.article-wrap {
	/*width:1020px;
	margin:0 auto;*/
}

.breadcrumbs {
	
	padding:35px 0;
	
	li {
		font-size:14px;
		color:#9e9e9e;
		display:inline-block;
		background-image:url('../images/arrow-bc.png');
		background-repeat:no-repeat;
		background-position:0 5px;
		padding-left:10px;
		padding-right:5px;
		
		&:first-child {
			background-image:none;
			padding-left:0;
		}
	}
}

.article-container {
	@color-shadow:#9c426a;
	@color-overlay:#79435a;
	
	padding-left:20px;
	padding-right:20px;
	
	color:@color-text;
	/*width:1000px;*/
	
	a {
		color:@color-baby;
		text-decoration:none;
		&:hover {
			text-decoration:underline;
		}
	}
	
	h1 {
		width: 560px;
		font-size:70px;
		line-height:70px;
		margin-top:35px;
		color:@color-baby;
		.font('conqueror_sans_extrabold');
		max-width:590px;
		
		@media (max-width:1199px){
			font-size:42px;
			line-height:1.2;
		}
	}
	
	article {
		padding:50px 110px 35px 110px;
		font-size:16px;
		background-color:@color-bg;
		.border-radius(10px);
		.box-shadow(0 10px 30px fade(@color-shadow, 30%));
		
		img {
			margin:1.6em 0;
			&.wide {
				width:calc(~"100% + 220px");
				margin-left:-110px;
				margin-right:-110px;
			}
		}
		p {
			line-height:1.6;
			margin:1.6em 0;
		}
	}
	
	h3 {
		font-size:24px;
		color:@color-baby;
		.font('conqueror_sans_semibold');
	}
	
	.bottom-nav {
		font-size:14px;
		padding-top:20px;
		.font('conqueror_sans_medium');
		
		.left {
			float:left;
		}
		.right {
			float:right;
		}
		&:after {
			content:"";
			clear:both;
			display:block;
		}
	}
}
.article-block-1 {
	background-image:url(../images/article-header.jpg);
	background-repeat:no-repeat;
	background-position:50%;
	background-size:cover;
	
	min-height:538px;

	@media (max-width: 1366px) {
		min-height: 440px;
	}
	h1 {
		font-size:42px;
		line-height:1.2;
	}
	.breadcrumbs {
		li:last-child {
			display: none;
		}
	}
}
.article-block-2 {
	background-image:url(../images/bg-article.png);
	background-repeat:no-repeat;
	background-position:50% 0;
	background-size:cover;
	
	padding-top:157px;
	margin-top:-213px;
	padding-bottom:50px;

}
html:not(.mobile):not(.tablet) {
	.article-block-2 {
		.article-wrap {
			@media (max-width: 1366px) {
				margin-top: -80px;
			}
		}
	}
}
.link-down {
	padding-top: 20px;
}