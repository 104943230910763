.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height:355px;
}

.carousel-inner > .item {
  position: relative;
  display: none;
  /* -webkit-transition: 0.6s ease-in-out left;
          transition: 0.6s ease-in-out left; */
	.transition(0.6s ease-in-out left);
}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  height: auto;
  max-width: 100%;
  line-height: 1;
}

.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}

.carousel-inner > .active {
  left: 0;
}

.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel-inner > .next {
  left: 100%;
}

.carousel-inner > .prev {
  left: -100%;
}

.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}

.carousel-inner > .active.left {
  left: -100%;
}

.carousel-inner > .active.right {
  left: 100%;
}

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: @color-bg;
  text-align: center;
  /* text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  opacity: 0.5;
  filter: alpha(opacity=50); */
}

.carousel-control.left {
}

.carousel-control.right {
  right: 0;
  left: auto;
}

.carousel-control:hover,
.carousel-control:focus {
  color: @color-bg;
  text-decoration: none;
}

.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  font-family: serif;
}

.carousel-control .icon-prev:before {
  content: '\2039';
}

.carousel-control .icon-next:before {
  content: '\203a';
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  border: 1px solid @color-bg;
  border-radius: 10px;
}

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: @color-bg;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: @color-bg;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    font-size: 30px;
  }
  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
  }
  .carousel-indicators {
    bottom: 20px;
  }
}
.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}

@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
}

/* my styles */
.carousel-inner {
	.item {
		margin-top: 173px;
		line-height: 25px;
		color: @color-text;
		font-size: 22px;
		font-family: @font-medium;
		text-align: center;
		
		.slide-text {
			display:table-cell;
			vertical-align:middle;
			text-align:center;
			padding:0 30px;
			width:450px;
			height:75px;
		}
		
	}
}

.carousel-main-baby {
	width: 424px;
	height: 424px;
	background-color: @color-bg;
	border: 2px solid @color-baby;
	.border-radius(50%);
	overflow: hidden;
}

.carousel-control {
	top: 50%;
	margin-top: -15px;
	width: 18px;
	height: 30px;
	
	&.left {
		left: 24px;
		
		span {
			opacity: .9;
			display: inline-block;
			width: 18px;
			height: 30px;
			background: url('../images/c-icon-left.png') 0 0 no-repeat;
			.transition(all .2s ease-in-out);
			.transform(scale(.9));
		}
		&:hover {
			span {
				opacity: 1;
				.transform(scale(1));
			}
		}
	}
	&.right {
		right: 24px;
		
		span {
			opacity: .9;
			display: inline-block;
			width: 18px;
			height: 30px;
			background: url('../images/c-icon-right.png') 0 0 no-repeat;
			.transition(all .2s ease-in-out);
			.transform(scale(.9));
		}
		&:hover {
			span {
				opacity: 1;
				.transform(scale(1));
			}
		}
	}
}
#slidetext,
#slidetext2 {
	position: absolute;
	top: 47px;
	left: 50%;
	margin-left: -30px;
	
	color: @color-baby;
	font-size: 25px;
	font-family: @font-medium;
	font-weight: normal;
	
	b {
		font-size: 54px;
		font-family: @font-medium;
		font-weight: normal;
	}
	i {
		position: relative;
		top: 22px;
		display: inline-block;
		width: 16px;
		height: 73px;
		background: url('../images/carousel-icon-count.png') 0 0 no-repeat;
	}
}
#slidetext2 {
	color: @color-forte;
	
	i {
		background: url('../images/carousel-icon-count-2.png') 0 0 no-repeat;
	}
}

#carousel-main-2 {
	&.carousel-main-baby {
		border: 2px solid @color-forte;
	}
	
	.carousel-control {

		&.left {

			span {
				background: url('../images/c-icon-left-2.png') 0 0 no-repeat;
			}
		}
		&.right {

			span {
				background: url('../images/c-icon-right-2.png') 0 0 no-repeat;
			}
		}
	}
}

#carousel-main-3 {
	min-width:1000px;
    @color-rose:@color-baby;
	@slider-height:870px;
    /*@slider-height:890px;*/
    height:@slider-height;
    width:100%;
    overflow:hidden;
    position:relative;
	
	.controls {
		position:relative;
		z-index:10;
		width: 100%;
		max-width: 100%;
	}
	
	.carousel-inner {
		
		.item {
			width: 100%;
			height:@slider-height;
			padding:58px 0 0;
			margin:0;
			text-align: left;
			background-repeat:no-repeat;
			background-position:50%;

			@media (max-width: 1600px) {
				padding:38px 0 0;
			}

			.foreground {
				/*margin:0 150px 115px;*/
				max-width: 1524px;
				margin: 0 auto 115px;
				
				padding-left: 150px;
				padding-right: 150px;

				@media (max-width: 1600px) {
					padding-left: 100px;
					padding-right: 100px;
				}
				
				color:@color-text;
				font-size:29px;
				.font(@font-medium);
					
				h2, h3 {
					line-height:1em;
					font-size:40px;
					/*
					line-height:1.1em;
					font-size:46px;
					*/
					color:@color-baby;
					margin:.5em 0 .2em 0;
					.font(@font-extrabold);
				}

				.lead {
					font-size:40px;
					color:#c61d71;
					.font('conqueror_sans_bold');
				}
			}
			
			@media (max-width:1199px){
				.foreground {
					font-size:20px;
				
					h2, h3 {
						font-size:36px;
					}
					
					.lead {
						font-size:36px;
					}
					img {
						max-width:250px;
					}
				}
			}
		}
	}
	
	.carousel-control {
		position:absolute;
		top:318px;
		width:44px;
		height:44px;
		background:white;
		border:2px solid @color-rose;
		cursor:pointer;
		.border-radius(22px);
		.transition(all .4s ease);

		&:after {
			content:"";
			display:inline-block;
			width:7px;
			height:12px;
			background-image:url('../images/slider-arrows.png');
			background-repeat: no-repeat;
			position:absolute;
			top:14px;
		}
		&:hover {
			/*opacity:.5;*/
			background-color:@color-baby;
		}
		
		&.left {
			left:40px;
            &:after {
                left:16px;
                background-position:0 -1px;
            }
            &:hover:after {
                background-position:0 -15px;
            }
		}
		&.right {
            right:40px;
            &:after {
                right:16px;
                background-position:0 -29px;
            }
            &:hover:after {
                background-position:0 -43px;
            }
		}
	}
	
	
	&:after {
        content:"";
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        height:227px;
        background-image:url('../images/slider-cloud.png');
        background-repeat:no-repeat;
        background-position:50% 0;
    }
}
@media ( min-width: 1540px ) {
	#carousel-main-3 {
		.controls {
			width: 1520px;
		}
	}
}
@media ( min-width: 1240px ) {
	#carousel-main-3 {
		.carousel-inner {
			.item {
				-webkit-background-size: cover;
				-moz-background-size: cover;
				-o-background-size: cover;
				background-size: cover;
			}
		}
		&:after {
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
		}
	}
}
/*
@media ( min-width: 1524px ) {
	#carousel-main-3 {
		&:after {
			-webkit-background-size: auto;
			-moz-background-size: auto;
			-o-background-size: auto;
			background-size: auto;
			background-image:url('../images/slider-cloud2.png');
		}
	}
}*/