@color-shadow:#9c426a;
@color-overlay:#79435a;

.form-field {
    border:2px solid #bcbcbc;
    background:@color-bg;
    padding:20px 20px;
    outline:none;
    width:100%;
    color:@color-baby;
    .border-box;
    .border-radius(30px);
    .transition;
    
    &:focus {
        border-color:@color-baby;
    }
    &.has-error {
        color:red !important;
        border-color:red !important;
    }
}

.form-row {
    margin-bottom:15px;
}

.icon-close {
    width:44px;
    height:44px;

    &:before {
        content:"";
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background-color:@color-baby;
        .border-radius(50%);
        .box-shadow(0 4px 10px fade(@color-invert, 20%));
        .transition(all .3s ease-in);
    }
	
    &:after {
        content:"";
        position:absolute;
        width:14px;
        height:14px;
        top:50%;
        left:50%;
        margin-left:-7px;
        margin-top:-7px;
        background-image:url(../images/icon-x.png);
        background-repeat: no-repeat;
		.transition;
    }
}

.icon-close-forte {
	&:before {
		background-color:@color-forte;
	}
}

.icon-close-hover {
    &:before {
        .transform(scale(1.1));
    }
	&:after {
		.transform(rotate(180deg));
	}
}

.wrapper {
    .transition(all .6s ease-in);
    &.popup-overlay-blur {
        .blur(15px);
    }
}

.fancybox-wrap,
.q-item,
.prob-benefits {
    .fancybox-skin,
	.popup {
        background-color: white;
        color:@color-text;
        .border-radius(10px);
        .box-shadow(0 15px 30px fade(@color-shadow, 30%));
        
		.popup-close,
        .fancybox-close {
            top: -22px;
            right: -22px;
            background:none;
            
            .icon-close;
            
            &:hover {
                .icon-close-hover;
            }
        }
        
        h3 {
            font-size:20px;
            color:@color-baby;
            text-align:center;
            text-transform:uppercase;
            line-height:1.4;
            .font('conqueror_sans_semibold');
        }
		
		b {
			.font('conqueror_sans_semibold');
		}
		
		small {
			display:inline-block;
			font-size:12px;
			line-height:1.2;
		}
		
		ul {
			padding:0;
			margin:0;
			
			li {
				position:relative;
				padding:0;
				margin:0;
				padding-bottom:30px;
				
				&:before {
					content: "•";
					position:absolute;
					top:1px;
					left:-20px;
					font-size:32px;
					color:@color-baby;
					.font('conqueror_sans_semibold');
				}
				
				&:laft-of-type {
					padding-bottom:0;
				}
			}
		}
		
		.forte {
			h3,
			a,
			ul li:before {
				color:@color-forte;
			}
		}
		
		.tab-icon {
			display:inline-block;
			margin:10px;
		}
    }
	
	&.forte {
		.popup-close,
        .fancybox-close {
			.icon-close-forte;
		}
	}
    
    &.popup-small {
		.popup-close,
        .fancybox-close {
            top:auto;
            left:50%;
            bottom:0;
            margin:0 0 -22px -22px;
        }
        
        .popup-content {
            width:370px;
            padding:30px 0;
            font-size:18px;
            text-align:center;
            line-height:1.4;
            .font('conqueror_sans_light');
        }
    }
	
	&.popup-medium {
		&, p {
			line-height:1.5;
		}
		
		h3 {
			font-size:22px;
			text-transform:none;
			margin-bottom:30px;
			
			&.big {
				font-size:28px;
				margin-top:10px;
			}
		}
		
		.popup-content {
            width:730px;
            padding:0 40px;
            font-size:18px;
            line-height:1.4;
			letter-spacing:.5px;
            .font('conqueror_sans_light');
        }
	}
    
    &.popup-form {
        .fancybox-inner {
            overflow:visible !important;
        }
        .popup-content {
            width:660px;
            margin:0 130px;
            .font('conqueror_sans_light');
            .border-box;
        }
    }
	
	&.popup-text {
	    .fancybox-inner {
            overflow-x:hidden !important;
        }
        .popup-content {
			h3 {
				font-size:23px;
				text-transform:none;
				text-align:left;
			}
			
			.popup-header {
				img {
					width:100%;
					.border-radius(10px 10px 0 0);
				}
			}
		
            width:1000px;
            font-size:18px;
            text-align:left;
            line-height:1.4;
            .font('conqueror_sans_light');
            .border-box;
			
			.popup-scroll {
				margin:40px 115px;
				oveflow:auto;
				
			}
        }
    }
    
    &.popup-question-success {
        .popup-content {
            width:920px;
            margin:172px 0;
            font-size:30px;
            text-align:center;
            line-height:1.4;
            color:@color-baby;
            text-transform:uppercase;
            .font('conqueror_sans_semibold');
            .border-box;
        }
    }
    
    
}
label.error {
  margin-top: -20px;
  font-family: 'conqueror_sans_light', Arial, sans-serif;
  font-weight: normal;
  color: red;
  padding-right: 50px;
  position: absolute;
  display: block !important;
  width: 100%;
  text-align: right;
}
textarea.form-field {
  margin-bottom: -5px;
}
.fancybox-overlay {
    position:fixed;
    height:100% !important;
    background:rgba(121,67,90,.7);
    .box-shadow(inset 0 20px 40px @color-overlay);
}