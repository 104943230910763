/* ==========================================================================
	Typography palitra
	========================================================================== */
html {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html, body {
	font-family: 'conqueror_sans_regular', 'Arial', sans-serif;
	line-height: 1.142857142857143;
	color: #333;
	font-size: 14px;
	background: #fff;
}
body {
	-webkit-font-smoothing: subpixel-antialiased;
}
/* Сбрасываем отступы для всех элементов сайта */
li {
	list-style-type: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
li {
	margin: 0;
	padding: 0;
	line-height: 1.2em;
}

/* т3 - 9.5. Отступы между заголовками H и текстами */
p {
	margin-bottom: 1.3em;
	line-height: 1.2em;
	font-size: 1em;
}
h1 {
	margin-bottom: 1.5em;
}
h2 {
	margin-bottom: 1.3em;
}
h3 {
	margin-bottom: 1.2em;
}
h4,
h5,
h6 {
	margin-bottom: 1em;
}

a {
	color:@color-baby;
	text-decoration:none;
	
	&:hover {
		text-decoration:underline;
	}
}

.forte {
	a {
		color:@color-forte;
	}
}

