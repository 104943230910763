/* ==========================================================================
	* MIXINS
	========================================================================== */
.font (@value) {
	font-family: @value, Arial, sans-serif;
	font-weight: normal;
}
.font-bold (@value:@w-700) {
	font-family: @value;
	font-weight: bold;
}
.background-size (@value:cover){
	-webkit-background-size:@value;
	   -moz-background-size:@value;
		 -o-background-size:@value;
			background-size:@value;
}
.blur (@value:5px){
	-webkit-filter: blur(@value);
	   -moz-filter: blur(@value);
		-ms-filter: blur(@value);
		 -o-filter: blur(@value);
}
.border-radius (@value:5px){
	-webkit-border-radius:@value;
	   -moz-border-radius:@value;
	        border-radius:@value;
}
.box-shadow (@value:5px){
	-webkit-box-shadow:@value;
	   -moz-box-shadow:@value;
	        box-shadow:@value;
}
.transition (@value:all 500ms ease){
	-webkit-transition:@value;
	   -moz-transition:@value;
	     -o-transition:@value;
	        transition:@value;
}
.opacity (@value:.8){
	-webkit-opacity:@value;
	   -moz-opacity:@value;
	     -o-opacity:@value;
	        opacity:@value;
}
.gradient (@top, @bottom, @alt_bg:#fff){
	background: @alt_bg;
	background: -moz-linear-gradient(top,  @top 0%, @bottom 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@top), color-stop(100%,@bottom));
	background: -webkit-linear-gradient(top,  @top 0%,@bottom 100%);
	background: -o-linear-gradient(top,  @top 0%,@bottom 100%);
	background: -ms-linear-gradient(top,  @top 0%,@bottom 100%);
	background: linear-gradient(to bottom,  @top 0%,@bottom 100%);
}
.transform (@value){
	-webkit-transform: @value;
	-moz-transform: @value;
	-o-transform: @value;
	-ms-transform: @value;
	transform: @value;
}
.border-box {
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}
.calc (@prop, @value){
	width: 0;
	width: -webkit-calc(@value);
	width: -moz-calc(@value);
	width: calc(@value);
}
.round(@val:50%){
  -webkit-border-radius:@val;
  -moz-border-radius:@val;
  border-radius:@val;
}

.cf, clearfix {
	display:block;
	clear:both;
}