[src*="tab-icon-bottom"] {
  max-width: 100% !important;
  max-height: 100% !important;
  margin-top: 0 !important;
}
html.tablet {
  body {
    width: 100%;
    min-width: 100%;
    font-size: 1.4rem;
  }


  .header nav a.not-btn:hover, .header nav a.not-btn.active {
    text-decoration: none;
    font-family: inherit;
  }
  .header nav a {
    padding: 1.6rem 1.5rem 1.5rem !important;
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
  .wrapper {
    padding-top: 10rem;
  }
  .header nav a.not-btn {
    width: auto;
  }
  .header {

    .container {
      padding: 2.7rem 0 0;
      min-width: 100%;
      display: block !important;
      height: auto !important;
    }
  }
  .header nav a.nab-btn {
    padding: 1.6rem 1.5rem 1.5rem;
    font-size: 1.8rem;
    border-width: 0.2rem;
  }
  .header nav a{
    border-width: 0.2rem !important;
  }
  .how-work {
    width: 90rem;
    margin: 0 auto;
  }
  .footer-text {
    width: 80%;
    height: 0;
    padding-bottom: 5.2%;
    margin-top: 3rem;
    margin-bottom: 0;
  }
  .page-new h3 {
    font-size: 4rem;
  }
  .spec-block-1 h1,
  .page-new h2 {
    font-size: 4.2rem;
    line-height: 1.2;
  }
  .container {
    max-width: 100%;
    min-width: 100%;
  }
  .block-map {
    padding: 55px 0 6rem;
  }
  .btn-big {
    padding: 0 6rem;
    height: 6rem;
    line-height: 6.2rem;
    font-size: 2rem;
    border-radius: 3rem;
    border-width: 0.2rem;
  }
  .block-map .map-container {
    height: 67rem;
    margin-bottom: 3rem;
  }
  .block-map h3 {
    font-size: 4.6rem;
  }
  .block-map .map-container .map-filter button[type=submit] {
    padding: 1.5rem 2rem;
    border-radius: 2.5rem;
    width: 19rem;
    height: 5rem;
    vertical-align: top;
  }
  .block-map .map-container .map-filter .map-switch {
    width: 5rem;
    height: 5rem;
    vertical-align: top;
  }
  .block-map .map-container .map-filter .map-switch > .icon-list {
    width: 1.9rem;
    height: 1.2rem;
    margin-left: -1rem;
    margin-top: -.6rem;
    background-size: contain;
  }
  .block-map .map-container .map-filter .map-switch > .icon-map {
    width: 1.7rem;
    height: 2.4rem;
    margin-left: -.9rem;
    margin-top: -1.2rem;
    background-size: contain;
  }
  .block-map .map-container .map-filter input[name=city] {
    padding: 1.5rem 2.0rem;
    border-radius: 2.5rem;
    width: 19rem;
    height: 5rem;
  }
  .bg-block-5, .bg-block-6, .bg-block-5 .col-md-12, .bg-block-6 .col-md-12 {
    height: 59rem;
    width: 100%;
  }
  .bakset-baby-title, .bakset-forte-title,
  .how-work h4 {
    margin-top: 7.2rem;
    line-height: 4.5rem;
    font-size: 4.6rem;
  }
  .how-work .description {
    margin-top: 7rem;
    width: 45rem;
  }
  .how-work .description .desc-title {
    font-size: 2.3rem;
  }
  .how-work .description p {
    margin-top: 2.5rem;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
  .how-work.block-7 .how-work-image {
    width: 45rem;
  }
  .how-work .more-knew {
    bottom: 2rem;
    font-size: 1.8rem;
    &:before {
      bottom: -0.4rem;
      width: 12.2rem;
      height: 1px;
    }
  }
  .carousel-main-baby {
    width: 42rem;
    height: 42rem;
  }
  .carousel-inner .item .slide-text {
    padding: 0 3rem;
    width: 42rem;
    height: 7.5rem;
  }
  .carousel-inner .item {
    margin-top: 17.5rem;
    line-height: 2.5rem;
    font-size: 2.2rem;
  }
  .bakset-baby-title b, .how-work h4 span, .bakset-forte-title b {
    font-size: 6rem;
  }
  .bg-block-6 .container,
  .bg-block-6 {
    height: 59rem;
  }
  .bg-block-5 .col-md-12 {
    height: auto;
  }
  .bg-block-5,
  .bg-block-5 .container {
    height: auto;
    display: block;
  }
  .bg-block-5 .how-work .description {
    margin-left: 4.5rem;
  }
  .bg-block-5,
  .bg-block-6,
  .bg-block-5 .col-md-12,
  .bg-block-6 .col-md-12 {
    display: block;
  }
  .bg-block-4 {
    background-size: cover;
  }
  .bg-block-6 {
    background-size: cover;
  }
  .bg-block-5 .how-work .how-work-image img {
    width: 35rem;
  }
  .bg-block-7 .how-work .how-work-image img {
    width: 45rem;
  }
  .bg-block-7, .bg-block-7 .col-md-12 {
    height: auto;
  }
  .bg-block-6 .col-md-12 .carousel-main-baby {
    right: 4rem;
    bottom: -6.7rem;
  }

  .bg-block-7 {
    height: auto;
  }
  .bg-block-7 .container {
    height: 100%;
  }
  .bg-block-4 .col-md-12 .carousel-main-baby {
    left: 4.5rem;
    bottom: -6.7rem;
  }

  .bg-block-4 {
    height: 57rem;
    background-position: 60% 0;
  }
  .bg-block-4 .col-md-12 {
    height: 57rem;
  }

  //footer
  footer {
    margin-top: 9rem;
    padding: 2.5rem 0 4rem 0;
    padding-bottom: 2.5rem;
  }
  footer .container {
    min-width: 92%;
  }
  .pharmamed img {
    width: 12rem;
  }
  .footer-socials .social-item {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    line-height: 4.5rem;
    border-radius: 2.5rem;
    margin: 0 .8rem;
    border-width: .2rem;
    &:hover:after {
      background-position: 0 100%;
    }
  }
  .tabs-scale.forte .btn-forte,
  .tabs-scale.baby .btn-more {
    padding: 0 9rem;
  }
  .tabs-scale .tabs-content--bottom .icon-item {
    padding: 0 2.5rem;
  }
  .tabs-scale .tabs-content--bottom .icon-item .tab-text {
    padding-top: .7rem;
    width: 24.2rem;
    font-size: 1.8rem;
    padding-left: 1.5rem;
  }
  .tab-icon {
    width: 8.3rem;
    height: 7.7rem;
    background-size: contain;
  }
  .tab-icon img {
    max-width: 45%;
    max-height: 45%;
    margin-top: 0.8rem;
  }
  .tabs-scale .tabs-content--bottom img {
    margin-top: .8rem;
    margin-left: .2rem;

    max-width: 100%;
    max-height: 100%;
  }
  .tabs-scale .tabs-content {
    height: 45rem;
  }
  .tabs-scale.baby.transition-back .tabs-content .image-baby {
    right: 29%;
  }
  .tabs-scale.baby .tabs-content .image-baby {
    right: 29%;
  }
  .tabs-scale.baby.transition-back .tabs-content .image-forte {
    z-index: 1;
    transform: scale(0.8);
  }
  .tabs-scale .tabs .logo-title {
    position: relative;
    display: inline-block;
    width: 28rem;
    height: 4.6rem;
    background-size: cover;
  }
  .bg-block-23 {
    background-position: 50% 0;
    background-size: 140%;
  }

  .tabs-scale .tabs-content .image-baby, .tabs-scale .tabs-content .image-forte {
    margin: -2rem 6% 0;
    width: 25%;
  }
  .tabs-scale.forte .tabs-content .image-baby {
    margin-right: -1rem !important;
  }
  .tabs-scale .tabs-content .left .icon-item,
  .tabs-scale .tabs-content .right .icon-item {
    padding-bottom: 2rem;
  }
  .tabs-scale .tabs-desc {
    font-size: 2.2rem;
    padding-left: 4rem;
    min-height: 2.9rem;
    line-height: 2.9rem;
    margin-bottom: 3rem;
    background-size: auto 3rem;
  }
  .tabs-scale .subtitle {
    font-size: 2.6rem;
  }
  .tabs-scale .tabs-content .left, .tabs-scale .tabs-content .right {
    width: 28rem;
    top: 2.5rem;
    transform: scale(1);
  }
  .tabs-scale .tabs-content .left .icon-item .tab-text, .tabs-scale .tabs-content .right .icon-item .tab-text {
    padding-top: .7rem;
    width: 22rem;
    font-size: 1.8rem;
  }
  .tabs-scale .tabs-content .tab-icon {
    width: 5.3rem;
    height: 4.7rem;
  }
  .developer {
    width: 14.1rem;
    height: 2.3rem;
    margin-top: 1.5rem;
    background-size: cover;
  }
  .footer-socials .social-item:after {
    width: 1.1rem;
    height: 2.1rem;
    background-size: auto 200%;
  }
  .footer-socials .social-item.vk:after {
    width: 2.5rem;
    height: 1.5rem;
  }
  //footer
  #carousel-main-3 {
    min-width: 100%;
    height: 78rem;
    width: 100%;
  }
  #carousel-main-3 .carousel-inner .item {
    height: 77rem;
  }
  .carousel-inner {
    min-height: 100%;
  }
  .tabs-scale .tabs-content--bottom {
    width: 100%;
    margin: 0 auto 5rem;
  }
  .benefit-icons .icon-item {
    width: 12rem;
    height: 12rem;
    line-height: 7rem;
    border: 2px solid #fe86a4;
    margin: 0 4.2rem;
    font-size: 1.2rem;
    border-radius: 1rem;
    img {
      height: 6rem;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .benefit-icons .icon-item > span {
    padding: .5rem 0;
    font-size: 1.2rem;
  }
  .link-down {
    padding-top: 5rem;
    padding-bottom: 0;
  }

  #carousel-main-3 .carousel-control.left {
    left: 4rem;
  }
  #carousel-main-3 .carousel-control.right {
    right: 4rem;
  }
  .icon-down {
    width: 4.4rem;
    height: 4.4rem;
    border: 0.2rem solid #fe86a4;
    border-radius: 2.2rem;
    &:after {
      width: 1.4rem;
      height: .7rem;
      background-size: 200%;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -.7rem;
      margin-top: -.2rem;
      background-position: 0;
    }
    &:hover:after {
      background-position: -1.5rem 0;
    }
  }
  #carousel-main-3 .carousel-control {
    top: 48rem;
    width: 4.4rem;
    height: 4.4rem;
    border-width: 0.2rem;
    border-radius: 2.2rem;
  }
  #carousel-main-3 .carousel-control.left:after {
    left: 1.4rem;
    background-position: 0 -0.1rem;
    background-size: 100%;
  }
  #carousel-main-3 .carousel-control.right:after {
    right: 1.6rem;
    background-position: 0 66%;
    background-size: 100%;
  }
  #carousel-main-3 .carousel-control:after {
    width: .7rem;
    height: 1.2rem;
    top: 50%;
    margin-top: -0.6rem;
  }
  #carousel-main-3 .carousel-inner .item {
    background-size: auto 100% !important;
  }
  #carousel-main-3 .carousel-inner .item .foreground img {
    width: 40rem;
    max-width: 40rem;
  }
  #carousel-main-3 .carousel-inner .item .foreground {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  //header
  .header .container {
    height: 100%;
  }
  //header

  //fonts
  #carousel-main-3 .carousel-inner .item .foreground h2,
  #carousel-main-3 .carousel-inner .item .foreground h3 {
    font-size: 4.6rem;
  }
  #carousel-main-3 .carousel-inner .item .foreground {
    font-size: 2.5rem;
  }
  #carousel-main-3 .carousel-inner .item .foreground .lead {
    font-size: 4.5rem;
  }

  .tabs-scale .tabs .tab {
    border-width: .2rem;
    border-radius: .7rem;
    margin: 0 5.5rem;
    font-size: 2.6rem;
    min-width: 13.0rem;
    height: 5.0rem;
    line-height: 4.4rem;
  }
  .tabs-scale .subtitle {
    font-size: 2.6rem;
    margin-top: 4.3rem;
    height: 10rem;
  }
  .tabs-scale {
    padding-top: 7.5rem;
    padding-bottom: 6.1rem;
  }
  .header nav {
    padding: 0 2rem;
  }

  .fancybox-wrap.popup-medium h3, .q-item.popup-medium h3, .prob-benefits.popup-medium h3 {
    font-size: 2.2rem;
    margin-bottom: 3rem;
  }

  .fancybox-wrap .fancybox-skin ul li, .q-item .fancybox-skin ul li, .prob-benefits .fancybox-skin ul li, .fancybox-wrap .popup ul li, .q-item .popup ul li, .prob-benefits .popup ul li {
    padding-bottom: 3rem;
  }
  .fancybox-wrap .fancybox-skin ul li:before, .q-item .fancybox-skin ul li:before, .prob-benefits .fancybox-skin ul li:before, .fancybox-wrap .popup ul li:before, .q-item .popup ul li:before, .prob-benefits .popup ul li:before {
    top: .1rem;
    left: -2rem;
    font-size: 3.2rem;
  }
  .fancybox-wrap.popup-medium .popup-content, .q-item.popup-medium .popup-content, .prob-benefits.popup-medium .popup-content {
    width: 73rem;
    padding: 0 4rem;
    font-size: 1.8rem;
    line-height: .1rem;
    letter-spacing: .05rem;
  }

  //inner pages
  .page-new .block-ld-small {
    padding-top: 5rem;
  }

  .page-new .block-ld.prob-2 {
    background-position: center bottom;
  }
  .page-new .block-ld.prob-2 .img {
    img {
      width: 100%;
      height: auto;
    }
    width: 100%;
  }


  .page-new, .page-new p {
    font-size: 1.6rem;
  }

  .page-new .block-ld {
    .col-xs-5 {
      width: 50%;
    }
    .col-xs-7 {
      width: 50%;
    }
    .container {
      padding-left: 5rem;
      padding-right: 5rem;
    }
    &.prob-1 {
      .col-xs-5 {
        width: 25%;
      }
      .col-xs-7 {
        width: 75%;
      }
    }
  }
  .page-new .block-ld.prob-3 .img .count b {
    font-size: 6rem;
  }
  .page-new .block-ld.prob-3 .img .count .cell {
    width: 29.6rem;
    height: 29.6rem;
  }
  .page-new .block-ld.prob-3 .img .count {
    width: 29.6rem;
    height: 29.6rem;
    font-size: 1.8rem;
    background-size: cover;
  }

  .page-new .block-ld.prob-3 .img {
    margin-top: 2rem;
  }
  .page-new .block-ld {
    background-position: 16%;
  }

  .page-new h4 {
    font-size: 2.3rem;
  }
  .page-new.lead, .page-new p.lead {
    font-size: 1.8rem;
  }
  .prob-benefits .item .icon.icon-2:before {
    background-size: 60%;
  }
  .page-new .block-ld.prob-4 {
    padding-top: 9rem;
    padding-bottom: 7rem;
  }
  .page-new .block-ld {
    padding: 10rem 2rem 9rem;
  }
  .prob-benefits .item .icon:before {
    background-size: auto 50%;
  }
  .prob-benefits .item .icon {
    width: 14rem;
    height: 14rem;
    background-size: contain;
  }
  .prob-benefits .item {
    padding-bottom: 7rem;
  }
  .prob-benefits {
    padding: 7rem 8.5rem 0 8.5rem;
  }

  .prob-benefits .popup.active {
    top: 4.5rem;
  }
  .prob-benefits .popup .popup-content {
    padding: 0 12rem 4rem 12rem;
  }
  .prob-benefits .popup .item {
    padding-bottom: 3.5rem;
    margin-top: -6rem;
  }
  .prob-benefits .item .title {
    margin-top: 2rem;
    line-height: normal;
    font-size: 2.4rem;
  }
  .prob-benefits .item .icon .btn-prob {
    width: 3rem;
    height: 3rem;
    margin-left: -1.5rem;
    margin-bottom: -0.9rem;
    background-size: cover;
    &.close:after {
      background-size: auto 50%;
    }
  }

  .spec-block-1 h1 {
    width: 59rem;
    margin-top: 14rem;
  }
  .spec-block-1 {
    min-height: 54.5rem;
    padding-left: 2.0rem;
    padding-right: 2.0rem;
  }
  .spec-block-2 {
    margin-bottom: -7rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .article-block-2 {
    padding-top: 15.7rem;
    margin-top: -21.3rem;
    padding-bottom: 5.0rem;
  }
  .spec-block-2 .container {
    padding-bottom: 7rem;
  }
  .spec-list .list-item .title {
    font-size: 2.3rem;
    margin-bottom: 2.5rem;
  }
  .paginator {
    bottom: 2rem;
  }
  .spec-block-2 h2 {
    font-size: 4rem;
  }
  .spec-list .icon-date, .spec-list .icon-place {
    margin-right: 1.5rem;
  }
  .spec-list .list-item {
    padding-right: 7.0rem;
    padding-bottom: 4.5rem;
  }
  .spec-list .icon-date:before {
    width: 1.3rem;
    height: 1.4rem;
    margin-top: -0.4rem;
    background-size: contain;
    margin-right: .8rem;
  }
  .spec-list .icon-place:before {
    width: 1.5rem;
    height: 1.9rem;
    background-size: contain;
    margin-right: .8rem;
  }
  .article-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .breadcrumbs {
    padding: 3.5rem 0;
  }
  .breadcrumbs li {
    font-size: 1.4rem;
    background-position: 0 .5rem;
    padding-left: 1.0rem;
    padding-right: .5rem;
  }
  .article-container h1 {
    font-size: 4.2rem;
    line-height: 1.2;
    width: 56rem;
    margin-top: 3.5rem;
    max-width: 59rem;
  }
  .faq-block-1 {
    height: 54.5rem;
    background-size: cover;
  }
  .faq-block-3 h3 {
    font-size: 4.6rem;
    margin-bottom: 4.5rem;
  }
  .faq-block-3 p {
    margin: 4.5rem 0 0 0;
  }
  .fancybox-wrap .fancybox-skin h3,
  .q-item .fancybox-skin h3,
  .prob-benefits .fancybox-skin h3,
  .fancybox-wrap .popup h3,
  .q-item .popup h3,
  .prob-benefits .popup h3 {
    font-size: 2rem;
  }
  .faq-block-2 .q-item .title {
    font-size: 2.4rem;
    min-height: inherit;
  }
  .faq-block-2 .q-item .popup {
    top: -3rem;
  }

  .fancybox-wrap.popup-form .popup-content,
  .q-item.popup-form .popup-content,
  .prob-benefits.popup-form .popup-content {
    width: 85rem;
    margin: 0 auto;
  }
  .faq-block-2 .q-item {
    width: 43rem;
    padding: 4.5rem 2rem 2.5rem 2rem;
  }
  .faq-slider .item-item .title {
    font-size: 2.3rem;
    margin-top: 2.0rem;
  }
  .faq-block-2 .q-item .icon, .faq-block-2 .q-item .title {
    padding-bottom: 2rem;
  }
  .faq-block-2 .q-item .icon img {
    width: 10rem;
  }
  .faq-block-4 h3 {
    font-size: 4.6rem;
    margin-bottom: 4.5rem;
  }
  .faq-slider .item {
    margin-top: 0;
  }
  .faq-slider .item-item p {
    margin-top: 2.0rem;
    font-size: 1.8rem;
  }
  .faq-block-2 {
    margin-bottom: 4rem;
    padding-bottom: 0;
  }
  .faq-slider .item-item .item-content {
    width: 42rem;
    img {
      width: 100%;
      height: auto;
    }
  }
  .faq-block-2 .q-item .popup .popup-content {
    padding: 7rem 3.0rem;
    font-size: 1.8rem;
  }
  .faq-slider {
    margin-bottom: 4rem;
  }
  .carousel-control.left span,
  .carousel-control.right span {
    width: 1.8rem;
    height: 3.0rem;
    background-size: cover  !important;
  }
  .faq-block-4 {
    margin-bottom: -7rem;
    padding: 8.5rem 0 5.5rem 0;
  }
  .faq-slider .carousel-control.right {
    right: 0.5rem;
  }
  .faq-slider .carousel-control.left {
    left: 0.5rem;
  }
  .faq-list .list-item {
    padding: 5.5rem 11.5rem 3.5rem 11.5rem;
  }
  .faq-list {
    margin-top: -2.0rem;
    margin-bottom: 15.0rem;
    font-size: 1.6rem;
    border-radius: 1.0rem;
    .paginator {
      bottom: -70px;
      width: 100%;
    }
  }
  .faq-list .list-item .title {
    font-size: 2.4rem;
    margin-bottom: 3.0rem;
  }
  .block-map .map {
    height: 67rem;
  }
  .block-map .map-container .map-filter {
    width: 56rem;
    top: 3rem;
    margin-left: -25rem;
  }
  .block-map .map-container .map-filter input[name=city],
  .block-map .map-container .map-filter button[type=submit],
  .block-map .map-container .map-filter .map-switch {
    margin: 0 1.7rem;
  }
  #carousel-main-3 .carousel-inner .item {
    padding: 5.8rem 0 0;
  }
  #slidetext, #slidetext2 {
    top: 4.7rem;
    margin-left: -3rem;
    font-size: 2.5rem;
  }
  #slidetext i, #slidetext2 i {
    top: 2.2rem;
    width: 1.6rem;
    height: 7.3rem;
    background-size: cover;
  }
  #slidetext b, #slidetext2 b {
    font-size: 5.4rem;
  }
  .carousel-control.left {
    left: 2.4rem;
  }
  .carousel-control.right {
    right: 2.4rem;
  }
}

.logo-link {
  display: none;
}

.menu-link {
  display: none;
}

html.mobile {
  .article-block-1 {
    min-height:42rem;
  }
  .article-container {
    article {
      padding: 3rem;
      font-size: 2rem;
      background-color: white;
      border-radius: 1rem;
      box-shadow: 0 1rem 3rem rgba(156, 66, 106, 0.3);
      img.wide {
        width: ~"calc(100% + 6rem)";
        margin-left: -3rem;
        margin-right: -3rem;
      }
    }
    h3 {
      font-size: 2.4rem;
    }
    .bottom-nav {
      font-size: 2rem;
      padding-top: 2rem;
    }
  }
  .faq-new .article-wrap.container{
    margin-bottom: 5rem;
  }
  .faq-block-2 .prob-benefits{
    padding: 0;
  }
  #carousel-main-3 .carousel-inner .item {
    padding: 9rem 0 0;
  }
  .block-map .map-container .map-filter input[name=city],
  .block-map .map-container .map-filter button[type=submit],
  .block-map .map-container .map-filter .map-switch {
    margin: 0 1.7rem;
  }
  .forte .btn-baby {
    display: inline-block;
  }
  .block-map .map-container .map-filter {
    width: 60rem;
    top: 3rem;
    margin-left: -30rem;
  }
  body {
    width: 100%;
    min-width: 100%;
    font-size: 1.4rem;
  }
  #slidetext, #slidetext2 {
    top: 4.7rem;
    margin-left: -3rem;
    font-size: 2.5rem;
  }
  #slidetext i, #slidetext2 i {
    top: 2.2rem;
    width: 1.6rem;
    height: 7.3rem;
    background-size: cover;
  }
  #slidetext b, #slidetext2 b {
    font-size: 5.4rem;
  }
  .carousel-control.left {
    left: 2.4rem;
  }
  .carousel-control.right {
    right: 2.4rem;
  }
  .header nav a.not-btn:hover, .header nav a.not-btn.active {
    text-decoration: none;
    font-family: inherit;
  }
  .header nav a {
    padding: 2rem 5rem 1.8rem !important;
    font-size: 3.4rem;
    line-height: 1;
    height: 7rem;
    border-radius: 3.5rem;
    margin-bottom: 2rem;
    @media (orientation: landscape) {
      padding: 1rem 2.5rem 0.9rem !important;
      font-size: 1.7rem;
      height: 3.5rem;
      border-radius: 1.75rem;
      margin-bottom: 1rem;
    }
  }
  .wrapper {
    padding-top: 14rem;
    @media (orientation: landscape) {
      padding-top: 7rem;
    }
  }
  .header nav a.not-btn {
    width: auto;
  }
  .header {

    .container {
      padding: 2.7rem 0 0;
      min-width: 100%;
    }
  }
  .header nav a.nab-btn {
    padding: 2rem 5rem 1.8rem !important;
    font-size: 3.4rem;
    margin-bottom: 6rem;
    @media (orientation: landscape) {
      padding: 1rem 2.5rem 1.2rem !important;
      font-size: 1.7rem;
      margin-bottom: 0;
    }
  }
  .how-work {
    width: 100%;
    margin: 0 auto;
  }
  .footer-text {
    width: 80%;
    height: 0;
    padding-bottom: 18.2%;
    margin-top: 0;
    margin-bottom: 0;
    background-image: url(../images/mobile/footer-text.png);
  }
  .page-new h3 {
    font-size: 3.3rem;
  }
  .page-new .block-ld.prob-1 {
    background-position: 50% 0;
    background-size: 200% auto;
  }
  .spec-block-1 h1,
  .page-new h2 {
    font-size: 3.7rem;
    line-height: 1.2;
  }
  .container {
    max-width: 100%;
    min-width: 100%;
  }
  .block-map {
    padding: 55px 0 6rem;
  }
  .btn-big {
    padding: 0 4rem;
    height: 6rem;
    line-height: 6.2rem;
    font-size: 2.2rem;
    border-radius: 3rem;
    border-width: 0.2rem;
  }
  .block-map .map-container {
    height: 66rem;
    margin-bottom: 5rem;
  }
  .block-map .map {
    height: 66rem;
  }
  .block-map h3 {
    font-size: 3.8rem;
  }
  .block-map .map-container .map-filter button[type=submit] {
    padding: 1.5rem 2rem;
    border-radius: 2.5rem;
    width: 21rem;
    height: 5rem;
    vertical-align: top;
  }
  .block-map .map-container .map-filter .map-switch {
    width: 5rem;
    height: 5rem;
    vertical-align: top;
  }
  .block-map .map-container .map-filter .map-switch > .icon-list {
    width: 1.9rem;
    height: 1.2rem;
    margin-left: -1rem;
    margin-top: -.6rem;
    background-size: contain;
  }
  .block-map .map-container .map-filter .map-switch > .icon-map {
    width: 1.7rem;
    height: 2.4rem;
    margin-left: -.9rem;
    margin-top: -1.2rem;
    background-size: contain;
  }
  .block-map .map-container .map-filter input[name=city] {
    padding: 1.2rem 2.0rem;
    border-radius: 2.5rem;
    width: 21rem;
    height: 5rem;
    &::-webkit-input-placeholder {
      font-size: 1.8rem;
      top: 0.3rem;
      position: relative;
    }
  }
  .bg-block-5, .bg-block-6, .bg-block-5 .col-md-12, .bg-block-6 .col-md-12 {
    height: 54rem;
    width: 100%;
  }
  .bakset-baby-title, .bakset-forte-title,
  .how-work h4 {
    margin-top: 4rem;
    line-height: 4.5rem;
    font-size: 3.8rem;
  }
  .how-work .description {
    margin-top: 7rem;
    width: 57rem;
    padding-bottom: 8rem;
  }
  .how-work .description .desc-title {
    font-size: 2.6rem;
  }
  .how-work .description p {
    margin-top: 2.5rem;
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
  .how-work.block-7 .how-work-image {
    width: 0;
  }
  .how-work .more-knew {
    bottom: 2rem;
    font-size: 2rem;
    &:before {
      bottom: -0.4rem;
      width: 12.2rem;
      height: 1px;
    }
  }
  .carousel-main-baby {
    width: 39rem;
    height: 39rem;
  }
  .carousel-inner .item .slide-text {
    padding: 0 3rem;
    width: 42rem;
    height: 7.5rem;
  }
  .carousel-inner .item {
    margin-top: 17.5rem;
    line-height: 2.5rem;
    font-size: 2.4rem;
  }
  .bakset-baby-title b, .how-work h4 span, .bakset-forte-title b {
    font-size: 4.8rem;
  }
  .bg-block-6 .container,
  .bg-block-6 {
    height: 54rem;
    background-size: auto 80% !important;
    background-position: 30% bottom;
  }
  .bg-block-5 .col-md-12 {
    height: auto;
  }
  .bg-block-5 .description {
    margin-top: 55rem;
    padding-bottom: 2rem;
  }
  .bg-block-5,
  .bg-block-5 .container {
    height: auto;
    display: block;
  }
  .bg-block-5 .how-work .description {
    margin-left: 4.5rem;
  }
  .bg-block-5,
  .bg-block-6,
  .bg-block-5 .col-md-12,
  .bg-block-6 .col-md-12 {
    display: block;
  }
  .bg-block-4 {
    background-size: cover;
  }
  .bg-block-6 {
    background-size: cover;
  }
  .bg-block-5 {
    background: none;
  }
  .bg-block-5 .how-work{
    padding-bottom: 5rem;
  }
  .bg-block-5 .how-work .how-work-image img {
    width: 40rem;
    position: absolute;
    top: 8rem;
    left: 13rem;

  }
  .bg-block-7 .how-work .how-work-image img {
    width: 50rem;
    position: absolute;
    top: 8rem;
    left: 8rem;
  }
  .bg-block-7, .bg-block-7 .col-md-12 {
    height: auto;
  }
  .bg-block-6 .col-md-12 .carousel-main-baby {
    right: 0;
    bottom: -8.7rem;
  }

  .bg-block-7 {
    height: auto;
    background-size: cover;
    background-position: top left;
    .description {
      margin-top: 60rem;
    }
  }
  .bg-block-7 .container {
    height: 100%;
  }
  .bg-block-4 .col-md-12 .carousel-main-baby {
    left: 0;
  }

  .bg-block-4 {
    height: 57rem;
    background-position: 64% bottom;
    background-size: auto 72%;
  }
  .bg-block-4 .col-md-12 {
    height: 57rem;
  }

  //footer
  footer {
    margin-top: 6rem;
    padding: 3.5rem 0 4rem 0;
    padding-bottom: 2.5rem;
  }
  footer .container {
    min-width: 92%;
    max-width: 92%;
  }
  .pharmamed img {
    width: 12rem;
  }
  .footer-socials .social-item {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    line-height: 4.5rem;
    border-radius: 2.5rem;
    margin: 0 .8rem;
    border-width: .2rem;
    &:hover:after {
      background-position: 0 100%;
    }
  }
  .footer-socials {
    width: 21rem;
    margin-left: -3rem;
  }
  .tabs-scale.forte .btn-forte,
  .tabs-scale.baby .btn-more {
    padding: 0 9rem;
  }
  .tabs-scale .tabs-content--bottom .icon-item {
    padding: 0 2.5rem;
  }
  .tabs-scale .tabs-content--bottom .icon-item .tab-text {
    padding-top: .7rem;
    width: 24.2rem;
    font-size: 1.8rem;
    padding-left: 1.5rem;
  }
  .tab-icon {
    width: 8.3rem;
    height: 7.7rem;
    background-size: contain;
  }
  .tab-icon img {
    max-width: 45%;
    max-height: 45%;
    margin-top: 0.8rem;

  }
  .icon-item:nth-last-child(2) .tab-icon img,
  .icon-item:nth-last-child(1) .tab-icon img {
    max-width: 100%;
    max-height: 90%;
    margin-top: 0.2rem;
  }
  .tabs-scale .tabs-content--bottom img {
    margin-top: .8rem;
    margin-left: .2rem;

    max-width: 100%;
    max-height: 100%;
  }
  .tabs-scale .tabs-content {
    height: 45rem;
  }
  .tabs-scale.baby.transition-back .tabs-content .image-baby {
    right: 29%;
  }
  .tabs-scale.baby .tabs-content .image-baby {
    right: 29%;
  }
  .tabs-scale.baby.transition-back .tabs-content .image-forte {
    z-index: 1;
    transform: scale(0.8);
  }
  .tabs-scale .tabs .logo-title {
    position: relative;
    display: inline-block;
    width: 28rem;
    height: 4.6rem;
    background-size: cover;
  }
  .bg-block-23 {
    background-position: 50% 0;
    background-size: 140%;
  }

  .tabs-scale .tabs-content .image-baby, .tabs-scale .tabs-content .image-forte {
    margin: -2rem 6% 0;
    width: 25%;
  }
  .tabs-scale.forte .tabs-content .image-baby {
    margin-right: -1rem !important;
  }
  .tabs-scale .tabs-content .left .icon-item,
  .tabs-scale .tabs-content .right .icon-item {
    padding-bottom: 2rem;
  }
  .tabs-scale .tabs-desc {
    font-size: 2.2rem;
    padding-left: 4rem;
    min-height: 2.9rem;
    line-height: 2.9rem;
    margin-bottom: 3rem;
    background-size: auto 3rem;
  }
  .tabs-scale .subtitle {
    font-size: 2.6rem;
  }
  .tabs-scale .tabs-content .left, .tabs-scale .tabs-content .right {
    width: 28rem;
    top: 2.5rem;
    transform: scale(1);
  }
  .tabs-scale .tabs-content .left .icon-item .tab-text, .tabs-scale .tabs-content .right .icon-item .tab-text {
    padding-top: .7rem;
    width: 22rem;
    font-size: 1.8rem;
  }
  .tabs-scale .tabs-content .tab-icon {
    width: 5.3rem;
    height: 4.7rem;
  }
  .developer {
    width: 14.1rem;
    height: 2.3rem;
    margin-top: 1.5rem;
    background-size: cover;
  }
  .footer-socials .social-item:after {
    width: 1.1rem;
    height: 2.1rem;
    background-size: auto 200%;
  }
  .footer-socials .social-item.vk:after {
    width: 2.5rem;
    height: 1.5rem;
  }
  //footer
  #carousel-main-3 {
    min-width: 100%;
    height: 75rem;
    width: 100%;
  }
  #carousel-main-3 .carousel-inner .item {
    height: 55rem;
  }
  .carousel-inner {
    min-height: 100%;
  }
  .tabs-scale .tabs-content--bottom {
    width: 100%;
    margin: 0 auto 5rem;
  }
  .benefit-icons .icon-item {
    width: 14rem;
    height: 14rem;
    line-height: 7rem;
    border: 0.2rem solid #fe86a4;
    margin: 0 1.35rem 2.7rem;
    font-size: 1.4rem;
    border-radius: 1.5rem;
    img {
      height: 7rem;
    }
    &:first-child {
      margin-left: 1.35rem;
    }
    &:last-child {
      margin-right: 1.35rem;
    }
  }
  .benefit-icons .icon-item > span {
    padding: .5rem 0;
    font-size: 1.4rem;
  }
  .link-down {
    padding-top: 5rem;
    padding-bottom: 0;
  }

  #carousel-main-3 .carousel-control.left {
    left: 0.8rem;
  }
  #carousel-main-3 .carousel-control.right {
    right: 0.8rem;
  }
  .icon-down {
    display: none;
  }
  #carousel-main-3 .carousel-control {
    top: 20rem;
    width: 2.5rem;
    height: 2.5rem;
    border: 0.2rem solid #fe86a4;
    border-radius: 1.3rem;
  }
  #carousel-main-3 .carousel-control.left:after {
    left: .7rem;
    background-position: 0 -0.1rem;
    background-size: 100%;
  }
  #carousel-main-3 .carousel-control.right:after {
    right: .7rem;
    background-position: 0 66%;
    background-size: 100%;
  }
  #carousel-main-3 .carousel-control:after {
    width: .5rem;
    height: 0.9rem;
    top: 50%;
    margin-top: -0.45rem;
  }
  #carousel-main-3 .carousel-inner .item {
    background-size: auto 100% !important;
    background-position: 20%;
  }
  #carousel-main-3 .carousel-inner .item .foreground img {
    width: 29rem;
    max-width: 29rem;
    height: 5rem;
  }
  #carousel-main-3 .carousel-inner .item .foreground {
    padding-left: 4rem;
    padding-right: 26rem;
  }

  //header
  .header .container {
    height: 100%;
  }
  //header

  //fonts
  #carousel-main-3 .carousel-inner .item .foreground h2,
  #carousel-main-3 .carousel-inner .item .foreground h3 {
    font-size: 3.2rem;
    margin-bottom: 2rem;
  }
  #carousel-main-3 .carousel-inner .item .foreground {
    font-size: 2.2rem;
  }
  #carousel-main-3 .carousel-inner .item .foreground .lead {
    font-size: 2.4rem;
  }

  .tabs-scale .tabs .tab {
    border-width: .2rem;
    border-radius: .7rem;
    margin: 0 5.5rem;
    font-size: 2.6rem;
    min-width: 13.0rem;
    height: 5.0rem;
    line-height: 4.4rem;
  }
  .tabs-scale .subtitle {
    font-size: 2.6rem;
    margin-top: 4.3rem;
    height: 10rem;
  }
  .tabs-scale {
    padding-top: 0;
    padding-bottom: 6.1rem;
  }
  .header nav {
    padding: 0 2rem;
  }

  .fancybox-wrap.popup-medium h3, .q-item.popup-medium h3, .prob-benefits.popup-medium h3 {
    font-size: 2.2rem;
    margin-bottom: 3rem;
  }

  .fancybox-wrap .fancybox-skin ul li, .q-item .fancybox-skin ul li, .prob-benefits .fancybox-skin ul li, .fancybox-wrap .popup ul li, .q-item .popup ul li, .prob-benefits .popup ul li {
    padding-bottom: 3rem;
  }
  .fancybox-wrap .fancybox-skin ul li:before, .q-item .fancybox-skin ul li:before, .prob-benefits .fancybox-skin ul li:before, .fancybox-wrap .popup ul li:before, .q-item .popup ul li:before, .prob-benefits .popup ul li:before {
    top: .1rem;
    left: -2rem;
    font-size: 3.2rem;
  }
  .fancybox-wrap.popup-medium .popup-content, .q-item.popup-medium .popup-content, .prob-benefits.popup-medium .popup-content {
    width: 100%;
    padding: 0;
    font-size: 2.2rem;
    line-height: .1rem;
    letter-spacing: .05rem;
  }
  .fancybox-outer {
    width: 105%;
  }
  .fancybox-inner {
    width: 105%;
    padding-right: 17%;
  }

  //inner pages
  .page-new .block-ld-small {
    padding-top: 5rem;
  }

  .page-new .block-ld.prob-2 {
    background-position: center bottom;
  }
  .page-new .block-ld.prob-2 .img {
    img {
      width: 100%;
      height: auto;
    }
    width: 100%;
  }

  .page-new, .page-new p {
    font-size: 2.4rem;
    line-height: 1.2;
  }

  .page-new .block-ld {
    .col-xs-5 {
      width: 100%;
      padding: 0 15%;
    }
    .col-xs-7 {
      width: 100%;
    }
    .container {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    &.prob-1 {
      .col-xs-5 {
        width: 25%;
      }
      .col-xs-7 {
        width: 100%;
        p:first-of-type {
          width: 80%;
        }
      }
    }
  }
  .page-new .block-ld.prob-1 {
    margin-bottom: 0;
  }
  .page-new .block-ld.prob-2 {
    margin-top: 0;
    background: none;
  }
  .page-new .block-ld.prob-3 .img .count b {
    font-size: 6rem;
  }
  .page-new .block-ld.prob-3 .img .count .cell {
    width: 29.6rem;
    height: 29.6rem;
  }
  .page-new .block-ld.prob-3 .img .count {
    width: 29.6rem;
    height: 29.6rem;
    font-size: 1.8rem;
    background-size: cover;
  }

  .page-new .block-ld.prob-3 {
    background-position: 10% bottom;
    background-size: auto 139%;
    padding-top: 0;
    padding-bottom: 2rem;

    .container {
      position: relative;
      .col-xs-7 {
        padding-top: 36rem;
      }
      .col-xs-5 {
        position: absolute;
        top: 0;
        left: -2rem;
      }
    }
  }
  .page-new .block-ld.prob-4 {
    padding-top: 0;
    padding-bottom: 2rem;
    background-image: url(../images/mobile/bact.jpg);
    background-position: center top;
    background-size: cover;

    .container {
      position: relative;
      .col-xs-7 {
        padding-top: 24rem;
      }
    }
  }
  .page-new .block-ld.prob-3 .img {
    margin-top: 2rem;
  }
  .page-new .block-ld {
    background-position: 16%;
  }

  .page-new h4 {
    font-size: 2.6rem;
  }
  .page-new.lead, .page-new p.lead {
    font-size: 2.4rem;
  }
  .prob-benefits .item .icon.icon-2:before {
    background-size: 60%;
  }
  .page-new .block-ld.prob-4 {
    padding-top: 9rem;
    padding-bottom: 2rem;
  }
  .page-new .block-ld {
    padding: 5rem 2rem 0;
  }
  .prob-benefits .item .icon:before {
    background-size: auto 50%;
  }
  .prob-benefits .item .icon {
    width: 14rem;
    height: 14rem;
    background-size: contain;
  }
  .prob-benefits .item {
    padding-bottom: 7rem;
  }
  .prob-benefits {
    padding: 7rem 5.5rem 0 5.5rem;

    .row:nth-child(odd) {
      position: relative;
      .col-xs-4:last-child {
        position: absolute;
        bottom: -100%;
      }
    }
    .row:nth-child(even) {
      .col-xs-4:first-child {
        width: 50%;
        margin-left: 50%;
      }
    }
  }
  .prob-benefits .col-xs-4 {
    width: 50%;
  }
  .prob-benefits .popup {

  }

  .prob-benefits .popup.active {
    top: 4.5rem;
  }
  .prob-benefits .popup .popup-content {
    padding: 0 2rem 1rem;
    .popup-text p {
      font-size: 2.4rem;
      margin-bottom: 1rem;
    }
  }
  .prob-benefits .popup .item {
    padding-bottom: 3.5rem;
    margin-top: -6rem;
  }
  .prob-benefits .item .title {
    margin-top: 2rem;
    line-height: normal;
    font-size: 2.6rem;
  }
  .prob-benefits .item .icon .btn-prob {
    width: 3rem;
    height: 3rem;
    margin-left: -1.5rem;
    margin-bottom: -0.9rem;
    background-size: cover;
    &.close:after {
      background-size: auto 50%;
    }
  }

  .spec-block-1 h1 {
    width: 35rem;
    margin-top: 6rem;
  }
  .spec-block-1 {
    min-height: 39.5rem;
    padding-left: 2.0rem;
    padding-right: 2.0rem;
  }
  .spec-block-2 {
    margin-bottom: -7rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .article-block-2 {
    padding-top: 15.7rem;
    margin-top: -21.3rem;
    padding-bottom: 12.0rem;
  }
  .spec-block-2 .container {
    padding-bottom: 7rem;
  }
  .spec-list .list-item .title {
    font-size: 2.3rem;
    margin-bottom: 2.5rem;
  }
  .paginator {
    bottom: 2rem;
  }
  .paginator .prev,
  .paginator .next {
    height: 2.5rem;
    line-height: 2.5rem;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .paginator ul {
    margin: 0 1rem;
    li {
      margin: 0 .5rem;
      a {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        margin: 0 -0.2rem;
      }
    }
  }
  .spec-block-2 h2 {
    font-size: 3rem;
  }
  .spec-list .list-item .description {
    font-size: 1.8rem;
  }
  .spec-list .icon-date, .spec-list .icon-place {
    margin-right: 1.5rem;
  }
  .spec-list .list-item {
    padding-right: 0;
    padding-bottom: 4.5rem;
  }
  .spec-list .icon-date:before {
    width: 1.3rem;
    height: 1.4rem;
    margin-top: -0.4rem;
    background-size: contain;
    margin-right: .8rem;
  }
  .spec-list .icon-place:before {
    width: 1.5rem;
    height: 1.9rem;
    background-size: contain;
    margin-right: .8rem;
  }
  .article-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .breadcrumbs {
    padding: 3.5rem 0;
  }
  .breadcrumbs li {
    font-size: 1.4rem;
    background-position: 0 .5rem;
    padding-left: 1.0rem;
    padding-right: .5rem;
  }
  .article-container h1 {
    font-size: 3.7rem;
    line-height: 1.2;
    width: 36rem;
    margin-top: 3.5rem;
    max-width: 36rem;
  }
  .faq-block-1 {
    height: 39.5rem;
    background-size: cover;
    background-position: 60% 0px;
  }
  .faq-block-3 {
    padding: 5rem 0;
    text-align: center;
    color: white;
    font-size: 2rem;
    p {
      font-size: 2.4rem;
    }
    br {
      display: none;
    }
  }
  .faq-block-3 h3 {
    font-size: 3.5rem;
    margin-bottom: 4.5rem;
  }
  .faq-block-3 p {
    margin: 4.5rem 0 0 0;
  }
  .fancybox-wrap .fancybox-skin h3,
  .q-item .fancybox-skin h3,
  .prob-benefits .fancybox-skin h3,
  .fancybox-wrap .popup h3,
  .q-item .popup h3,
  .prob-benefits .popup h3 {
    font-size: 3rem;

    @media (orientation: landscape) {
      font-size: 2rem;
    }
  }
  .fancybox-wrap.popup-form .fancybox-inner {
    overflow: auto !important;
  }
  .fancybox-wrap.popup-form .popup-content {
    input, textarea {
      font-size: 2rem;
      &::-webkit-input-placeholder {
        font-size: 2rem;
      }
    }
    textarea {
      height: 16rem;
      resize: none;
    }

  }
  .fancybox-wrap .fancybox-skin .fancybox-close {
    top: -2.2rem;
    right: -2.2rem;
    width: 4.4rem;
    height: 4.4rem;
    &:after {
      width: 1.4rem;
      height: 1.4rem;
      margin-left: -0.7rem;
      margin-top: -0.7rem;
      background-size: cover;
    }
  }
  .fancybox-skin {
    padding: 4rem !important;
  }

  .fancybox-wrap .col-xs-6 {
    width: 100%;
    margin-top: 2rem;
    &:first-child {
      margin-top: -1rem;
    }

  }
  .form-field {
    border: 0.2rem solid #bcbcbc;
    padding: 2rem 2rem;
    width: 100%;
    border-radius: 3rem;
    @media (orientation: landscape) {
      padding: 1rem 1rem;
      width: 100%;
      border-radius: 1.5rem;

    }
  }
  .faq-block-2 .q-item .title {
    font-size: 2.4rem;
    min-height: inherit;
  }
  .faq-block-2 .q-item .popup {
    top: -3rem;
  }
  .faq-block-2 .btn-big.middle {
    font-size: 1.8rem;
    padding: 0 2.0rem;
  }
  .fancybox-wrap.popup-form .popup-content,
  .q-item.popup-form .popup-content,
  .prob-benefits.popup-form .popup-content {
    width: 100%;
    margin: 0 auto;
  }
  .faq-block-2 .row {
    position: relative;
  }
  .faq-block-2 .q-item,
  .faq-block-2 .col-xs-6 {
    position: static;
  }
  .faq-block-2 .q-item {
    width: 30rem;
    padding: 4.5rem 1rem 7.5rem 1rem;
  }
  .faq-slider .item-item .title {
    font-size: 2.3rem;
    margin-top: 2.0rem;
  }
  .faq-block-2 .q-item .icon, .faq-block-2 .q-item .title {
    padding-bottom: 2rem;
  }
  .faq-block-2 .q-item .title br {
    display: none;
  }
  .faq-block-2 .text-center a {
    position: relative !important;
    margin-left: 0 !important;
  }
  .faq-block-2 .btn-big.middle{
    position: absolute;
    width:25rem;
    bottom: 0;
    margin-left: -12.5rem;
  }
  .faq-block-2 .q-item .icon img {
    width: 10rem;
  }
  .faq-block-4 h3 {
    font-size: 4.6rem;
    margin-bottom: 4.5rem;
  }
  .faq-slider .item {
    margin-top: 0;
  }
  .faq-slider .item-item p {
    margin-top: 2.0rem;
    font-size: 1.8rem;
  }
  .faq-block-2 {
    margin-bottom: 4rem;
    padding-bottom: 0;
  }
  .faq-slider .item-item .item-content {
    width: 25rem;
    img {
      width: 100%;
      height: auto;
    }
  }
  .faq-block-2 .q-item .popup .popup-content {
    padding: 2rem 2rem 6rem;
    font-size: 2.2rem;
  }

  .faq-slider {
    margin-bottom: 4rem;
  }
  #carousel-main-3 .carousel-control {
    &:hover {
      background: white;
    }
    &:active {
      background-color: #fe86a4;
    }
  }
  .map-container .list-container {
    padding-top: 13rem;
    padding-bottom: 4.5rem;
    font-size: 2rem;
    width: 100%;
    .col-xs-6 {
      width: 43%;
    }
  }
  .list-container .container > .row [class^='col-'] {
    padding: 2rem 1rem 2rem 2rem !important;
  }
  .ui-menu {
    width: 45.8rem;
    max-height: 50rem;
    overflow: auto;

    font-size: 2.2rem;
    padding: 2.2rem 0 2rem 0;
    margin: 0;
    border-radius: 0 0 2.5rem 2.5rem;
  }
  .gmnoprint .gm-style-mtc {
    top: 10rem !important;
    position: relative;
  }
  .carousel-control.left span,
  .carousel-control.right span {
    width: 1.8rem;
    height: 3.0rem;
    background-size: cover !important;
  }
  .faq-block-4 {
    margin-bottom: -7rem;
    padding: 8.5rem 0 5.5rem 0;
  }
  .faq-slider .carousel-control.right {
    right: -2rem;
  }
  .faq-slider .carousel-control.left {
    left: -2rem;
  }
  .faq-list .list-item {
    padding: 4rem 4rem 1rem 4rem;
    p {
      font-size: 2.4rem;
    }
  }
  .faq-list {
    margin-top: -2.0rem;
    margin-bottom: 20.0rem;
    font-size: 1.6rem;
    border-radius: 1.0rem;
    .paginator {
      bottom: -70px;
      width: 100%;
    }
  }
  .faq-list .list-item .title {
    font-size: 2.4rem;
    margin-bottom: 3.0rem;
  }
}

html.mobile {
  .menu-link {
    display: inline-block;
    float: right;
    padding: 2rem;
    padding-bottom: 1.6rem;
    font-size: 2.2rem;
    background: #f27cb1;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    border-radius: 0.5rem;
    line-height: 1;
    margin-top: 4rem;
    margin-right: 4rem;
    @media (orientation: landscape) {
      padding: 1rem;
      padding-bottom: 0.8rem;
      font-size: 1.1rem;
      border-radius: 0.25rem;
      margin-top: 2rem;
      margin-right: 2rem;
    }
  }
  .header {
    height: 14rem;
    @media (orientation: landscape) {
      height: 7rem;

    }
    .logo-link {
      display: inline-block;
      width: 18rem;
      height: 3.1rem;

      margin-left: 3.6rem;
      margin-top: 5.3rem;

      background-image: url(../images/logo-tabs-scale.png);
      background-size: contain;
      background-repeat: no-repeat;
      @media (orientation: landscape) {
        display: inline-block;
        width: 9rem;
        height: 1.55rem;
        margin-left: 1.8rem;
        margin-top: 2.65rem;
      }
    }
    .container {
      display: none;
      position: absolute;
      top: 14rem;
      height: auto;
      box-shadow: inset 2px 3.464px 8px 0 rgba(0, 0, 0, 0.13);
      @media (orientation: landscape) {
        top: 7rem;
        height: ~"calc(100vh - 7rem)";
        overflow: auto;

      }
    }
  }
  .header nav {
    li {
      display: block;
      width: 100%;
      text-align: center;

    }
    a {


    }
  }

  .baby .btn-forte {
    display: inline-block;
  }

  .tabs-scale.forte .subtitle .text-default,
  .tabs-scale.forte .subtitle .text-baby,
  .tabs-scale.baby .subtitle .text-default {
    opacity: 1;
    visibility: visible;
  }
  .bg-block-23 {
    background: none;
  }
  .tabs-cols {

    margin-bottom: 8rem;
    &.forte {
      margin-bottom: 0;
    }

    .image-forte,
    .image-baby {
      width: 35rem;
    }
    &.baby {
      h4 {
        color: #f27cb1;
        font-size: 2.4rem;
        font-weight: 600;
      }
      .tab-icon {
        background-image: url(../images/tabs-triangle-right.png);
      }
      .icon-item .tab-text {
        color: #f27cb1;
      }
      .btn-more {
        background-color: #f27cb1;
        border-color: #f27cb1;
        &:hover {
          background-color: transparent;
          color: #f27cb1;
        }
      }
    }
    &.forte {
      h4 {
        color: #f29f05;
        font-size: 2.4rem;
        font-weight: 600;
      }
      .tab-icon {
        background-image: url(../images/tabs-triangle-left.png);
      }
      .icon-item .tab-text {
        color: #f29f05;
      }
      .btn-more {
        background-color: #f29f05;
        border-color: #f29f05;
        &:hover {
          background-color: transparent;
          color: #f29f05;
        }
      }
    }

    .icon-item {
      display: inline-block;
      vertical-align: top;
      width: 49%;
      padding: 0 1rem;
      cursor: pointer;
      text-align: center;
      margin-bottom: 4rem;
      &:hover .tab-text {
        text-decoration: underline;
      }
      .tab-icon {
        display: inline-block;
      }
      .tab-text {
        width: 100%;
        font-size: 2.4rem;
        box-sizing: border-box;
        font-family: 'conqueror_sans_medium', Arial, sans-serif;
        font-weight: normal;
        padding-left: 1rem;
        padding-right: 1rem;
        color: @color-baby;
        display: inline-block;
        cursor: pointer;
        margin-top: 0.5rem;
      }

    }
    .tabs-desc {
      width: 100%;
      background-position: center top;
      padding-top: 3rem !important;
      padding-bottom: 2rem;
      text-decoration: underline;
    }

  }
}

.menu-blur {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 5;
  top: 0;
  left: 0;
}
