/* Generated by Font Squirrel (https://www.fontsquirrel.com) on April 25, 2016 */
@font-face {
  font-family: 'univers_bold';
  src: url('../fonts/Univers-Bold_0.eot');
  src: local('☺'), url('../fonts/Univers-Bold_0.woff') format('woff'), url('../fonts/Univers-Bold_0.ttf') format('truetype'), url('../fonts/Univers-Bold_0.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'conqueror_sans_regular';
  src: url('../fonts/conquerorsans-webfont.eot');
  src: url('../fonts/conquerorsans-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/conquerorsans-webfont.woff2') format('woff2'), url('../fonts/conquerorsans-webfont.woff') format('woff'), url('../fonts/conquerorsans-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'conqueror_sans_bold';
  src: url('../fonts/conquerorsans-bold-webfont.eot');
  src: url('../fonts/conquerorsans-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/conquerorsans-bold-webfont.woff2') format('woff2'), url('../fonts/conquerorsans-bold-webfont.woff') format('woff'), url('../fonts/conquerorsans-bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'conqueror_sans_black';
  src: url('../fonts/conquerorsans-black-webfont.eot');
  src: url('../fonts/conquerorsans-black-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/conquerorsans-black-webfont.woff2') format('woff2'), url('../fonts/conquerorsans-black-webfont.woff') format('woff'), url('../fonts/conquerorsans-black-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'conqueror_sans_extrabold';
  src: url('../fonts/conquerorsans-extrabold-webfont.eot');
  src: url('../fonts/conquerorsans-extrabold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/conquerorsans-extrabold-webfont.woff2') format('woff2'), url('../fonts/conquerorsans-extrabold-webfont.woff') format('woff'), url('../fonts/conquerorsans-extrabold-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'conqueror_sans_light';
  src: url('../fonts/conquerorsans-light-webfont.eot');
  src: url('../fonts/conquerorsans-light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/conquerorsans-light-webfont.woff2') format('woff2'), url('../fonts/conquerorsans-light-webfont.woff') format('woff'), url('../fonts/conquerorsans-light-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'conqueror_sans_medium';
  src: url('../fonts/conquerorsans-medium-webfont.eot');
  src: url('../fonts/conquerorsans-medium-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/conquerorsans-medium-webfont.woff2') format('woff2'), url('../fonts/conquerorsans-medium-webfont.woff') format('woff'), url('../fonts/conquerorsans-medium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'conqueror_sans_semibold';
  src: url('../fonts/conquerorsans-semibold-webfont.eot');
  src: url('../fonts/conquerorsans-semibold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/conquerorsans-semibold-webfont.woff2') format('woff2'), url('../fonts/conquerorsans-semibold-webfont.woff') format('woff'), url('../fonts/conquerorsans-semibold-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
/* ==========================================================================
	* Обновление стилей
	* За основу взяты Normalize и некоторые стили Boilerplate
	========================================================================== */
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
figure {
  margin: 0;
}
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}
::selection {
  background: #b3d4fc;
  text-shadow: none;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}
/* ==========================================================================
	* Цвета
	========================================================================== */
/* ==========================================================================
	* fonts / Шрифты применяемые на сайте
	========================================================================== */
/* ==========================================================================
	* colors / Цвета применяемые на сайте
	========================================================================== */
/* ==========================================================================
	* Сетка / Grid system (pulled from core Bootstrap)
	========================================================================== */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 1345px) {
  .container {
    width: auto;
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.row {
  margin-left: -10px;
  margin-right: -10px;
}
.col, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}
.col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
.clearfix,
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfix {
  width: 100%;
}
/* ==========================================================================
	* MIXINS
	========================================================================== */
.border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.cf,
clearfix {
  display: block;
  clear: both;
}
/* ==========================================================================
	Typography palitra
	========================================================================== */
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html,
body {
  font-family: 'conqueror_sans_regular', 'Arial', sans-serif;
  line-height: 1.142857142857143;
  color: #333;
  font-size: 14px;
  background: #fff;
}
body {
  -webkit-font-smoothing: subpixel-antialiased;
}
/* Сбрасываем отступы для всех элементов сайта */
li {
  list-style-type: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
li {
  margin: 0;
  padding: 0;
  line-height: 1.2em;
}
/* т3 - 9.5. Отступы между заголовками H и текстами */
p {
  margin-bottom: 1.3em;
  line-height: 1.2em;
  font-size: 1em;
}
h1 {
  margin-bottom: 1.5em;
}
h2 {
  margin-bottom: 1.3em;
}
h3 {
  margin-bottom: 1.2em;
}
h4,
h5,
h6 {
  margin-bottom: 1em;
}
a {
  color: #fe86a4;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.forte a {
  color: #f28f2c;
}
/* ==========================================================================
	* wrapper / побертка
	========================================================================== */
.wrapper {
  position: relative;
  padding-top: 90px;
}
.clear {
  clear: both;
}
/* ==========================================================================
	* header / шапка
	========================================================================== */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 900;
  background: #fff;
}
.header .container {
  height: 90px;
  padding: 19px 0 0;
  background: #fff;
  /*min-width: 100%;*/
}
.header nav {
  padding: 0 20px;
}
.header nav ul {
  text-align: justify;
  padding: 0;
  margin: 0;
}
.header nav ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}
.header nav ul:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.header nav a {
  display: inline-block;
  padding: 16px 28px 15px;
  color: black;
  font-size: 1em;
  line-height: 1em;
  text-transform: uppercase;
  text-decoration: none;
  border: 2px solid transparent;
}
.header nav a.bak-set-baby {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -moz-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -o-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
.header nav a.bak-set-baby:hover,
.header nav a.bak-set-baby.active {
  color: #fe86a4;
  border: 2px solid #fe86a4;
}
.header nav a.bak-set-forte {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -moz-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -o-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
.header nav a.bak-set-forte:hover,
.header nav a.bak-set-forte.active {
  color: #f28f2c;
  border: 2px solid #f28f2c;
}
.header nav a.not-btn {
  width: 185px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.header nav a.not-btn:hover,
.header nav a.not-btn.active {
  text-decoration: underline;
  font-family: 'conqueror_sans_medium';
}
.header nav a b {
  font-family: 'conqueror_sans_extrabold';
  font-weight: normal;
}
.header nav a.active:hover {
  text-decoration: none;
}
.header nav a.nab-btn {
  padding: 14px 28px;
  color: #f28f2c;
  font-size: 18px;
  font-family: 'conqueror_sans_medium';
  font-weight: normal;
  border: 2px solid #f28f2c;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.header nav a.nab-btn:hover,
.header nav a.nab-btn.active {
  color: white;
  background: #f28f2c;
  text-decoration: none;
  -webkit-box-shadow: 0 10px 25px rgba(242, 143, 44, 0.59);
  -moz-box-shadow: 0 10px 25px rgba(242, 143, 44, 0.59);
  box-shadow: 0 10px 25px rgba(242, 143, 44, 0.59);
}
/* ==========================================================================
	* block 3
	========================================================================== */
.flora-title {
  color: #fe86a4;
  font-size: 46px;
  font-family: 'conqueror_sans_extrabold';
  text-align: center;
  margin-bottom: 55px;
}
.flora-text {
  overflow: hidden;
  margin: 0 157px 0 167px;
  padding-bottom: 75px;
}
.flora-text div {
  float: right;
  width: 383px;
  color: #565656;
  font-size: 18px;
  line-height: 1.4;
  font-family: 'conqueror_sans_light';
}
.flora-text div:first-child {
  float: left;
}
/* ==========================================================================
	* block 4
	========================================================================== */
.bg-block-4 {
  height: 570px;
  background-image: url('../images/bg-block-4.jpg');
  background-size: initial;
  background-position: 50% 0;
  background-repeat: no-repeat;
}
.bg-block-4 .col-md-12 {
  position: relative;
  overflow: hidden;
  height: 570px;
}
.bg-block-4 .col-md-12 .carousel-main-baby {
  position: absolute;
  left: 136px;
  bottom: -67px;
}
.bakset-baby-title {
  margin-top: 54px;
  color: #fe86a4;
  font-family: 'conqueror_sans_extrabold';
  font-size: 46px;
  line-height: 60px;
  text-align: center;
}
.bakset-baby-title b {
  font-size: 60px;
  color: #231f20;
  font-size: 63px;
  letter-spacing: -0.01em;
}
.bakset-baby-title b span {
  text-transform: uppercase;
  zoom: 0.69;
}
/* ==========================================================================
	* block 5 / 7
	========================================================================== */
.bg-block-5 {
  height: 658px;
  background-image: url('../images/bg-block-5.jpg');
  background-size: initial;
  background-position: 50% 0;
  background-repeat: no-repeat;
}
.bg-block-5 .col-md-12 {
  position: relative;
  height: 658px;
}
.how-work {
  margin: 0 12%;
  text-align: center;
}
@media (max-width: 1200px) {
  .how-work {
    margin: 0 auto 0 auto;
    width: 850px;
  }
}
.how-work h4 {
  margin-top: 52px;
  margin-bottom: 0;
  color: #fe86a4;
  font-size: 46px;
  font-family: 'conqueror_sans_extrabold';
  font-weight: normal;
}
.how-work h4 span {
  color: #231f20;
  font-size: 60px;
  font-size: 63px;
  letter-spacing: -0.01em;
}
.how-work h4 span b {
  text-transform: uppercase;
  zoom: 0.69;
}
.how-work .description {
  margin-top: 70px;
  float: left;
  width: 430px;
  text-align: left;
}
.how-work .description .desc-title {
  font: 'conqueror_sans_semibold';
  font-weight: normal;
  font-size: 23px;
}
.how-work .description p {
  margin-top: 25px;
  margin-bottom: 0;
  color: #565656;
  font-size: 18px;
  line-height: 25px;
  font-family: 'conqueror_sans_light';
  font-weight: normal;
}
.how-work .description p span {
  color: #fe86a4;
}
.how-work .how-work-image {
  margin-top: 57px;
  float: right;
}
.how-work .more-knew {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 39px;
  z-index: 3;
  display: inline-block;
  color: #fe86a4;
  font-size: 18px;
  font-family: 'conqueror_sans_medium';
  font-weight: normal;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.how-work .more-knew:before {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  width: 122px;
  height: 1px;
  margin: 0 auto;
  background: #fe86a4;
}
.how-work .more-knew:hover {
  color: #565656;
}
.how-work .more-knew:hover:before {
  background: #565656;
}
.how-work.block-7 h4 {
  color: #f28f2c;
}
.how-work.block-7 .description {
  float: right;
}
.how-work.block-7 .description p span {
  /*color: #f04c2d;*/
  color: #f28f2c;
}
.how-work.block-7 .how-work-image {
  float: left;
}
.how-work.block-7 .more-knew {
  color: #f28f2c;
}
.how-work.block-7 .more-knew:before {
  background: #f28f2c;
}
.how-work.block-7 .more-knew:hover {
  color: #565656;
}
.how-work.block-7 .more-knew:hover:before {
  background: #565656;
}
/* ==========================================================================
	* block 6
	========================================================================== */
.bg-block-6 {
  height: 635px;
  background-image: url('../images/bg-block-6-2.jpg');
  background-size: initial;
  background-position: 50% 0;
  background-repeat: no-repeat;
}
.bg-block-6 .col-md-12 {
  position: relative;
  overflow: hidden;
  height: 635px;
}
.bg-block-6 .col-md-12 .carousel-main-baby {
  position: absolute;
  right: 142px;
  bottom: -67px;
}
.bakset-forte-title {
  margin-top: 68px;
  color: #f28f2c;
  font-family: 'conqueror_sans_extrabold';
  font-size: 46px;
  line-height: 60px;
  text-align: center;
}
.bakset-forte-title b {
  font-size: 60px;
  color: #231f20;
  font-size: 63px;
  letter-spacing: -0.01em;
}
.bakset-forte-title b span {
  text-transform: uppercase;
  zoom: 0.69;
}
/* ==========================================================================
	* block 7
	========================================================================== */
.bg-block-7 {
  height: 720px;
  background-image: url('../images/bg-block-7.jpg');
  background-size: initial;
  background-position: 50% 0;
  background-repeat: no-repeat;
}
.bg-block-7 .col-md-12 {
  position: relative;
  height: 720px;
}
@media (min-width: 1524px) {
  .bg-block-4 {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50%;
  }
  .bg-block-5 {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .bg-block-6 {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50%;
  }
  .bg-block-7 {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
/**
 *  Стили Антона ;)
 */
/* Фон под 2-й и 3-й блоки */
.bg-block-23 {
  background-image: url('../images/bg-block-23.jpg');
  background-size: initial;
  background-position: 50% 0;
  background-repeat: no-repeat;
}
.btn-big {
  border: 2px solid #fe86a4;
  color: #fe86a4;
  text-transform: uppercase;
  padding: 0 60px;
  height: 60px;
  line-height: 58px;
  font-size: 20px;
  font-family: 'conqueror_sans_medium', Arial, sans-serif;
  font-weight: normal;
  text-decoration: none;
  display: inline-block;
  outline: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  -webkit-transition: color .2s ease, background .2s ease;
  -moz-transition: color .2s ease, background .2s ease;
  -o-transition: color .2s ease, background .2s ease;
  transition: color .2s ease, background .2s ease;
  background-color: white;
}
.btn-big.middle {
  height: 50px;
  line-height: 48px;
  font-size: 18px;
  padding: 0 52px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}
.btn-big:hover {
  color: white;
  background-color: #fe86a4;
}
.btn-big.invert {
  border-color: #fe86a4;
  color: white;
  background-color: #fe86a4;
}
.btn-big.invert:hover {
  color: #fe86a4;
  background-color: white;
}
.footer-text {
  width: 1004px;
  height: 65px;
  margin: 30px auto;
  background-image: url('../images/footer-text.png');
  background-repeat: no-repeat;
  text-indent: -9999px;
}
footer {
  background-color: #f8f8f8;
  margin-top: 70px;
  padding: 25px 0 40px 0;
  padding-bottom: 25px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.footer-socials .social-item {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 45px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  border: 2px solid #fe86a4;
  margin: 0 8px;
  background-color: white;
  vertical-align: middle;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}
.footer-socials .social-item:hover {
  background-color: #fe86a4;
}
.footer-socials .social-item.fb:after {
  content: "";
  display: inline-block;
  width: 11px;
  height: 21px;
  background-image: url('../images/icon-fb.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  vertical-align: middle;
}
.footer-socials .social-item.fb:hover:after {
  background-position: 0 -21px;
}
.footer-socials .social-item.ok:after {
  content: "";
  display: inline-block;
  width: 13px;
  height: 23px;
  background-image: url('../images/icon-ok.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  vertical-align: middle;
}
.footer-socials .social-item.ok:hover:after {
  background-position: 0 -23px;
}
.footer-socials .social-item.vk:after {
  content: "";
  display: inline-block;
  width: 25px;
  height: 15px;
  background-image: url('../images/icon-vk.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  vertical-align: middle;
}
.footer-socials .social-item.vk:hover:after {
  background-position: 0 -15px;
}
.pharmamed {
  margin-top: 7.5px;
  display: inline-block;
}
.developer {
  display: inline-block;
  width: 141px;
  height: 23px;
  background-image: url('../images/developer.png');
  background-repeat: no-repeat;
  margin-top: 15px;
}
.anchor-container {
  position: relative;
}
.anchor-container > div {
  position: absolute;
  top: -100px;
}
.over-slider {
  position: relative;
}
.over-slider > .wrap {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.benefit-icons {
  text-align: center;
}
.benefit-icons .icon-item {
  position: relative;
  width: 120px;
  height: 120px;
  line-height: 70px;
  border: 2px solid #fe86a4;
  display: inline-block;
  margin: 0 43px;
  background-color: white;
  /*color:#6b6868;*/
  color: #565656;
  font-size: 12px;
  text-align: center;
  vertical-align: top;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.benefit-icons .icon-item img {
  vertical-align: top;
}
.benefit-icons .icon-item img.icon-1 {
  margin-top: 9px;
}
.benefit-icons .icon-item img.icon-2 {
  margin-top: 8px;
}
.benefit-icons .icon-item img.icon-3 {
  margin-top: 8px;
}
.benefit-icons .icon-item img.icon-4 {
  margin-top: 15px;
}
.benefit-icons .icon-item img.icon-5 {
  margin-top: 5px;
}
.benefit-icons .icon-item > span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 0;
  line-height: 1.2;
  font-size: 12px;
  font-weight: bold;
  font-family: 'univers_bold', Arial, sans-serif;
  font-weight: normal;
  /*font-family:arial, sans-serif;*/
}
.icon-down {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 44px;
  background: white;
  border: 2px solid #fe86a4;
  cursor: pointer;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  border-radius: 22px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.icon-down:after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 7px;
  background-image: url('../images/down-arrow.png');
  background-repeat: no-repeat;
  background-position: -1px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -3px;
}
.icon-down:hover {
  /*opacity:.5;*/
  background-color: #fe86a4;
}
.icon-down:hover:after {
  background-position: -15px 0;
}
.link-down {
  padding-top: 88px;
  padding-bottom: 20px;
  text-align: center;
}
@media (min-width: 1524px) {
  .bg-block-23 {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
.tabs-scale .tabs-content .image-baby,
.tabs-scale .tabs-content .image-forte {
  width: 27%;
  max-width: 350px;
  margin: 0 2%;
}
.tabs-scale.forte .tabs-content .right {
  transform: scale(0.7);
}
.tabs-scale .tabs-content .right,
.tabs-scale .tabs-content .left {
  transform: scale(0.9);
}
.tabs-scale.baby .tabs-content .left {
  transform: scale(0.7);
}
.tabs-scale {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 61px;
}
.tabs-scale .tabs {
  text-align: center;
}
.tabs-scale .tabs .logo-title {
  position: relative;
  display: inline-block;
  width: 280px;
  height: 46px;
  background-image: url('../images/logo-tabs-scale.png');
  background-repeat: no-repeat;
  text-indent: -9999px;
  vertical-align: middle;
}
.tabs-scale .tabs .logo-title .subtext-forte,
.tabs-scale .tabs .logo-title .subtext-baby {
  position: absolute;
  left: 0;
  bottom: -1.2em;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  text-indent: 0;
  font-family: 'conqueror_sans_semibold', Arial, sans-serif;
  font-weight: normal;
  font-size: 34px;
}
.tabs-scale .tabs .logo-title .subtext-forte {
  color: #f28f2c;
}
.tabs-scale .tabs .logo-title .subtext-baby {
  color: #fe86a4;
}
.tabs-scale .tabs .tab {
  border-width: 2px;
  border-style: solid;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  margin: 0 55px;
  font-size: 26px;
  font-weight: bold;
  text-decoration: none;
  min-width: 130px;
  height: 50px;
  line-height: 44px;
  display: inline-block;
  vertical-align: middle;
}
.tabs-scale .tabs .tab.left,
.tabs-scale .tabs .tab.right {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.tabs-scale .tabs .tab.left {
  color: #f28f2c;
  border-color: #f28f2c;
}
.tabs-scale .tabs .tab.left:hover {
  background-color: #f28f2c;
  color: white;
}
.tabs-scale .tabs .tab.right {
  color: #fe86a4;
  border-color: #fe86a4;
}
.tabs-scale .tabs .tab.right:hover {
  background-color: #fe86a4;
  color: white;
}
.tabs-scale .subtitle {
  position: relative;
  /*color:#625e60;*/
  color: #565656;
  font-size: 26px;
  font-family: 'conqueror_sans_semibold', Arial, sans-serif;
  font-weight: normal;
  text-align: center;
  margin-top: 43px;
  height: 100px;
}
.tabs-scale .subtitle .text-default,
.tabs-scale .subtitle .text-forte,
.tabs-scale .subtitle .text-baby {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.tabs-scale .subtitle .text-default {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -o-opacity: 1;
  opacity: 1;
  visibility: visible;
}
.tabs-scale .subtitle .text-forte,
.tabs-scale .subtitle .text-baby {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
  visibility: hidden;
}
.tabs-scale .subtitle .text-forte {
  color: #f28f2c;
}
.tabs-scale .subtitle .text-baby {
  color: #fe86a4;
}
.tabs-scale .tabs-content {
  position: relative;
  text-align: center;
  /*height:530px;*/
  height: 480px;
}
.tabs-scale .tabs-content--bottom {
  width: 980px;
  margin: 0 auto 50px;
}
.tabs-scale .tabs-content--bottom:after {
  content: '';
  display: table;
  clear: both;
}
.tabs-scale .tabs-content--bottom .tab-icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  /*img {
              margin-top: -3px;
              margin-left: -1px;
            }*/
}
.tabs-scale .tabs-content--bottom img {
  margin-top: 0;
  margin-left: -2px;
}
.tabs-scale .tabs-content--bottom .icon-item {
  float: left;
  width: 50%;
  padding: 0 50px;
  cursor: pointer;
  text-align: left;
}
.tabs-scale .tabs-content--bottom .icon-item:hover .tab-text {
  text-decoration: underline;
}
.tabs-scale .tabs-content--bottom .icon-item:nth-child(odd) {
  text-align: right;
}
.tabs-scale .tabs-content--bottom .icon-item .tab-text {
  padding-top: 7px;
  width: 242px;
  font-size: 18px;
  box-sizing: border-box;
  font-family: 'conqueror_sans_medium', Arial, sans-serif;
  font-weight: normal;
  padding-left: 15px;
  color: #fe86a4;
  display: inline-block;
  cursor: pointer;
}
.tabs-scale .tabs-content .image-forte,
.tabs-scale .tabs-content .image-baby {
  cursor: pointer;
  position: absolute;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-backface-visibility: hidden;
}
.tabs-scale .tabs-content .image-forte {
  left: 23%;
  z-index: 1;
  top: 40px;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}
.tabs-scale .tabs-content .image-baby {
  right: 23%;
  z-index: 2;
  top: 48px;
}
.tabs-scale .tabs-content .left,
.tabs-scale .tabs-content .right {
  position: absolute;
  width: 325px;
  top: 25px;
  z-index: 3;
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
}
.tabs-scale .tabs-content .left .icon-item,
.tabs-scale .tabs-content .right .icon-item {
  padding-bottom: 20px;
  cursor: pointer;
}
.tabs-scale .tabs-content .left .icon-item .tab-text,
.tabs-scale .tabs-content .right .icon-item .tab-text {
  padding-top: 7px;
  width: 242px;
  font-size: 18px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'conqueror_sans_medium', Arial, sans-serif;
  font-weight: normal;
}
.tabs-scale .tabs-content .left .icon-item:after,
.tabs-scale .tabs-content .right .icon-item:after {
  content: "";
  display: block;
  clear: both;
}
.tabs-scale .tabs-content .left .icon-item:hover .tab-text,
.tabs-scale .tabs-content .right .icon-item:hover .tab-text {
  text-decoration: underline;
}
.tabs-scale .tabs-content .left:after,
.tabs-scale .tabs-content .right:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.tabs-scale .tabs-content .left {
  left: 0;
}
.tabs-scale .tabs-content .left .tab-icon {
  float: right;
  background-image: url('../images/tabs-triangle-left.png');
}
.tabs-scale .tabs-content .left .tab-text {
  float: right;
  text-align: right;
  padding-right: 15px;
  color: #f28f2c;
}
.tabs-scale .tabs-content .right {
  right: 0;
}
.tabs-scale .tabs-content .right .tab-icon {
  float: left;
  background-image: url('../images/tabs-triangle-right.png');
}
.tabs-scale .tabs-content .right .tab-text {
  float: left;
  text-align: left;
  padding-left: 15px;
  color: #fe86a4;
}
.tabs-scale.forte .subtitle .text-forte {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -o-opacity: 1;
  opacity: 1;
  visibility: visible;
}
.tabs-scale.forte .subtitle .text-default,
.tabs-scale.forte .subtitle .text-baby {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
  visibility: hidden;
}
.tabs-scale.forte .tabs .left {
  background-color: #f28f2c;
  color: #fff;
}
.tabs-scale.forte .tabs-content .right {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  -o-opacity: 0.5;
  opacity: 0.5;
}
.tabs-scale.forte .tabs-content .left:after {
  display: none;
}
.tabs-scale.forte .tabs .logo-title .subtext-forte {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -o-opacity: 1;
  opacity: 1;
  visibility: visible;
}
.tabs-scale.forte .tabs-content .image-forte {
  z-index: 3;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  left: 23%;
  -webkit-animation: forte-animation-show 0.6s ease-out forwards;
  animation: forte-animation-show 0.6s ease-out forwards;
}
@-webkit-keyframes forte-animation-show {
  0% {
    left: 26%;
  }
  35% {
    left: 23%;
  }
  100% {
    left: 29%;
  }
}
@keyframes forte-animation-show {
  0% {
    left: 26%;
  }
  35% {
    left: 23%;
  }
  100% {
    left: 29%;
  }
}
.tabs-scale.forte .tabs-content .image-baby {
  z-index: 1;
  right: 23%;
  -webkit-animation: baby-animation-hide 0.6s ease forwards;
  animation: baby-animation-hide 0.6s ease forwards;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
}
@-webkit-keyframes baby-animation-hide {
  0% {
    right: 26%;
  }
  35% {
    right: 23%;
  }
  100% {
    right: 29%;
  }
}
@keyframes baby-animation-hide {
  0% {
    right: 26%;
  }
  35% {
    right: 23%;
  }
  100% {
    right: 29%;
  }
}
.tabs-scale.baby .subtitle .text-baby {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -o-opacity: 1;
  opacity: 1;
  visibility: visible;
}
.tabs-scale.baby .subtitle .text-default,
.tabs-scale.baby .subtitle .text-forte {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
  visibility: hidden;
}
.tabs-scale.baby .tabs .right {
  background-color: #fe86a4;
  color: #fff;
}
.tabs-scale.baby .tabs-content .left {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  -o-opacity: 0.5;
  opacity: 0.5;
}
.tabs-scale.baby .tabs-content .right:after {
  display: none;
}
.tabs-scale.baby .tabs .logo-title .subtext-baby {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -o-opacity: 1;
  opacity: 1;
  visibility: visible;
}
.tabs-scale.baby .tabs-content .image-forte {
  z-index: 1;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
}
.tabs-scale.baby .tabs-content .image-baby {
  z-index: 2;
  right: 380px;
}
.tabs-scale.baby.transition-back .tabs-content .image-forte {
  z-index: 1;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
  -webkit-animation: forte-animation-hide 0.6s ease-out forwards;
  animation: forte-animation-hide 0.6s ease-out forwards;
}
@-webkit-keyframes forte-animation-hide {
  0% {
    right: 26%;
  }
  35% {
    right: 23%;
  }
  100% {
    right: 29%;
  }
}
@keyframes forte-animation-hide {
  0% {
    right: 26%;
  }
  35% {
    right: 23%;
  }
  100% {
    right: 29%;
  }
}
.tabs-scale.baby.transition-back .tabs-content .image-baby {
  z-index: 2;
  right: 26%;
  -webkit-animation: baby-animation-show 0.6s ease forwards;
  animation: baby-animation-show 0.6s ease forwards;
}
@-webkit-keyframes baby-animation-show {
  0% {
    right: 26%;
  }
  35% {
    right: 23%;
  }
  100% {
    right: 29%;
  }
}
@keyframes baby-animation-show {
  0% {
    right: 26%;
  }
  35% {
    right: 23%;
  }
  100% {
    right: 29%;
  }
}
.tabs-scale .tabs-desc {
  font-size: 22px;
  /*color:#625e60;*/
  /*color:@color-text;*/
  padding-left: 40px;
  min-height: 29px;
  line-height: 29px;
  font-family: 'conqueror_sans_light', Arial, sans-serif;
  font-weight: normal;
  background-image: url('../images/icon-alert.png');
  background-repeat: no-repeat;
  background-position: 0;
  display: inline-block;
  margin-bottom: 30px;
  cursor: pointer;
}
.tabs-scale .tabs-desc:hover {
  text-decoration: underline;
}
.tabs-scale.forte .btn-more {
  background-color: #f28f2c;
  border-color: #f28f2c;
  color: white;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.tabs-scale.forte .btn-more:hover {
  background-color: white;
  border-color: #f28f2c;
  color: #f28f2c;
}
.tabs-scale.baby .btn-more {
  background-color: #fe86a4;
  border-color: #fe86a4;
  color: white;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.tabs-scale.baby .btn-more:hover {
  background-color: white;
  border-color: #fe86a4;
  color: #fe86a4;
}
.btn-forte,
.btn-baby {
  display: none;
}
.tabs-scale.forte .btn-forte {
  display: inline-block;
}
.tabs-scale.baby .btn-baby {
  display: inline-block;
}
.tab-icon {
  display: block;
  width: 83px;
  height: 77px;
  background-repeat: no-repeat;
}
.tab-icon img {
  margin-top: 8px;
}
.forte .tab-icon {
  background-image: url('../images/tabs-triangle-left.png');
}
.baby .tab-icon {
  background-image: url('../images/tabs-triangle-right.png');
}
.forte .btn-baby {
  display: none;
}
.baby .btn-forte {
  display: none;
}
.forte .tabs-content--bottom .tab-icon {
  background-image: url('../images/tabs-triangle-left.png');
}
.forte .tabs-content--bottom .tab-text {
  color: #f28f2c !important;
}
@media (min-width: 1366px) {
  .tabs-scale .tabs-content .left,
  .tabs-scale .tabs-content .right {
    width: 345px;
  }
  .tabs-scale .tabs-content .left {
    left: -20px;
  }
  .tabs-scale .tabs-content .right {
    right: -20px;
  }
  .tabs-scale .tabs-content .left .icon-item .tab-text,
  .tabs-scale .tabs-content .right .icon-item .tab-text {
    width: 262px;
  }
}
@media (max-width: 1250px) {
  .tabs-scale .tabs-content .image-baby,
  .tabs-scale .tabs-content .image-forte {
    width: 20%;
    margin: 50px 6% 0;
  }
}
@media (min-width: 1250px) {
  .tabs-scale .tabs-content .left,
  .tabs-scale .tabs-content .right {
    width: 300px;
  }
  .tabs-scale .tabs-content .left {
    left: -20px;
  }
  .tabs-scale .tabs-content .right {
    right: -20px;
  }
  .tabs-scale .tabs-content .left .icon-item .tab-text,
  .tabs-scale .tabs-content .right .icon-item .tab-text {
    width: 212px;
  }
}
.block-map {
  text-align: center;
  padding: 55px 0;
}
.block-map h3 {
  font-size: 46px;
  /*color:#494949;*/
  color: #565656;
  font-family: 'conqueror_sans_extrabold', Arial, sans-serif;
  font-weight: normal;
}
.block-map .map {
  height: 675px;
}
.block-map .map-container {
  position: relative;
  margin-bottom: 30px;
}
.block-map .map-container .map-filter {
  width: 540px;
  text-align: center;
  position: absolute;
  top: 30px;
  left: 50%;
  margin-left: -250px;
  z-index: 5;
}
.block-map .map-container .map-filter input[name=city],
.block-map .map-container .map-filter button[type=submit],
.block-map .map-container .map-filter .map-switch {
  margin: 0 17px;
}
.block-map .map-container .map-filter input[name=city] {
  border: 2px solid #fe86a4;
  background: white;
  padding: 15px 20px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 190px;
}
.block-map .map-container .map-filter button[type=submit] {
  border: 2px solid #fe86a4;
  background: #fe86a4;
  color: white;
  padding: 15px 20px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 190px;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.block-map .map-container .map-filter button[type=submit]:hover {
  color: #fe86a4;
  background-color: white;
}
.block-map .map-container .map-filter .map-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  /*border:2px solid @color-rose;*/
  background: #fe86a4;
  cursor: pointer;
  outline: none;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.block-map .map-container .map-filter .map-switch > .icon-list,
.block-map .map-container .map-filter .map-switch > .icon-map {
  position: absolute;
  top: 50%;
  height: 50%;
  left: 50%;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.block-map .map-container .map-filter .map-switch > .icon-list {
  width: 19px;
  height: 12px;
  background-image: url(../images/icon-map-list.png);
  background-repeat: no-repeat;
  margin-left: -10px;
  margin-top: -6px;
}
.block-map .map-container .map-filter .map-switch > .icon-map {
  width: 17px;
  height: 24px;
  background-image: url(../images/icon-map.png);
  background-repeat: no-repeat;
  margin-left: -9px;
  margin-top: -12px;
  opacity: 0;
  visibility: hidden;
}
.block-map .map-container .map-filter .map-switch:hover {
  background: #fe9fb7;
}
.block-map .map-container .list-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding-top: 130px;
  padding-bottom: 45px;
  text-align: left;
  font-size: 16px;
  color: #565656;
  line-height: 1.4;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 0;
  visibility: hidden;
}
.block-map .map-container .list-container a {
  color: #fe86a4;
  text-decoration: none;
}
.block-map .map-container .list-container a:hover {
  text-decoration: underline;
}
.block-map .map-container .list-container .no-site {
  color: #565656;
}
.block-map .map-container .list-container .container {
  max-height: 100%;
  overflow: auto;
}
.block-map .map-container .list-container .container > .row:nth-child(odd) {
  background-color: #f8f8f8;
}
.block-map .map-container .list-container .container > .row [class^='col-'] {
  padding: 20px 10px 20px 40px;
}
.block-map .map-container.list-view .map-switch > .icon-map {
  opacity: 1;
  visibility: visible;
}
.block-map .map-container.list-view .map-switch > .icon-list {
  opacity: 0;
  visibility: hidden;
}
.block-map .map-container.list-view .list-container {
  opacity: 1;
  visibility: visible;
}
.block-map .gm-style-bg {
  background-color: white;
  -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  border-radius: 25px !important;
}
.block-map .gm-style-arrow {
  z-index: 1 !important;
  border-top-color: white !important;
  margin-top: -1px;
}
.block-map .gm-style-close {
  width: 28px !important;
  height: 28px !important;
  opacity: 1 !important;
  top: -8px !important;
  right: -8px !important;
  overflow: visible !important;
}
.block-map .gm-style-close img {
  display: none;
}
.block-map .gm-style-close:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fe86a4;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.block-map .gm-style-close:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  background-image: url(../images/icon-x.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.block-map .gm-style-close:hover:before {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.block-map .gm-style-iw .window-content {
  width: 280px;
  padding: 26px 20px;
  color: #565656;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.block-map .gm-style-iw .window-content .w-title {
  font-size: 20px;
  color: #fe86a4;
  text-transform: uppercase;
  margin-bottom: 25px;
  font-family: 'conqueror_sans_medium', Arial, sans-serif;
  font-weight: normal;
}
.block-map .gm-style-iw .window-content p {
  font-size: 18px;
  line-height: 1.4;
  margin: 0;
  font-family: 'conqueror_sans_light', Arial, sans-serif;
  font-weight: normal;
}
#city-field {
  position: relative;
  z-index: 3;
}
.ui-widget-content {
  border: none;
}
.ui-menu {
  z-index: 2;
  font-size: 14px;
  padding: 23px 0 15px 0;
  margin: 0;
  font-family: 'conqueror_sans_regular', Arial, sans-serif;
  font-weight: normal;
  -webkit-border-radius: 0 0 15px 15px;
  -moz-border-radius: 0 0 15px 15px;
  border-radius: 0 0 15px 15px;
}
.ui-menu.add-margin {
  margin-top: -23px;
}
.ui-menu .ui-menu-item {
  padding: 15px 20px;
  -webkit-transition: background 0.4s ease;
  -moz-transition: background 0.4s ease;
  -o-transition: background 0.4s ease;
  transition: background 0.4s ease;
}
.ui-menu .ui-menu-item.ui-state-focus {
  border: none;
  background: #f5f5f5;
  margin: 0;
}
/*@import "common/slider.less";*/
.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 355px;
}
.carousel-inner > .item {
  position: relative;
  display: none;
  /* -webkit-transition: 0.6s ease-in-out left;
          transition: 0.6s ease-in-out left; */
  -webkit-transition: 0.6s ease-in-out left;
  -moz-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  height: auto;
  max-width: 100%;
  line-height: 1;
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}
.carousel-inner > .active {
  left: 0;
}
.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel-inner > .next {
  left: 100%;
}
.carousel-inner > .prev {
  left: -100%;
}
.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}
.carousel-inner > .active.left {
  left: -100%;
}
.carousel-inner > .active.right {
  left: 100%;
}
.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: white;
  text-align: center;
  /* text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  opacity: 0.5;
  filter: alpha(opacity=50); */
}
.carousel-control.right {
  right: 0;
  left: auto;
}
.carousel-control:hover,
.carousel-control:focus {
  color: white;
  text-decoration: none;
}
.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
}
.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  font-family: serif;
}
.carousel-control .icon-prev:before {
  content: '\2039';
}
.carousel-control .icon-next:before {
  content: '\203a';
}
.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}
.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 10px;
}
.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: white;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.carousel-caption .btn {
  text-shadow: none;
}
@media screen and (min-width: 768px) {
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    font-size: 30px;
  }
  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
  }
  .carousel-indicators {
    bottom: 20px;
  }
}
.carousel-fade .carousel-inner .item {
  transition-property: opacity;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-control {
  z-index: 2;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/* my styles */
.carousel-inner .item {
  margin-top: 173px;
  line-height: 25px;
  color: #565656;
  font-size: 22px;
  font-family: 'conqueror_sans_medium';
  text-align: center;
}
.carousel-inner .item .slide-text {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0 30px;
  width: 450px;
  height: 75px;
}
.carousel-main-baby {
  width: 424px;
  height: 424px;
  background-color: white;
  border: 2px solid #fe86a4;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}
.carousel-control {
  top: 50%;
  margin-top: -15px;
  width: 18px;
  height: 30px;
}
.carousel-control.left {
  left: 24px;
}
.carousel-control.left span {
  opacity: .9;
  display: inline-block;
  width: 18px;
  height: 30px;
  background: url('../images/c-icon-left.png') 0 0 no-repeat;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}
.carousel-control.left:hover span {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.carousel-control.right {
  right: 24px;
}
.carousel-control.right span {
  opacity: .9;
  display: inline-block;
  width: 18px;
  height: 30px;
  background: url('../images/c-icon-right.png') 0 0 no-repeat;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}
.carousel-control.right:hover span {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
#slidetext,
#slidetext2 {
  position: absolute;
  top: 47px;
  left: 50%;
  margin-left: -30px;
  color: #fe86a4;
  font-size: 25px;
  font-family: 'conqueror_sans_medium';
  font-weight: normal;
}
#slidetext b,
#slidetext2 b {
  font-size: 54px;
  font-family: 'conqueror_sans_medium';
  font-weight: normal;
}
#slidetext i,
#slidetext2 i {
  position: relative;
  top: 22px;
  display: inline-block;
  width: 16px;
  height: 73px;
  background: url('../images/carousel-icon-count.png') 0 0 no-repeat;
}
#slidetext2 {
  color: #f28f2c;
}
#slidetext2 i {
  background: url('../images/carousel-icon-count-2.png') 0 0 no-repeat;
}
#carousel-main-2.carousel-main-baby {
  border: 2px solid #f28f2c;
}
#carousel-main-2 .carousel-control.left span {
  background: url('../images/c-icon-left-2.png') 0 0 no-repeat;
}
#carousel-main-2 .carousel-control.right span {
  background: url('../images/c-icon-right-2.png') 0 0 no-repeat;
}
#carousel-main-3 {
  min-width: 1000px;
  /*@slider-height:890px;*/
  height: 870px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
#carousel-main-3 .controls {
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 100%;
}
#carousel-main-3 .carousel-inner .item {
  width: 100%;
  height: 870px;
  padding: 58px 0 0;
  margin: 0;
  text-align: left;
  background-repeat: no-repeat;
  background-position: 50%;
}
@media (max-width: 1600px) {
  #carousel-main-3 .carousel-inner .item {
    padding: 38px 0 0;
  }
}
#carousel-main-3 .carousel-inner .item .foreground {
  /*margin:0 150px 115px;*/
  max-width: 1524px;
  margin: 0 auto 115px;
  padding-left: 150px;
  padding-right: 150px;
  color: #565656;
  font-size: 29px;
  font-family: 'conqueror_sans_medium', Arial, sans-serif;
  font-weight: normal;
}
@media (max-width: 1600px) {
  #carousel-main-3 .carousel-inner .item .foreground {
    padding-left: 100px;
    padding-right: 100px;
  }
}
#carousel-main-3 .carousel-inner .item .foreground h2,
#carousel-main-3 .carousel-inner .item .foreground h3 {
  line-height: 1em;
  font-size: 40px;
  /*
					line-height:1.1em;
					font-size:46px;
					*/
  color: #fe86a4;
  margin: .5em 0 .2em 0;
  font-family: 'conqueror_sans_extrabold', Arial, sans-serif;
  font-weight: normal;
}
#carousel-main-3 .carousel-inner .item .foreground .lead {
  font-size: 40px;
  color: #c61d71;
  font-family: 'conqueror_sans_bold', Arial, sans-serif;
  font-weight: normal;
}
@media (max-width: 1199px) {
  #carousel-main-3 .carousel-inner .item .foreground {
    font-size: 20px;
  }
  #carousel-main-3 .carousel-inner .item .foreground h2,
  #carousel-main-3 .carousel-inner .item .foreground h3 {
    font-size: 36px;
  }
  #carousel-main-3 .carousel-inner .item .foreground .lead {
    font-size: 36px;
  }
  #carousel-main-3 .carousel-inner .item .foreground img {
    max-width: 250px;
  }
}
#carousel-main-3 .carousel-control {
  position: absolute;
  top: 318px;
  width: 44px;
  height: 44px;
  background: white;
  border: 2px solid #fe86a4;
  cursor: pointer;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  border-radius: 22px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
#carousel-main-3 .carousel-control:after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 12px;
  background-image: url('../images/slider-arrows.png');
  background-repeat: no-repeat;
  position: absolute;
  top: 14px;
}
#carousel-main-3 .carousel-control:hover {
  /*opacity:.5;*/
  background-color: #fe86a4;
}
#carousel-main-3 .carousel-control.left {
  left: 40px;
}
#carousel-main-3 .carousel-control.left:after {
  left: 16px;
  background-position: 0 -1px;
}
#carousel-main-3 .carousel-control.left:hover:after {
  background-position: 0 -15px;
}
#carousel-main-3 .carousel-control.right {
  right: 40px;
}
#carousel-main-3 .carousel-control.right:after {
  right: 16px;
  background-position: 0 -29px;
}
#carousel-main-3 .carousel-control.right:hover:after {
  background-position: 0 -43px;
}
#carousel-main-3:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 227px;
  background-image: url('../images/slider-cloud.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
}
@media (min-width: 1540px) {
  #carousel-main-3 .controls {
    width: 1520px;
  }
}
@media (min-width: 1240px) {
  #carousel-main-3 .carousel-inner .item {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  #carousel-main-3:after {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
/*
@media ( min-width: 1524px ) {
	#carousel-main-3 {
		&:after {
			-webkit-background-size: auto;
			-moz-background-size: auto;
			-o-background-size: auto;
			background-size: auto;
			background-image:url('../images/slider-cloud2.png');
		}
	}
}*/
.form-field {
  border: 2px solid #bcbcbc;
  background: white;
  padding: 20px 20px;
  outline: none;
  width: 100%;
  color: #fe86a4;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.form-field:focus {
  border-color: #fe86a4;
}
.form-field.has-error {
  color: red !important;
  border-color: red !important;
}
.form-row {
  margin-bottom: 15px;
}
.icon-close {
  width: 44px;
  height: 44px;
}
.icon-close:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fe86a4;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.icon-close:after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  top: 50%;
  left: 50%;
  margin-left: -7px;
  margin-top: -7px;
  background-image: url(../images/icon-x.png);
  background-repeat: no-repeat;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.icon-close-forte:before {
  background-color: #f28f2c;
}
.icon-close-hover:before {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.icon-close-hover:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.wrapper {
  -webkit-transition: all 0.6s ease-in;
  -moz-transition: all 0.6s ease-in;
  -o-transition: all 0.6s ease-in;
  transition: all 0.6s ease-in;
}
.wrapper.popup-overlay-blur {
  -webkit-filter: blur(15px);
  -moz-filter: blur(15px);
  -ms-filter: blur(15px);
  -o-filter: blur(15px);
}
.fancybox-wrap .fancybox-skin,
.q-item .fancybox-skin,
.prob-benefits .fancybox-skin,
.fancybox-wrap .popup,
.q-item .popup,
.prob-benefits .popup {
  background-color: white;
  color: #565656;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 15px 30px rgba(156, 66, 106, 0.3);
  -moz-box-shadow: 0 15px 30px rgba(156, 66, 106, 0.3);
  box-shadow: 0 15px 30px rgba(156, 66, 106, 0.3);
}
.fancybox-wrap .fancybox-skin .popup-close,
.q-item .fancybox-skin .popup-close,
.prob-benefits .fancybox-skin .popup-close,
.fancybox-wrap .popup .popup-close,
.q-item .popup .popup-close,
.prob-benefits .popup .popup-close,
.fancybox-wrap .fancybox-skin .fancybox-close,
.q-item .fancybox-skin .fancybox-close,
.prob-benefits .fancybox-skin .fancybox-close,
.fancybox-wrap .popup .fancybox-close,
.q-item .popup .fancybox-close,
.prob-benefits .popup .fancybox-close {
  top: -22px;
  right: -22px;
  background: none;
  width: 44px;
  height: 44px;
}
.fancybox-wrap .fancybox-skin .popup-close:before,
.q-item .fancybox-skin .popup-close:before,
.prob-benefits .fancybox-skin .popup-close:before,
.fancybox-wrap .popup .popup-close:before,
.q-item .popup .popup-close:before,
.prob-benefits .popup .popup-close:before,
.fancybox-wrap .fancybox-skin .fancybox-close:before,
.q-item .fancybox-skin .fancybox-close:before,
.prob-benefits .fancybox-skin .fancybox-close:before,
.fancybox-wrap .popup .fancybox-close:before,
.q-item .popup .fancybox-close:before,
.prob-benefits .popup .fancybox-close:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fe86a4;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.fancybox-wrap .fancybox-skin .popup-close:after,
.q-item .fancybox-skin .popup-close:after,
.prob-benefits .fancybox-skin .popup-close:after,
.fancybox-wrap .popup .popup-close:after,
.q-item .popup .popup-close:after,
.prob-benefits .popup .popup-close:after,
.fancybox-wrap .fancybox-skin .fancybox-close:after,
.q-item .fancybox-skin .fancybox-close:after,
.prob-benefits .fancybox-skin .fancybox-close:after,
.fancybox-wrap .popup .fancybox-close:after,
.q-item .popup .fancybox-close:after,
.prob-benefits .popup .fancybox-close:after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  top: 50%;
  left: 50%;
  margin-left: -7px;
  margin-top: -7px;
  background-image: url(../images/icon-x.png);
  background-repeat: no-repeat;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.fancybox-wrap .fancybox-skin .popup-close:hover:before,
.q-item .fancybox-skin .popup-close:hover:before,
.prob-benefits .fancybox-skin .popup-close:hover:before,
.fancybox-wrap .popup .popup-close:hover:before,
.q-item .popup .popup-close:hover:before,
.prob-benefits .popup .popup-close:hover:before,
.fancybox-wrap .fancybox-skin .fancybox-close:hover:before,
.q-item .fancybox-skin .fancybox-close:hover:before,
.prob-benefits .fancybox-skin .fancybox-close:hover:before,
.fancybox-wrap .popup .fancybox-close:hover:before,
.q-item .popup .fancybox-close:hover:before,
.prob-benefits .popup .fancybox-close:hover:before {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.fancybox-wrap .fancybox-skin .popup-close:hover:after,
.q-item .fancybox-skin .popup-close:hover:after,
.prob-benefits .fancybox-skin .popup-close:hover:after,
.fancybox-wrap .popup .popup-close:hover:after,
.q-item .popup .popup-close:hover:after,
.prob-benefits .popup .popup-close:hover:after,
.fancybox-wrap .fancybox-skin .fancybox-close:hover:after,
.q-item .fancybox-skin .fancybox-close:hover:after,
.prob-benefits .fancybox-skin .fancybox-close:hover:after,
.fancybox-wrap .popup .fancybox-close:hover:after,
.q-item .popup .fancybox-close:hover:after,
.prob-benefits .popup .fancybox-close:hover:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fancybox-wrap .fancybox-skin h3,
.q-item .fancybox-skin h3,
.prob-benefits .fancybox-skin h3,
.fancybox-wrap .popup h3,
.q-item .popup h3,
.prob-benefits .popup h3 {
  font-size: 20px;
  color: #fe86a4;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.4;
  font-family: 'conqueror_sans_semibold', Arial, sans-serif;
  font-weight: normal;
}
.fancybox-wrap .fancybox-skin b,
.q-item .fancybox-skin b,
.prob-benefits .fancybox-skin b,
.fancybox-wrap .popup b,
.q-item .popup b,
.prob-benefits .popup b {
  font-family: 'conqueror_sans_semibold', Arial, sans-serif;
  font-weight: normal;
}
.fancybox-wrap .fancybox-skin small,
.q-item .fancybox-skin small,
.prob-benefits .fancybox-skin small,
.fancybox-wrap .popup small,
.q-item .popup small,
.prob-benefits .popup small {
  display: inline-block;
  font-size: 12px;
  line-height: 1.2;
}
.fancybox-wrap .fancybox-skin ul,
.q-item .fancybox-skin ul,
.prob-benefits .fancybox-skin ul,
.fancybox-wrap .popup ul,
.q-item .popup ul,
.prob-benefits .popup ul {
  padding: 0;
  margin: 0;
}
.fancybox-wrap .fancybox-skin ul li,
.q-item .fancybox-skin ul li,
.prob-benefits .fancybox-skin ul li,
.fancybox-wrap .popup ul li,
.q-item .popup ul li,
.prob-benefits .popup ul li {
  position: relative;
  padding: 0;
  margin: 0;
  padding-bottom: 30px;
}
.fancybox-wrap .fancybox-skin ul li:before,
.q-item .fancybox-skin ul li:before,
.prob-benefits .fancybox-skin ul li:before,
.fancybox-wrap .popup ul li:before,
.q-item .popup ul li:before,
.prob-benefits .popup ul li:before {
  content: "•";
  position: absolute;
  top: 1px;
  left: -20px;
  font-size: 32px;
  color: #fe86a4;
  font-family: 'conqueror_sans_semibold', Arial, sans-serif;
  font-weight: normal;
}
.fancybox-wrap .fancybox-skin ul li:laft-of-type,
.q-item .fancybox-skin ul li:laft-of-type,
.prob-benefits .fancybox-skin ul li:laft-of-type,
.fancybox-wrap .popup ul li:laft-of-type,
.q-item .popup ul li:laft-of-type,
.prob-benefits .popup ul li:laft-of-type {
  padding-bottom: 0;
}
.fancybox-wrap .fancybox-skin .forte h3,
.q-item .fancybox-skin .forte h3,
.prob-benefits .fancybox-skin .forte h3,
.fancybox-wrap .popup .forte h3,
.q-item .popup .forte h3,
.prob-benefits .popup .forte h3,
.fancybox-wrap .fancybox-skin .forte a,
.q-item .fancybox-skin .forte a,
.prob-benefits .fancybox-skin .forte a,
.fancybox-wrap .popup .forte a,
.q-item .popup .forte a,
.prob-benefits .popup .forte a,
.fancybox-wrap .fancybox-skin .forte ul li:before,
.q-item .fancybox-skin .forte ul li:before,
.prob-benefits .fancybox-skin .forte ul li:before,
.fancybox-wrap .popup .forte ul li:before,
.q-item .popup .forte ul li:before,
.prob-benefits .popup .forte ul li:before {
  color: #f28f2c;
}
.fancybox-wrap .fancybox-skin .tab-icon,
.q-item .fancybox-skin .tab-icon,
.prob-benefits .fancybox-skin .tab-icon,
.fancybox-wrap .popup .tab-icon,
.q-item .popup .tab-icon,
.prob-benefits .popup .tab-icon {
  display: inline-block;
  margin: 10px;
}
.fancybox-wrap.forte .popup-close:before,
.q-item.forte .popup-close:before,
.prob-benefits.forte .popup-close:before,
.fancybox-wrap.forte .fancybox-close:before,
.q-item.forte .fancybox-close:before,
.prob-benefits.forte .fancybox-close:before {
  background-color: #f28f2c;
}
.fancybox-wrap.popup-small .popup-close,
.q-item.popup-small .popup-close,
.prob-benefits.popup-small .popup-close,
.fancybox-wrap.popup-small .fancybox-close,
.q-item.popup-small .fancybox-close,
.prob-benefits.popup-small .fancybox-close {
  top: auto;
  left: 50%;
  bottom: 0;
  margin: 0 0 -22px -22px;
}
.fancybox-wrap.popup-small .popup-content,
.q-item.popup-small .popup-content,
.prob-benefits.popup-small .popup-content {
  width: 370px;
  padding: 30px 0;
  font-size: 18px;
  text-align: center;
  line-height: 1.4;
  font-family: 'conqueror_sans_light', Arial, sans-serif;
  font-weight: normal;
}
.fancybox-wrap.popup-medium,
.q-item.popup-medium,
.prob-benefits.popup-medium,
.fancybox-wrap.popup-medium p,
.q-item.popup-medium p,
.prob-benefits.popup-medium p {
  line-height: 1.5;
}
.fancybox-wrap.popup-medium h3,
.q-item.popup-medium h3,
.prob-benefits.popup-medium h3 {
  font-size: 22px;
  text-transform: none;
  margin-bottom: 30px;
}
.fancybox-wrap.popup-medium h3.big,
.q-item.popup-medium h3.big,
.prob-benefits.popup-medium h3.big {
  font-size: 28px;
  margin-top: 10px;
}
.fancybox-wrap.popup-medium .popup-content,
.q-item.popup-medium .popup-content,
.prob-benefits.popup-medium .popup-content {
  width: 730px;
  padding: 0 40px;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: .5px;
  font-family: 'conqueror_sans_light', Arial, sans-serif;
  font-weight: normal;
}
.fancybox-wrap.popup-form .fancybox-inner,
.q-item.popup-form .fancybox-inner,
.prob-benefits.popup-form .fancybox-inner {
  overflow: visible !important;
}
.fancybox-wrap.popup-form .popup-content,
.q-item.popup-form .popup-content,
.prob-benefits.popup-form .popup-content {
  width: 660px;
  margin: 0 130px;
  font-family: 'conqueror_sans_light', Arial, sans-serif;
  font-weight: normal;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fancybox-wrap.popup-text .fancybox-inner,
.q-item.popup-text .fancybox-inner,
.prob-benefits.popup-text .fancybox-inner {
  overflow-x: hidden !important;
}
.fancybox-wrap.popup-text .popup-content,
.q-item.popup-text .popup-content,
.prob-benefits.popup-text .popup-content {
  width: 1000px;
  font-size: 18px;
  text-align: left;
  line-height: 1.4;
  font-family: 'conqueror_sans_light', Arial, sans-serif;
  font-weight: normal;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fancybox-wrap.popup-text .popup-content h3,
.q-item.popup-text .popup-content h3,
.prob-benefits.popup-text .popup-content h3 {
  font-size: 23px;
  text-transform: none;
  text-align: left;
}
.fancybox-wrap.popup-text .popup-content .popup-header img,
.q-item.popup-text .popup-content .popup-header img,
.prob-benefits.popup-text .popup-content .popup-header img {
  width: 100%;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.fancybox-wrap.popup-text .popup-content .popup-scroll,
.q-item.popup-text .popup-content .popup-scroll,
.prob-benefits.popup-text .popup-content .popup-scroll {
  margin: 40px 115px;
  oveflow: auto;
}
.fancybox-wrap.popup-question-success .popup-content,
.q-item.popup-question-success .popup-content,
.prob-benefits.popup-question-success .popup-content {
  width: 920px;
  margin: 172px 0;
  font-size: 30px;
  text-align: center;
  line-height: 1.4;
  color: #fe86a4;
  text-transform: uppercase;
  font-family: 'conqueror_sans_semibold', Arial, sans-serif;
  font-weight: normal;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
label.error {
  margin-top: -20px;
  font-family: 'conqueror_sans_light', Arial, sans-serif;
  font-weight: normal;
  color: red;
  padding-right: 50px;
  position: absolute;
  display: block !important;
  width: 100%;
  text-align: right;
}
textarea.form-field {
  margin-bottom: -5px;
}
.fancybox-overlay {
  position: fixed;
  height: 100% !important;
  background: rgba(121, 67, 90, 0.7);
  -webkit-box-shadow: inset 0 20px 40px #79435a;
  -moz-box-shadow: inset 0 20px 40px #79435a;
  box-shadow: inset 0 20px 40px #79435a;
}
.faq-list {
  position: relative;
  margin-top: -20px;
  margin-bottom: 160px;
  font-size: 16px;
  background-color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 30px rgba(156, 66, 106, 0.3);
  -moz-box-shadow: 0 10px 30px rgba(156, 66, 106, 0.3);
  box-shadow: 0 10px 30px rgba(156, 66, 106, 0.3);
}
.faq-list .list-item {
  padding: 55px 115px 35px 115px;
  -webkit-box-shadow: 0 15px 30px rgba(156, 66, 106, 0.05);
  -moz-box-shadow: 0 15px 30px rgba(156, 66, 106, 0.05);
  box-shadow: 0 15px 30px rgba(156, 66, 106, 0.05);
}
.faq-list .list-item .title {
  font-family: 'conqueror_sans_semibold', Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
  margin-bottom: 30px;
}
.faq-list .list-item .description,
.faq-list .list-item .description p {
  line-height: 1.5;
}
.faq-list .paginator {
  bottom: -70px;
  width: 100%;
}
.faq-new {
  margin-bottom: -70px;
}
.faq-new .article-wrap.container {
  position: relative;
}
.faq-new .article-wrap.container .paginator {
  width: 96%;
}
.faq-block-1 {
  height: 500px;
  background-image: url(../images/bg-faq-b1.jpg);
  background-repeat: no-repeat;
  background-position: 100% 100%;
}
.faq-block-1 h1 {
  font-size: 70px;
  line-height: 70px;
  margin-top: 140px;
  color: #fe86a4;
  font-family: 'conqueror_sans_extrabold', Arial, sans-serif;
  font-weight: normal;
}
.faq-block-1 .article-container {
  max-width: 1500px;
  margin: 0 auto;
}
@media (max-width: 1600px) {
  .faq-block-1 {
    height: 500px;
    background-size: cover;
  }
}
@media (max-width: 1400px) {
  .faq-block-1 {
    height: 450px;
  }
}
@media (max-width: 1150px) {
  .faq-block-1 {
    height: 400px;
  }
}
@media (max-width: 1100px) {
  .faq-block-1 {
    height: 350px;
  }
}
.faq-block-2 {
  background-image: url(../images/bg-faq-b2.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  padding-bottom: 40px;
}
.faq-block-2 .btn-big.middle {
  font-size: 14px;
  padding: 0 20px;
}
.faq-block-2 .q-item {
  position: relative;
  width: 430px;
  margin: 0 auto;
  padding: 45px 20px 25px 20px;
  text-align: center;
}
.faq-block-2 .q-item .icon,
.faq-block-2 .q-item .title {
  padding-bottom: 20px;
}
.faq-block-2 .q-item .title {
  font-size: 24px;
  color: #565656;
  line-height: 1.3;
  min-height: 175px;
  font-family: 'conqueror_sans_semibold', Arial, sans-serif;
  font-weight: normal;
}
.faq-block-2 .q-item .popup {
  position: absolute;
  width: 100%;
  top: -30px;
  left: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.faq-block-2 .q-item .popup.active {
  top: 45px;
  visibility: visible;
  opacity: 1;
}
.faq-block-2 .q-item .popup .popup-content {
  width: auto;
  padding: 70px 30px;
  font-size: 18px;
}
.faq-block-2 .q-item .popup .popup-close {
  position: absolute;
  cursor: pointer;
}
.faq-block-3 {
  background-color: #fe86a4;
  background-image: url(../images/bg-faq-b3.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  padding: 55px 0;
  text-align: center;
  color: white;
  font-size: 20px;
}
.faq-block-3 h3 {
  font-size: 46px;
  margin-bottom: 45px;
  font-family: 'conqueror_sans_extrabold', Arial, sans-serif;
  font-weight: normal;
}
@media (max-width: 1600px) {
  .faq-block-3 h3 {
    font-size: 44px;
    margin-bottom: 40px;
  }
}
@media (max-width: 1400px) {
  .faq-block-3 h3 {
    font-size: 40px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1150px) {
  .faq-block-3 h3 {
    font-size: 35px;
    margin-bottom: 25px;
  }
}
@media (max-width: 1100px) {
  .faq-block-3 h3 {
    font-size: 32px;
    margin-bottom: 20px;
  }
}
.faq-block-3 p {
  margin: 45px 0 0 0;
}
@media (max-width: 1600px) {
  .faq-block-3 p {
    margin-top: 44px;
  }
}
@media (max-width: 1400px) {
  .faq-block-3 p {
    margin-top: 40px;
  }
}
@media (max-width: 1150px) {
  .faq-block-3 p {
    margin-top: 35px;
  }
}
@media (max-width: 1100px) {
  .faq-block-3 p {
    margin-top: 32px;
  }
}
.faq-block-3 .btn-big {
  border-width: 0;
  line-height: 62px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.faq-block-3 .btn-big:hover {
  color: #fe86a4;
  background-color: white;
  opacity: .7;
}
.faq-block-4 {
  background-color: white;
  background-image: url(../images/bg-faq-b4.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  margin-bottom: -70px;
  padding: 85px 0 55px 0;
}
.faq-block-4 h3 {
  font-size: 46px;
  margin-bottom: 45px;
  color: #fe86a4;
  text-align: center;
  font-family: 'conqueror_sans_extrabold', Arial, sans-serif;
  font-weight: normal;
}
@media (max-width: 1200px) {
  html:not(.mobile):not(.tablet) .faq-slider .carousel-control.left {
    left: 0;
  }
  html:not(.mobile):not(.tablet) .faq-slider .carousel-control.right {
    right: 0;
  }
}
.faq-slider {
  margin-bottom: 40px;
}
.faq-slider .item {
  margin: 0;
}
.faq-slider .item:after {
  display: block;
  clear: both;
}
.faq-slider .carousel-control {
  margin-top: -25px;
}
.faq-slider .carousel-control .sr-only {
  text-indent: -9999px;
}
.faq-slider .carousel-control .left {
  left: 30px;
}
.faq-slider .carousel-control .right {
  right: 30px;
}
.faq-slider .item-item {
  float: left;
  width: 50%;
  text-align: left;
  font-family: 'conqueror_sans_light', Arial, sans-serif;
  font-weight: normal;
}
.faq-slider .item-item .title {
  font-size: 23px;
  margin-top: 20px;
  font-family: 'conqueror_sans_bold', Arial, sans-serif;
  font-weight: normal;
}
.faq-slider .item-item .title a {
  color: #565656;
  text-decoration: none;
}
.faq-slider .item-item p {
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.4;
}
.faq-slider .item-item .item-content {
  width: 423px;
  margin: 0 auto;
}
.article-wrap {
  /*width:1020px;
	margin:0 auto;*/
}
.breadcrumbs {
  padding: 35px 0;
}
.breadcrumbs li {
  font-size: 14px;
  color: #9e9e9e;
  display: inline-block;
  background-image: url('../images/arrow-bc.png');
  background-repeat: no-repeat;
  background-position: 0 5px;
  padding-left: 10px;
  padding-right: 5px;
}
.breadcrumbs li:first-child {
  background-image: none;
  padding-left: 0;
}
.article-container {
  padding-left: 20px;
  padding-right: 20px;
  color: #565656;
  /*width:1000px;*/
}
.article-container a {
  color: #fe86a4;
  text-decoration: none;
}
.article-container a:hover {
  text-decoration: underline;
}
.article-container h1 {
  width: 560px;
  font-size: 70px;
  line-height: 70px;
  margin-top: 35px;
  color: #fe86a4;
  font-family: 'conqueror_sans_extrabold', Arial, sans-serif;
  font-weight: normal;
  max-width: 590px;
}
@media (max-width: 1199px) {
  .article-container h1 {
    font-size: 42px;
    line-height: 1.2;
  }
}
.article-container article {
  padding: 50px 110px 35px 110px;
  font-size: 16px;
  background-color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 30px rgba(156, 66, 106, 0.3);
  -moz-box-shadow: 0 10px 30px rgba(156, 66, 106, 0.3);
  box-shadow: 0 10px 30px rgba(156, 66, 106, 0.3);
}
.article-container article img {
  margin: 1.6em 0;
}
.article-container article img.wide {
  width: calc(100% + 220px);
  margin-left: -110px;
  margin-right: -110px;
}
.article-container article p {
  line-height: 1.6;
  margin: 1.6em 0;
}
.article-container h3 {
  font-size: 24px;
  color: #fe86a4;
  font-family: 'conqueror_sans_semibold', Arial, sans-serif;
  font-weight: normal;
}
.article-container .bottom-nav {
  font-size: 14px;
  padding-top: 20px;
  font-family: 'conqueror_sans_medium', Arial, sans-serif;
  font-weight: normal;
}
.article-container .bottom-nav .left {
  float: left;
}
.article-container .bottom-nav .right {
  float: right;
}
.article-container .bottom-nav:after {
  content: "";
  clear: both;
  display: block;
}
.article-block-1 {
  background-image: url(../images/article-header.jpg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  min-height: 538px;
}
@media (max-width: 1366px) {
  .article-block-1 {
    min-height: 440px;
  }
}
.article-block-1 h1 {
  font-size: 42px;
  line-height: 1.2;
}
.article-block-1 .breadcrumbs li:last-child {
  display: none;
}
.article-block-2 {
  background-image: url(../images/bg-article.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  padding-top: 157px;
  margin-top: -213px;
  padding-bottom: 50px;
}
@media (max-width: 1366px) {
  html:not(.mobile):not(.tablet) .article-block-2 .article-wrap {
    margin-top: -80px;
  }
}
.link-down {
  padding-top: 20px;
}
.page-new b.baby {
  font-family: 'conqueror_sans_medium', Arial, sans-serif;
  font-weight: normal;
  color: #fe86a4;
}
.page-new .block-ld {
  padding: 140px 20px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.page-new .block-ld .img {
  text-align: center;
}
.page-new .block-ld-small {
  padding-top: 50px;
}
.page-new .block-ld.prob-1 {
  background-position: 50% 100%;
  background-image: url(../images/bg-prob-1.jpg);
  padding-bottom: 220px;
}
.page-new .block-ld.prob-2 {
  background-position: 0 100%;
  background-size: initial;
  background-image: url(../images/bg-prob-2.jpg);
  margin-top: -150px;
}
.page-new .block-ld.prob-2 .img {
  margin-top: -70px;
}
.page-new .block-ld.prob-3 {
  background-image: url(../images/bg-prob-3.jpg);
  padding-top: 180px;
  padding-bottom: 160px;
}
.page-new .block-ld.prob-3 .img {
  margin-top: -70px;
}
.page-new .block-ld.prob-3 .img .count {
  width: 296px;
  height: 296px;
  background-image: url(../images/prob-3-img.png);
  background-position: 50%;
  background-repeat: no-repeat;
  text-transform: uppercase;
  color: white;
  font-size: 18px;
  line-height: 1.2;
  display: inline-block;
}
.page-new .block-ld.prob-3 .img .count .cell {
  display: table-cell;
  width: 296px;
  height: 296px;
  vertical-align: middle;
}
.page-new .block-ld.prob-3 .img .count b {
  font-size: 60px;
  font-family: 'conqueror_sans_bold', Arial, sans-serif;
  font-weight: normal;
}
.page-new .block-ld.prob-4 {
  background-image: url(../images/bg-prob-4.jpg);
  padding-top: 180px;
  padding-bottom: 90px;
}
.page-new,
.page-new p {
  font-family: 'conqueror_sans_light', Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #565656;
  line-height: 1.5;
}
.page-new.lead,
.page-new p.lead {
  font-size: 18px;
}
.page-new h2 {
  font-family: 'conqueror_sans_extrabold', Arial, sans-serif;
  font-weight: normal;
  font-size: 70px;
  color: #fe86a4;
  line-height: 1;
  margin-bottom: .8em;
}
.page-new h3 {
  font-family: 'conqueror_sans_bold', Arial, sans-serif;
  font-weight: normal;
  font-size: 40px;
  color: #fe86a4;
  line-height: 1;
  margin-bottom: 1em;
}
.page-new h4 {
  font-family: 'conqueror_sans_bold', Arial, sans-serif;
  font-weight: normal;
  font-size: 23px;
  line-height: 1;
  margin-bottom: 1em;
}
.prob-benefits {
  padding: 70px 85px 0 85px;
  position: relative;
}
.prob-benefits .item {
  padding-bottom: 70px;
  display: block;
  text-align: center;
}
.prob-benefits .item .icon {
  position: relative;
  width: 141px;
  height: 140px;
  margin: 0 auto;
  background-image: url(../images/prob-benefit-bg.png);
  background-repeat: no-repeat;
}
.prob-benefits .item .icon:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: 50%;
  background-repeat: no-repeat;
}
.prob-benefits .item .icon.icon-1:before {
  background-image: url(../images/prob-benefit-1.png);
}
.prob-benefits .item .icon.icon-2:before {
  background-image: url(../images/prob-benefit-2.png);
}
.prob-benefits .item .icon.icon-3:before {
  background-image: url(../images/prob-benefit-3.png);
}
.prob-benefits .item .icon.icon-4:before {
  background-image: url(../images/prob-benefit-4.png);
}
.prob-benefits .item .icon.icon-5:before {
  background-image: url(../images/prob-benefit-5.png);
}
.prob-benefits .item .icon.icon-6:before {
  background-image: url(../images/prob-benefit-6.png);
}
.prob-benefits .item .icon .btn-prob {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-left: -15px;
  margin-bottom: -9px;
  cursor: pointer;
}
.prob-benefits .item .icon .btn-prob:before,
.prob-benefits .item .icon .btn-prob:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.prob-benefits .item .icon .btn-prob:before {
  background-color: white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 5px rgba(254, 134, 164, 0.2);
  -moz-box-shadow: 0 2px 5px rgba(254, 134, 164, 0.2);
  box-shadow: 0 2px 5px rgba(254, 134, 164, 0.2);
}
.prob-benefits .item .icon .btn-prob.open:after {
  background-image: url(../images/prob-plus.png);
}
.prob-benefits .item .icon .btn-prob.close:after {
  background-image: url(../images/prob-close.png);
}
.prob-benefits .item .icon .btn-prob:hover:before {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -o-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}
.prob-benefits .item .icon .btn-prob:hover:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.prob-benefits .item .title {
  margin-top: 20px;
  font-family: 'conqueror_sans_semibold', Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
  color: #fe86a4;
}
.prob-benefits .popup {
  position: absolute;
  width: 100%;
  top: -30px;
  left: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.prob-benefits .popup.active {
  top: 45px;
  visibility: visible;
  opacity: 1;
}
.prob-benefits .popup .popup-content {
  width: auto;
  padding: 0 120px 40px 120px;
}
.prob-benefits .popup .popup-content,
.prob-benefits .popup .popup-content p {
  font-size: 18px;
}
.prob-benefits .popup .item {
  padding-bottom: 35px;
  margin-top: -60px;
}
.paginator {
  text-align: center;
  position: absolute;
  white-space: nowrap;
  width: 44%;
  bottom: 0;
}
.paginator .cls-1 {
  opacity: 1;
}
.paginator > a {
  width: 14px;
}
.paginator .prev,
.paginator .next {
  display: inline-block;
  height: 25px;
  line-height: 25px;
  vertical-align: middle;
}
.paginator .prev svg,
.paginator .next svg {
  vertical-align: top;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.paginator .prev:hover,
.paginator .next:hover {
  text-decoration: none;
}
.paginator .prev:hover svg,
.paginator .next:hover svg {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.paginator ul {
  margin: 0 20px;
  display: inline-block;
}
.paginator ul li {
  display: inline-block;
  padding: 0;
  margin: 0 5px;
  font-family: 'conqueror_sans_medium', Arial, sans-serif;
  font-weight: normal;
}
.paginator ul li a,
.paginator ul li span {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin: 0 -2px;
  color: #565656;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.paginator ul li.active a,
.paginator ul li.active span,
.paginator ul li a:hover,
.paginator ul li span:hover {
  color: white;
  background-color: #fe86a4;
  text-decoration: none;
}
.spec-block-1 {
  min-height: 545px;
  background-image: url(../images/bg-spec-1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-left: 20px;
  padding-right: 20px;
}
.spec-block-1 h1 {
  width: 590px;
  font-size: 70px;
  line-height: 70px;
  margin-top: 140px;
  color: #fe86a4;
  font-family: 'conqueror_sans_extrabold', Arial, sans-serif;
  font-weight: normal;
}
.spec-block-2 {
  color: #565656;
  margin-bottom: -70px;
  padding-left: 20px;
  padding-right: 20px;
}
.spec-block-2 h2 {
  font-family: 'conqueror_sans_extrabold', Arial, sans-serif;
  font-weight: normal;
  font-size: 40px;
}
.spec-block-2 .container {
  position: relative;
  padding-bottom: 70px;
}
.spec-block-2 .container .col-xs-6 {
  position: static;
}
.spec-list .list-item {
  padding-right: 70px;
  padding-bottom: 45px;
}
.spec-list .list-item .title {
  font-family: 'conqueror_sans_bold', Arial, sans-serif;
  font-weight: normal;
  font-size: 23px;
  margin-bottom: 25px;
}
.spec-list .list-item .title a {
  color: #fe86a4;
  text-decoration: none;
}
.spec-list .list-item .title a:hover {
  text-decoration: underline;
}
.spec-list .list-item .description {
  vertical-align: middle;
}
.spec-list .icon-date,
.spec-list .icon-place {
  margin-right: 15px;
}
.spec-list .icon-date:before,
.spec-list .icon-place:before {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-right: 8px;
}
.spec-list .icon-date:before {
  width: 13px;
  height: 14px;
  background-image: url(../images/icon-date.png);
  margin-top: -4px;
}
.spec-list .icon-place:before {
  width: 15px;
  height: 19px;
  background-image: url(../images/icon-place.png);
}
.how-work .more-knew {
  bottom: 80px;
}
@media (max-width: 1400px) {
  #carousel-main-3 .carousel-inner .item {
    height: 65vw;
  }
  #carousel-main-3 {
    height: 60vw;
  }
  .bg-block-4 {
    background-position: right;
  }
  .bg-block-6 {
    background-position: 30% 0;
  }
  .block-map .map {
    height: 40vw;
  }
  .how-work .description {
    margin-top: 50px;
  }
  .how-work .how-work-image {
    margin-top: 37px;
  }
  .how-work .how-work-image img {
    width: 90%;
  }
  .how-work .description .desc-title {
    font-size: 20px;
  }
  .how-work .description p {
    margin-top: 20px;
    font-size: 16px;
    line-height: 20px;
  }
  .how-work h4 {
    margin-top: 42px;
    font-size: 36px;
  }
  .block-map h3 {
    font-size: 36px;
  }
  .bakset-baby-title,
  .bakset-forte-title {
    margin-top: 48px;
    font-size: 36px;
    line-height: 45px;
  }
  .bakset-baby-title b,
  .how-work h4 span,
  .bakset-forte-title b {
    font-size: 50px;
  }
  .bg-block-7,
  .bg-block-7 .col-md-12 {
    height: 620px;
  }
  .bg-block-5,
  .bg-block-6,
  .bg-block-5 .col-md-12,
  .bg-block-6 .col-md-12 {
    height: 535px;
  }
}
@media (max-width: 1250px) {
  #carousel-main-3 .carousel-inner .item {
    height: 70vw;
  }
  #carousel-main-3 {
    height: 73vw;
  }
  .bg-block-4 {
    background-position: 80% 0;
  }
  .bg-block-6 {
    background-position: 30% 0;
  }
  .bg-block-6 .col-md-12 .carousel-main-baby {
    right: 0;
  }
  .bg-block-4 .col-md-12 .carousel-main-baby {
    left: 0;
  }
}
@media (max-width: 1100px) {
  #carousel-main-3 .carousel-inner .item {
    height: 770px;
  }
  #carousel-main-3 {
    height: 800px;
  }
}
@media (max-width: 1200px) {
  .benefit-icons .icon-item {
    margin: 0 3%;
  }
}
@media (max-width: 1200px) {
  .header nav a {
    padding: 12px 15px 11px;
    font-size: 0.8em;
    line-height: 0.8em;
  }
  .header .container {
    padding: 27px 0 0;
    min-width: 1000px;
  }
  .header nav a.nab-btn {
    padding: 12px 15px 11px;
    font-size: 0.8em;
  }
  .how-work {
    margin: 0 auto 0 auto;
    width: 900px;
  }
  .footer-text {
    width: 990px;
    background-size: contain;
  }
  .spec-block-1 h1,
  .page-new h2 {
    font-size: 42px;
    line-height: 1.2;
  }
}
@media (max-width: 1024px) {
  .block-map .map {
    height: 400px;
  }
}
[src*="tab-icon-bottom"] {
  max-width: 100% !important;
  max-height: 100% !important;
  margin-top: 0 !important;
}
html.tablet body {
  width: 100%;
  min-width: 100%;
  font-size: 1.4rem;
}
html.tablet .header nav a.not-btn:hover,
html.tablet .header nav a.not-btn.active {
  text-decoration: none;
  font-family: inherit;
}
html.tablet .header nav a {
  padding: 1.6rem 1.5rem 1.5rem !important;
  font-size: 1.4rem;
  line-height: 1.4rem;
}
html.tablet .wrapper {
  padding-top: 10rem;
}
html.tablet .header nav a.not-btn {
  width: auto;
}
html.tablet .header .container {
  padding: 2.7rem 0 0;
  min-width: 100%;
  display: block !important;
  height: auto !important;
}
html.tablet .header nav a.nab-btn {
  padding: 1.6rem 1.5rem 1.5rem;
  font-size: 1.8rem;
  border-width: 0.2rem;
}
html.tablet .header nav a {
  border-width: 0.2rem !important;
}
html.tablet .how-work {
  width: 90rem;
  margin: 0 auto;
}
html.tablet .footer-text {
  width: 80%;
  height: 0;
  padding-bottom: 5.2%;
  margin-top: 3rem;
  margin-bottom: 0;
}
html.tablet .page-new h3 {
  font-size: 4rem;
}
html.tablet .spec-block-1 h1,
html.tablet .page-new h2 {
  font-size: 4.2rem;
  line-height: 1.2;
}
html.tablet .container {
  max-width: 100%;
  min-width: 100%;
}
html.tablet .block-map {
  padding: 55px 0 6rem;
}
html.tablet .btn-big {
  padding: 0 6rem;
  height: 6rem;
  line-height: 6.2rem;
  font-size: 2rem;
  border-radius: 3rem;
  border-width: 0.2rem;
}
html.tablet .block-map .map-container {
  height: 67rem;
  margin-bottom: 3rem;
}
html.tablet .block-map h3 {
  font-size: 4.6rem;
}
html.tablet .block-map .map-container .map-filter button[type=submit] {
  padding: 1.5rem 2rem;
  border-radius: 2.5rem;
  width: 19rem;
  height: 5rem;
  vertical-align: top;
}
html.tablet .block-map .map-container .map-filter .map-switch {
  width: 5rem;
  height: 5rem;
  vertical-align: top;
}
html.tablet .block-map .map-container .map-filter .map-switch > .icon-list {
  width: 1.9rem;
  height: 1.2rem;
  margin-left: -1rem;
  margin-top: -0.6rem;
  background-size: contain;
}
html.tablet .block-map .map-container .map-filter .map-switch > .icon-map {
  width: 1.7rem;
  height: 2.4rem;
  margin-left: -0.9rem;
  margin-top: -1.2rem;
  background-size: contain;
}
html.tablet .block-map .map-container .map-filter input[name=city] {
  padding: 1.5rem 2.0rem;
  border-radius: 2.5rem;
  width: 19rem;
  height: 5rem;
}
html.tablet .bg-block-5,
html.tablet .bg-block-6,
html.tablet .bg-block-5 .col-md-12,
html.tablet .bg-block-6 .col-md-12 {
  height: 59rem;
  width: 100%;
}
html.tablet .bakset-baby-title,
html.tablet .bakset-forte-title,
html.tablet .how-work h4 {
  margin-top: 7.2rem;
  line-height: 4.5rem;
  font-size: 4.6rem;
}
html.tablet .how-work .description {
  margin-top: 7rem;
  width: 45rem;
}
html.tablet .how-work .description .desc-title {
  font-size: 2.3rem;
}
html.tablet .how-work .description p {
  margin-top: 2.5rem;
  font-size: 1.8rem;
  line-height: 2.5rem;
}
html.tablet .how-work.block-7 .how-work-image {
  width: 45rem;
}
html.tablet .how-work .more-knew {
  bottom: 2rem;
  font-size: 1.8rem;
}
html.tablet .how-work .more-knew:before {
  bottom: -0.4rem;
  width: 12.2rem;
  height: 1px;
}
html.tablet .carousel-main-baby {
  width: 42rem;
  height: 42rem;
}
html.tablet .carousel-inner .item .slide-text {
  padding: 0 3rem;
  width: 42rem;
  height: 7.5rem;
}
html.tablet .carousel-inner .item {
  margin-top: 17.5rem;
  line-height: 2.5rem;
  font-size: 2.2rem;
}
html.tablet .bakset-baby-title b,
html.tablet .how-work h4 span,
html.tablet .bakset-forte-title b {
  font-size: 6rem;
}
html.tablet .bg-block-6 .container,
html.tablet .bg-block-6 {
  height: 59rem;
}
html.tablet .bg-block-5 .col-md-12 {
  height: auto;
}
html.tablet .bg-block-5,
html.tablet .bg-block-5 .container {
  height: auto;
  display: block;
}
html.tablet .bg-block-5 .how-work .description {
  margin-left: 4.5rem;
}
html.tablet .bg-block-5,
html.tablet .bg-block-6,
html.tablet .bg-block-5 .col-md-12,
html.tablet .bg-block-6 .col-md-12 {
  display: block;
}
html.tablet .bg-block-4 {
  background-size: cover;
}
html.tablet .bg-block-6 {
  background-size: cover;
}
html.tablet .bg-block-5 .how-work .how-work-image img {
  width: 35rem;
}
html.tablet .bg-block-7 .how-work .how-work-image img {
  width: 45rem;
}
html.tablet .bg-block-7,
html.tablet .bg-block-7 .col-md-12 {
  height: auto;
}
html.tablet .bg-block-6 .col-md-12 .carousel-main-baby {
  right: 4rem;
  bottom: -6.7rem;
}
html.tablet .bg-block-7 {
  height: auto;
}
html.tablet .bg-block-7 .container {
  height: 100%;
}
html.tablet .bg-block-4 .col-md-12 .carousel-main-baby {
  left: 4.5rem;
  bottom: -6.7rem;
}
html.tablet .bg-block-4 {
  height: 57rem;
  background-position: 60% 0;
}
html.tablet .bg-block-4 .col-md-12 {
  height: 57rem;
}
html.tablet footer {
  margin-top: 9rem;
  padding: 2.5rem 0 4rem 0;
  padding-bottom: 2.5rem;
}
html.tablet footer .container {
  min-width: 92%;
}
html.tablet .pharmamed img {
  width: 12rem;
}
html.tablet .footer-socials .social-item {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  line-height: 4.5rem;
  border-radius: 2.5rem;
  margin: 0 .8rem;
  border-width: .2rem;
}
html.tablet .footer-socials .social-item:hover:after {
  background-position: 0 100%;
}
html.tablet .tabs-scale.forte .btn-forte,
html.tablet .tabs-scale.baby .btn-more {
  padding: 0 9rem;
}
html.tablet .tabs-scale .tabs-content--bottom .icon-item {
  padding: 0 2.5rem;
}
html.tablet .tabs-scale .tabs-content--bottom .icon-item .tab-text {
  padding-top: .7rem;
  width: 24.2rem;
  font-size: 1.8rem;
  padding-left: 1.5rem;
}
html.tablet .tab-icon {
  width: 8.3rem;
  height: 7.7rem;
  background-size: contain;
}
html.tablet .tab-icon img {
  max-width: 45%;
  max-height: 45%;
  margin-top: 0.8rem;
}
html.tablet .tabs-scale .tabs-content--bottom img {
  margin-top: .8rem;
  margin-left: .2rem;
  max-width: 100%;
  max-height: 100%;
}
html.tablet .tabs-scale .tabs-content {
  height: 45rem;
}
html.tablet .tabs-scale.baby.transition-back .tabs-content .image-baby {
  right: 29%;
}
html.tablet .tabs-scale.baby .tabs-content .image-baby {
  right: 29%;
}
html.tablet .tabs-scale.baby.transition-back .tabs-content .image-forte {
  z-index: 1;
  transform: scale(0.8);
}
html.tablet .tabs-scale .tabs .logo-title {
  position: relative;
  display: inline-block;
  width: 28rem;
  height: 4.6rem;
  background-size: cover;
}
html.tablet .bg-block-23 {
  background-position: 50% 0;
  background-size: 140%;
}
html.tablet .tabs-scale .tabs-content .image-baby,
html.tablet .tabs-scale .tabs-content .image-forte {
  margin: -2rem 6% 0;
  width: 25%;
}
html.tablet .tabs-scale.forte .tabs-content .image-baby {
  margin-right: -1rem !important;
}
html.tablet .tabs-scale .tabs-content .left .icon-item,
html.tablet .tabs-scale .tabs-content .right .icon-item {
  padding-bottom: 2rem;
}
html.tablet .tabs-scale .tabs-desc {
  font-size: 2.2rem;
  padding-left: 4rem;
  min-height: 2.9rem;
  line-height: 2.9rem;
  margin-bottom: 3rem;
  background-size: auto 3rem;
}
html.tablet .tabs-scale .subtitle {
  font-size: 2.6rem;
}
html.tablet .tabs-scale .tabs-content .left,
html.tablet .tabs-scale .tabs-content .right {
  width: 28rem;
  top: 2.5rem;
  transform: scale(1);
}
html.tablet .tabs-scale .tabs-content .left .icon-item .tab-text,
html.tablet .tabs-scale .tabs-content .right .icon-item .tab-text {
  padding-top: .7rem;
  width: 22rem;
  font-size: 1.8rem;
}
html.tablet .tabs-scale .tabs-content .tab-icon {
  width: 5.3rem;
  height: 4.7rem;
}
html.tablet .developer {
  width: 14.1rem;
  height: 2.3rem;
  margin-top: 1.5rem;
  background-size: cover;
}
html.tablet .footer-socials .social-item:after {
  width: 1.1rem;
  height: 2.1rem;
  background-size: auto 200%;
}
html.tablet .footer-socials .social-item.vk:after {
  width: 2.5rem;
  height: 1.5rem;
}
html.tablet #carousel-main-3 {
  min-width: 100%;
  height: 78rem;
  width: 100%;
}
html.tablet #carousel-main-3 .carousel-inner .item {
  height: 77rem;
}
html.tablet .carousel-inner {
  min-height: 100%;
}
html.tablet .tabs-scale .tabs-content--bottom {
  width: 100%;
  margin: 0 auto 5rem;
}
html.tablet .benefit-icons .icon-item {
  width: 12rem;
  height: 12rem;
  line-height: 7rem;
  border: 2px solid #fe86a4;
  margin: 0 4.2rem;
  font-size: 1.2rem;
  border-radius: 1rem;
}
html.tablet .benefit-icons .icon-item img {
  height: 6rem;
}
html.tablet .benefit-icons .icon-item:first-child {
  margin-left: 0;
}
html.tablet .benefit-icons .icon-item:last-child {
  margin-right: 0;
}
html.tablet .benefit-icons .icon-item > span {
  padding: .5rem 0;
  font-size: 1.2rem;
}
html.tablet .link-down {
  padding-top: 5rem;
  padding-bottom: 0;
}
html.tablet #carousel-main-3 .carousel-control.left {
  left: 4rem;
}
html.tablet #carousel-main-3 .carousel-control.right {
  right: 4rem;
}
html.tablet .icon-down {
  width: 4.4rem;
  height: 4.4rem;
  border: 0.2rem solid #fe86a4;
  border-radius: 2.2rem;
}
html.tablet .icon-down:after {
  width: 1.4rem;
  height: .7rem;
  background-size: 200%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.7rem;
  margin-top: -0.2rem;
  background-position: 0;
}
html.tablet .icon-down:hover:after {
  background-position: -1.5rem 0;
}
html.tablet #carousel-main-3 .carousel-control {
  top: 48rem;
  width: 4.4rem;
  height: 4.4rem;
  border-width: 0.2rem;
  border-radius: 2.2rem;
}
html.tablet #carousel-main-3 .carousel-control.left:after {
  left: 1.4rem;
  background-position: 0 -0.1rem;
  background-size: 100%;
}
html.tablet #carousel-main-3 .carousel-control.right:after {
  right: 1.6rem;
  background-position: 0 66%;
  background-size: 100%;
}
html.tablet #carousel-main-3 .carousel-control:after {
  width: .7rem;
  height: 1.2rem;
  top: 50%;
  margin-top: -0.6rem;
}
html.tablet #carousel-main-3 .carousel-inner .item {
  background-size: auto 100% !important;
}
html.tablet #carousel-main-3 .carousel-inner .item .foreground img {
  width: 40rem;
  max-width: 40rem;
}
html.tablet #carousel-main-3 .carousel-inner .item .foreground {
  padding-left: 6rem;
  padding-right: 6rem;
}
html.tablet .header .container {
  height: 100%;
}
html.tablet #carousel-main-3 .carousel-inner .item .foreground h2,
html.tablet #carousel-main-3 .carousel-inner .item .foreground h3 {
  font-size: 4.6rem;
}
html.tablet #carousel-main-3 .carousel-inner .item .foreground {
  font-size: 2.5rem;
}
html.tablet #carousel-main-3 .carousel-inner .item .foreground .lead {
  font-size: 4.5rem;
}
html.tablet .tabs-scale .tabs .tab {
  border-width: .2rem;
  border-radius: .7rem;
  margin: 0 5.5rem;
  font-size: 2.6rem;
  min-width: 13.0rem;
  height: 5.0rem;
  line-height: 4.4rem;
}
html.tablet .tabs-scale .subtitle {
  font-size: 2.6rem;
  margin-top: 4.3rem;
  height: 10rem;
}
html.tablet .tabs-scale {
  padding-top: 7.5rem;
  padding-bottom: 6.1rem;
}
html.tablet .header nav {
  padding: 0 2rem;
}
html.tablet .fancybox-wrap.popup-medium h3,
html.tablet .q-item.popup-medium h3,
html.tablet .prob-benefits.popup-medium h3 {
  font-size: 2.2rem;
  margin-bottom: 3rem;
}
html.tablet .fancybox-wrap .fancybox-skin ul li,
html.tablet .q-item .fancybox-skin ul li,
html.tablet .prob-benefits .fancybox-skin ul li,
html.tablet .fancybox-wrap .popup ul li,
html.tablet .q-item .popup ul li,
html.tablet .prob-benefits .popup ul li {
  padding-bottom: 3rem;
}
html.tablet .fancybox-wrap .fancybox-skin ul li:before,
html.tablet .q-item .fancybox-skin ul li:before,
html.tablet .prob-benefits .fancybox-skin ul li:before,
html.tablet .fancybox-wrap .popup ul li:before,
html.tablet .q-item .popup ul li:before,
html.tablet .prob-benefits .popup ul li:before {
  top: .1rem;
  left: -2rem;
  font-size: 3.2rem;
}
html.tablet .fancybox-wrap.popup-medium .popup-content,
html.tablet .q-item.popup-medium .popup-content,
html.tablet .prob-benefits.popup-medium .popup-content {
  width: 73rem;
  padding: 0 4rem;
  font-size: 1.8rem;
  line-height: .1rem;
  letter-spacing: .05rem;
}
html.tablet .page-new .block-ld-small {
  padding-top: 5rem;
}
html.tablet .page-new .block-ld.prob-2 {
  background-position: center bottom;
}
html.tablet .page-new .block-ld.prob-2 .img {
  width: 100%;
}
html.tablet .page-new .block-ld.prob-2 .img img {
  width: 100%;
  height: auto;
}
html.tablet .page-new,
html.tablet .page-new p {
  font-size: 1.6rem;
}
html.tablet .page-new .block-ld .col-xs-5 {
  width: 50%;
}
html.tablet .page-new .block-ld .col-xs-7 {
  width: 50%;
}
html.tablet .page-new .block-ld .container {
  padding-left: 5rem;
  padding-right: 5rem;
}
html.tablet .page-new .block-ld.prob-1 .col-xs-5 {
  width: 25%;
}
html.tablet .page-new .block-ld.prob-1 .col-xs-7 {
  width: 75%;
}
html.tablet .page-new .block-ld.prob-3 .img .count b {
  font-size: 6rem;
}
html.tablet .page-new .block-ld.prob-3 .img .count .cell {
  width: 29.6rem;
  height: 29.6rem;
}
html.tablet .page-new .block-ld.prob-3 .img .count {
  width: 29.6rem;
  height: 29.6rem;
  font-size: 1.8rem;
  background-size: cover;
}
html.tablet .page-new .block-ld.prob-3 .img {
  margin-top: 2rem;
}
html.tablet .page-new .block-ld {
  background-position: 16%;
}
html.tablet .page-new h4 {
  font-size: 2.3rem;
}
html.tablet .page-new.lead,
html.tablet .page-new p.lead {
  font-size: 1.8rem;
}
html.tablet .prob-benefits .item .icon.icon-2:before {
  background-size: 60%;
}
html.tablet .page-new .block-ld.prob-4 {
  padding-top: 9rem;
  padding-bottom: 7rem;
}
html.tablet .page-new .block-ld {
  padding: 10rem 2rem 9rem;
}
html.tablet .prob-benefits .item .icon:before {
  background-size: auto 50%;
}
html.tablet .prob-benefits .item .icon {
  width: 14rem;
  height: 14rem;
  background-size: contain;
}
html.tablet .prob-benefits .item {
  padding-bottom: 7rem;
}
html.tablet .prob-benefits {
  padding: 7rem 8.5rem 0 8.5rem;
}
html.tablet .prob-benefits .popup.active {
  top: 4.5rem;
}
html.tablet .prob-benefits .popup .popup-content {
  padding: 0 12rem 4rem 12rem;
}
html.tablet .prob-benefits .popup .item {
  padding-bottom: 3.5rem;
  margin-top: -6rem;
}
html.tablet .prob-benefits .item .title {
  margin-top: 2rem;
  line-height: normal;
  font-size: 2.4rem;
}
html.tablet .prob-benefits .item .icon .btn-prob {
  width: 3rem;
  height: 3rem;
  margin-left: -1.5rem;
  margin-bottom: -0.9rem;
  background-size: cover;
}
html.tablet .prob-benefits .item .icon .btn-prob.close:after {
  background-size: auto 50%;
}
html.tablet .spec-block-1 h1 {
  width: 59rem;
  margin-top: 14rem;
}
html.tablet .spec-block-1 {
  min-height: 54.5rem;
  padding-left: 2.0rem;
  padding-right: 2.0rem;
}
html.tablet .spec-block-2 {
  margin-bottom: -7rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
html.tablet .article-block-2 {
  padding-top: 15.7rem;
  margin-top: -21.3rem;
  padding-bottom: 5.0rem;
}
html.tablet .spec-block-2 .container {
  padding-bottom: 7rem;
}
html.tablet .spec-list .list-item .title {
  font-size: 2.3rem;
  margin-bottom: 2.5rem;
}
html.tablet .paginator {
  bottom: 2rem;
}
html.tablet .spec-block-2 h2 {
  font-size: 4rem;
}
html.tablet .spec-list .icon-date,
html.tablet .spec-list .icon-place {
  margin-right: 1.5rem;
}
html.tablet .spec-list .list-item {
  padding-right: 7.0rem;
  padding-bottom: 4.5rem;
}
html.tablet .spec-list .icon-date:before {
  width: 1.3rem;
  height: 1.4rem;
  margin-top: -0.4rem;
  background-size: contain;
  margin-right: .8rem;
}
html.tablet .spec-list .icon-place:before {
  width: 1.5rem;
  height: 1.9rem;
  background-size: contain;
  margin-right: .8rem;
}
html.tablet .article-container {
  padding-left: 2rem;
  padding-right: 2rem;
}
html.tablet .breadcrumbs {
  padding: 3.5rem 0;
}
html.tablet .breadcrumbs li {
  font-size: 1.4rem;
  background-position: 0 .5rem;
  padding-left: 1.0rem;
  padding-right: .5rem;
}
html.tablet .article-container h1 {
  font-size: 4.2rem;
  line-height: 1.2;
  width: 56rem;
  margin-top: 3.5rem;
  max-width: 59rem;
}
html.tablet .faq-block-1 {
  height: 54.5rem;
  background-size: cover;
}
html.tablet .faq-block-3 h3 {
  font-size: 4.6rem;
  margin-bottom: 4.5rem;
}
html.tablet .faq-block-3 p {
  margin: 4.5rem 0 0 0;
}
html.tablet .fancybox-wrap .fancybox-skin h3,
html.tablet .q-item .fancybox-skin h3,
html.tablet .prob-benefits .fancybox-skin h3,
html.tablet .fancybox-wrap .popup h3,
html.tablet .q-item .popup h3,
html.tablet .prob-benefits .popup h3 {
  font-size: 2rem;
}
html.tablet .faq-block-2 .q-item .title {
  font-size: 2.4rem;
  min-height: inherit;
}
html.tablet .faq-block-2 .q-item .popup {
  top: -3rem;
}
html.tablet .fancybox-wrap.popup-form .popup-content,
html.tablet .q-item.popup-form .popup-content,
html.tablet .prob-benefits.popup-form .popup-content {
  width: 85rem;
  margin: 0 auto;
}
html.tablet .faq-block-2 .q-item {
  width: 43rem;
  padding: 4.5rem 2rem 2.5rem 2rem;
}
html.tablet .faq-slider .item-item .title {
  font-size: 2.3rem;
  margin-top: 2.0rem;
}
html.tablet .faq-block-2 .q-item .icon,
html.tablet .faq-block-2 .q-item .title {
  padding-bottom: 2rem;
}
html.tablet .faq-block-2 .q-item .icon img {
  width: 10rem;
}
html.tablet .faq-block-4 h3 {
  font-size: 4.6rem;
  margin-bottom: 4.5rem;
}
html.tablet .faq-slider .item {
  margin-top: 0;
}
html.tablet .faq-slider .item-item p {
  margin-top: 2.0rem;
  font-size: 1.8rem;
}
html.tablet .faq-block-2 {
  margin-bottom: 4rem;
  padding-bottom: 0;
}
html.tablet .faq-slider .item-item .item-content {
  width: 42rem;
}
html.tablet .faq-slider .item-item .item-content img {
  width: 100%;
  height: auto;
}
html.tablet .faq-block-2 .q-item .popup .popup-content {
  padding: 7rem 3.0rem;
  font-size: 1.8rem;
}
html.tablet .faq-slider {
  margin-bottom: 4rem;
}
html.tablet .carousel-control.left span,
html.tablet .carousel-control.right span {
  width: 1.8rem;
  height: 3.0rem;
  background-size: cover  !important;
}
html.tablet .faq-block-4 {
  margin-bottom: -7rem;
  padding: 8.5rem 0 5.5rem 0;
}
html.tablet .faq-slider .carousel-control.right {
  right: 0.5rem;
}
html.tablet .faq-slider .carousel-control.left {
  left: 0.5rem;
}
html.tablet .faq-list .list-item {
  padding: 5.5rem 11.5rem 3.5rem 11.5rem;
}
html.tablet .faq-list {
  margin-top: -2rem;
  margin-bottom: 15.0rem;
  font-size: 1.6rem;
  border-radius: 1.0rem;
}
html.tablet .faq-list .paginator {
  bottom: -70px;
  width: 100%;
}
html.tablet .faq-list .list-item .title {
  font-size: 2.4rem;
  margin-bottom: 3.0rem;
}
html.tablet .block-map .map {
  height: 67rem;
}
html.tablet .block-map .map-container .map-filter {
  width: 56rem;
  top: 3rem;
  margin-left: -25rem;
}
html.tablet .block-map .map-container .map-filter input[name=city],
html.tablet .block-map .map-container .map-filter button[type=submit],
html.tablet .block-map .map-container .map-filter .map-switch {
  margin: 0 1.7rem;
}
html.tablet #carousel-main-3 .carousel-inner .item {
  padding: 5.8rem 0 0;
}
html.tablet #slidetext,
html.tablet #slidetext2 {
  top: 4.7rem;
  margin-left: -3rem;
  font-size: 2.5rem;
}
html.tablet #slidetext i,
html.tablet #slidetext2 i {
  top: 2.2rem;
  width: 1.6rem;
  height: 7.3rem;
  background-size: cover;
}
html.tablet #slidetext b,
html.tablet #slidetext2 b {
  font-size: 5.4rem;
}
html.tablet .carousel-control.left {
  left: 2.4rem;
}
html.tablet .carousel-control.right {
  right: 2.4rem;
}
.logo-link {
  display: none;
}
.menu-link {
  display: none;
}
html.mobile .article-block-1 {
  min-height: 42rem;
}
html.mobile .article-container article {
  padding: 3rem;
  font-size: 2rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 1rem 3rem rgba(156, 66, 106, 0.3);
}
html.mobile .article-container article img.wide {
  width: calc(100% + 6rem);
  margin-left: -3rem;
  margin-right: -3rem;
}
html.mobile .article-container h3 {
  font-size: 2.4rem;
}
html.mobile .article-container .bottom-nav {
  font-size: 2rem;
  padding-top: 2rem;
}
html.mobile .faq-new .article-wrap.container {
  margin-bottom: 5rem;
}
html.mobile .faq-block-2 .prob-benefits {
  padding: 0;
}
html.mobile #carousel-main-3 .carousel-inner .item {
  padding: 9rem 0 0;
}
html.mobile .block-map .map-container .map-filter input[name=city],
html.mobile .block-map .map-container .map-filter button[type=submit],
html.mobile .block-map .map-container .map-filter .map-switch {
  margin: 0 1.7rem;
}
html.mobile .forte .btn-baby {
  display: inline-block;
}
html.mobile .block-map .map-container .map-filter {
  width: 60rem;
  top: 3rem;
  margin-left: -30rem;
}
html.mobile body {
  width: 100%;
  min-width: 100%;
  font-size: 1.4rem;
}
html.mobile #slidetext,
html.mobile #slidetext2 {
  top: 4.7rem;
  margin-left: -3rem;
  font-size: 2.5rem;
}
html.mobile #slidetext i,
html.mobile #slidetext2 i {
  top: 2.2rem;
  width: 1.6rem;
  height: 7.3rem;
  background-size: cover;
}
html.mobile #slidetext b,
html.mobile #slidetext2 b {
  font-size: 5.4rem;
}
html.mobile .carousel-control.left {
  left: 2.4rem;
}
html.mobile .carousel-control.right {
  right: 2.4rem;
}
html.mobile .header nav a.not-btn:hover,
html.mobile .header nav a.not-btn.active {
  text-decoration: none;
  font-family: inherit;
}
html.mobile .header nav a {
  padding: 2rem 5rem 1.8rem !important;
  font-size: 3.4rem;
  line-height: 1;
  height: 7rem;
  border-radius: 3.5rem;
  margin-bottom: 2rem;
}
@media (orientation: landscape) {
  html.mobile .header nav a {
    padding: 1rem 2.5rem 0.9rem !important;
    font-size: 1.7rem;
    height: 3.5rem;
    border-radius: 1.75rem;
    margin-bottom: 1rem;
  }
}
html.mobile .wrapper {
  padding-top: 14rem;
}
@media (orientation: landscape) {
  html.mobile .wrapper {
    padding-top: 7rem;
  }
}
html.mobile .header nav a.not-btn {
  width: auto;
}
html.mobile .header .container {
  padding: 2.7rem 0 0;
  min-width: 100%;
}
html.mobile .header nav a.nab-btn {
  padding: 2rem 5rem 1.8rem !important;
  font-size: 3.4rem;
  margin-bottom: 6rem;
}
@media (orientation: landscape) {
  html.mobile .header nav a.nab-btn {
    padding: 1rem 2.5rem 1.2rem !important;
    font-size: 1.7rem;
    margin-bottom: 0;
  }
}
html.mobile .how-work {
  width: 100%;
  margin: 0 auto;
}
html.mobile .footer-text {
  width: 80%;
  height: 0;
  padding-bottom: 18.2%;
  margin-top: 0;
  margin-bottom: 0;
  background-image: url(../images/mobile/footer-text.png);
}
html.mobile .page-new h3 {
  font-size: 3.3rem;
}
html.mobile .page-new .block-ld.prob-1 {
  background-position: 50% 0;
  background-size: 200% auto;
}
html.mobile .spec-block-1 h1,
html.mobile .page-new h2 {
  font-size: 3.7rem;
  line-height: 1.2;
}
html.mobile .container {
  max-width: 100%;
  min-width: 100%;
}
html.mobile .block-map {
  padding: 55px 0 6rem;
}
html.mobile .btn-big {
  padding: 0 4rem;
  height: 6rem;
  line-height: 6.2rem;
  font-size: 2.2rem;
  border-radius: 3rem;
  border-width: 0.2rem;
}
html.mobile .block-map .map-container {
  height: 66rem;
  margin-bottom: 5rem;
}
html.mobile .block-map .map {
  height: 66rem;
}
html.mobile .block-map h3 {
  font-size: 3.8rem;
}
html.mobile .block-map .map-container .map-filter button[type=submit] {
  padding: 1.5rem 2rem;
  border-radius: 2.5rem;
  width: 21rem;
  height: 5rem;
  vertical-align: top;
}
html.mobile .block-map .map-container .map-filter .map-switch {
  width: 5rem;
  height: 5rem;
  vertical-align: top;
}
html.mobile .block-map .map-container .map-filter .map-switch > .icon-list {
  width: 1.9rem;
  height: 1.2rem;
  margin-left: -1rem;
  margin-top: -0.6rem;
  background-size: contain;
}
html.mobile .block-map .map-container .map-filter .map-switch > .icon-map {
  width: 1.7rem;
  height: 2.4rem;
  margin-left: -0.9rem;
  margin-top: -1.2rem;
  background-size: contain;
}
html.mobile .block-map .map-container .map-filter input[name=city] {
  padding: 1.2rem 2.0rem;
  border-radius: 2.5rem;
  width: 21rem;
  height: 5rem;
}
html.mobile .block-map .map-container .map-filter input[name=city]::-webkit-input-placeholder {
  font-size: 1.8rem;
  top: 0.3rem;
  position: relative;
}
html.mobile .bg-block-5,
html.mobile .bg-block-6,
html.mobile .bg-block-5 .col-md-12,
html.mobile .bg-block-6 .col-md-12 {
  height: 54rem;
  width: 100%;
}
html.mobile .bakset-baby-title,
html.mobile .bakset-forte-title,
html.mobile .how-work h4 {
  margin-top: 4rem;
  line-height: 4.5rem;
  font-size: 3.8rem;
}
html.mobile .how-work .description {
  margin-top: 7rem;
  width: 57rem;
  padding-bottom: 8rem;
}
html.mobile .how-work .description .desc-title {
  font-size: 2.6rem;
}
html.mobile .how-work .description p {
  margin-top: 2.5rem;
  font-size: 2.4rem;
  line-height: 2.8rem;
}
html.mobile .how-work.block-7 .how-work-image {
  width: 0;
}
html.mobile .how-work .more-knew {
  bottom: 2rem;
  font-size: 2rem;
}
html.mobile .how-work .more-knew:before {
  bottom: -0.4rem;
  width: 12.2rem;
  height: 1px;
}
html.mobile .carousel-main-baby {
  width: 39rem;
  height: 39rem;
}
html.mobile .carousel-inner .item .slide-text {
  padding: 0 3rem;
  width: 42rem;
  height: 7.5rem;
}
html.mobile .carousel-inner .item {
  margin-top: 17.5rem;
  line-height: 2.5rem;
  font-size: 2.4rem;
}
html.mobile .bakset-baby-title b,
html.mobile .how-work h4 span,
html.mobile .bakset-forte-title b {
  font-size: 4.8rem;
}
html.mobile .bg-block-6 .container,
html.mobile .bg-block-6 {
  height: 54rem;
  background-size: auto 80% !important;
  background-position: 30% bottom;
}
html.mobile .bg-block-5 .col-md-12 {
  height: auto;
}
html.mobile .bg-block-5 .description {
  margin-top: 55rem;
  padding-bottom: 2rem;
}
html.mobile .bg-block-5,
html.mobile .bg-block-5 .container {
  height: auto;
  display: block;
}
html.mobile .bg-block-5 .how-work .description {
  margin-left: 4.5rem;
}
html.mobile .bg-block-5,
html.mobile .bg-block-6,
html.mobile .bg-block-5 .col-md-12,
html.mobile .bg-block-6 .col-md-12 {
  display: block;
}
html.mobile .bg-block-4 {
  background-size: cover;
}
html.mobile .bg-block-6 {
  background-size: cover;
}
html.mobile .bg-block-5 {
  background: none;
}
html.mobile .bg-block-5 .how-work {
  padding-bottom: 5rem;
}
html.mobile .bg-block-5 .how-work .how-work-image img {
  width: 40rem;
  position: absolute;
  top: 8rem;
  left: 13rem;
}
html.mobile .bg-block-7 .how-work .how-work-image img {
  width: 50rem;
  position: absolute;
  top: 8rem;
  left: 8rem;
}
html.mobile .bg-block-7,
html.mobile .bg-block-7 .col-md-12 {
  height: auto;
}
html.mobile .bg-block-6 .col-md-12 .carousel-main-baby {
  right: 0;
  bottom: -8.7rem;
}
html.mobile .bg-block-7 {
  height: auto;
  background-size: cover;
  background-position: top left;
}
html.mobile .bg-block-7 .description {
  margin-top: 60rem;
}
html.mobile .bg-block-7 .container {
  height: 100%;
}
html.mobile .bg-block-4 .col-md-12 .carousel-main-baby {
  left: 0;
}
html.mobile .bg-block-4 {
  height: 57rem;
  background-position: 64% bottom;
  background-size: auto 72%;
}
html.mobile .bg-block-4 .col-md-12 {
  height: 57rem;
}
html.mobile footer {
  margin-top: 6rem;
  padding: 3.5rem 0 4rem 0;
  padding-bottom: 2.5rem;
}
html.mobile footer .container {
  min-width: 92%;
  max-width: 92%;
}
html.mobile .pharmamed img {
  width: 12rem;
}
html.mobile .footer-socials .social-item {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  line-height: 4.5rem;
  border-radius: 2.5rem;
  margin: 0 .8rem;
  border-width: .2rem;
}
html.mobile .footer-socials .social-item:hover:after {
  background-position: 0 100%;
}
html.mobile .footer-socials {
  width: 21rem;
  margin-left: -3rem;
}
html.mobile .tabs-scale.forte .btn-forte,
html.mobile .tabs-scale.baby .btn-more {
  padding: 0 9rem;
}
html.mobile .tabs-scale .tabs-content--bottom .icon-item {
  padding: 0 2.5rem;
}
html.mobile .tabs-scale .tabs-content--bottom .icon-item .tab-text {
  padding-top: .7rem;
  width: 24.2rem;
  font-size: 1.8rem;
  padding-left: 1.5rem;
}
html.mobile .tab-icon {
  width: 8.3rem;
  height: 7.7rem;
  background-size: contain;
}
html.mobile .tab-icon img {
  max-width: 45%;
  max-height: 45%;
  margin-top: 0.8rem;
}
html.mobile .icon-item:nth-last-child(2) .tab-icon img,
html.mobile .icon-item:nth-last-child(1) .tab-icon img {
  max-width: 100%;
  max-height: 90%;
  margin-top: 0.2rem;
}
html.mobile .tabs-scale .tabs-content--bottom img {
  margin-top: .8rem;
  margin-left: .2rem;
  max-width: 100%;
  max-height: 100%;
}
html.mobile .tabs-scale .tabs-content {
  height: 45rem;
}
html.mobile .tabs-scale.baby.transition-back .tabs-content .image-baby {
  right: 29%;
}
html.mobile .tabs-scale.baby .tabs-content .image-baby {
  right: 29%;
}
html.mobile .tabs-scale.baby.transition-back .tabs-content .image-forte {
  z-index: 1;
  transform: scale(0.8);
}
html.mobile .tabs-scale .tabs .logo-title {
  position: relative;
  display: inline-block;
  width: 28rem;
  height: 4.6rem;
  background-size: cover;
}
html.mobile .bg-block-23 {
  background-position: 50% 0;
  background-size: 140%;
}
html.mobile .tabs-scale .tabs-content .image-baby,
html.mobile .tabs-scale .tabs-content .image-forte {
  margin: -2rem 6% 0;
  width: 25%;
}
html.mobile .tabs-scale.forte .tabs-content .image-baby {
  margin-right: -1rem !important;
}
html.mobile .tabs-scale .tabs-content .left .icon-item,
html.mobile .tabs-scale .tabs-content .right .icon-item {
  padding-bottom: 2rem;
}
html.mobile .tabs-scale .tabs-desc {
  font-size: 2.2rem;
  padding-left: 4rem;
  min-height: 2.9rem;
  line-height: 2.9rem;
  margin-bottom: 3rem;
  background-size: auto 3rem;
}
html.mobile .tabs-scale .subtitle {
  font-size: 2.6rem;
}
html.mobile .tabs-scale .tabs-content .left,
html.mobile .tabs-scale .tabs-content .right {
  width: 28rem;
  top: 2.5rem;
  transform: scale(1);
}
html.mobile .tabs-scale .tabs-content .left .icon-item .tab-text,
html.mobile .tabs-scale .tabs-content .right .icon-item .tab-text {
  padding-top: .7rem;
  width: 22rem;
  font-size: 1.8rem;
}
html.mobile .tabs-scale .tabs-content .tab-icon {
  width: 5.3rem;
  height: 4.7rem;
}
html.mobile .developer {
  width: 14.1rem;
  height: 2.3rem;
  margin-top: 1.5rem;
  background-size: cover;
}
html.mobile .footer-socials .social-item:after {
  width: 1.1rem;
  height: 2.1rem;
  background-size: auto 200%;
}
html.mobile .footer-socials .social-item.vk:after {
  width: 2.5rem;
  height: 1.5rem;
}
html.mobile #carousel-main-3 {
  min-width: 100%;
  height: 75rem;
  width: 100%;
}
html.mobile #carousel-main-3 .carousel-inner .item {
  height: 55rem;
}
html.mobile .carousel-inner {
  min-height: 100%;
}
html.mobile .tabs-scale .tabs-content--bottom {
  width: 100%;
  margin: 0 auto 5rem;
}
html.mobile .benefit-icons .icon-item {
  width: 14rem;
  height: 14rem;
  line-height: 7rem;
  border: 0.2rem solid #fe86a4;
  margin: 0 1.35rem 2.7rem;
  font-size: 1.4rem;
  border-radius: 1.5rem;
}
html.mobile .benefit-icons .icon-item img {
  height: 7rem;
}
html.mobile .benefit-icons .icon-item:first-child {
  margin-left: 1.35rem;
}
html.mobile .benefit-icons .icon-item:last-child {
  margin-right: 1.35rem;
}
html.mobile .benefit-icons .icon-item > span {
  padding: .5rem 0;
  font-size: 1.4rem;
}
html.mobile .link-down {
  padding-top: 5rem;
  padding-bottom: 0;
}
html.mobile #carousel-main-3 .carousel-control.left {
  left: 0.8rem;
}
html.mobile #carousel-main-3 .carousel-control.right {
  right: 0.8rem;
}
html.mobile .icon-down {
  display: none;
}
html.mobile #carousel-main-3 .carousel-control {
  top: 20rem;
  width: 2.5rem;
  height: 2.5rem;
  border: 0.2rem solid #fe86a4;
  border-radius: 1.3rem;
}
html.mobile #carousel-main-3 .carousel-control.left:after {
  left: .7rem;
  background-position: 0 -0.1rem;
  background-size: 100%;
}
html.mobile #carousel-main-3 .carousel-control.right:after {
  right: .7rem;
  background-position: 0 66%;
  background-size: 100%;
}
html.mobile #carousel-main-3 .carousel-control:after {
  width: .5rem;
  height: 0.9rem;
  top: 50%;
  margin-top: -0.45rem;
}
html.mobile #carousel-main-3 .carousel-inner .item {
  background-size: auto 100% !important;
  background-position: 20%;
}
html.mobile #carousel-main-3 .carousel-inner .item .foreground img {
  width: 29rem;
  max-width: 29rem;
  height: 5rem;
}
html.mobile #carousel-main-3 .carousel-inner .item .foreground {
  padding-left: 4rem;
  padding-right: 26rem;
}
html.mobile .header .container {
  height: 100%;
}
html.mobile #carousel-main-3 .carousel-inner .item .foreground h2,
html.mobile #carousel-main-3 .carousel-inner .item .foreground h3 {
  font-size: 3.2rem;
  margin-bottom: 2rem;
}
html.mobile #carousel-main-3 .carousel-inner .item .foreground {
  font-size: 2.2rem;
}
html.mobile #carousel-main-3 .carousel-inner .item .foreground .lead {
  font-size: 2.4rem;
}
html.mobile .tabs-scale .tabs .tab {
  border-width: .2rem;
  border-radius: .7rem;
  margin: 0 5.5rem;
  font-size: 2.6rem;
  min-width: 13.0rem;
  height: 5.0rem;
  line-height: 4.4rem;
}
html.mobile .tabs-scale .subtitle {
  font-size: 2.6rem;
  margin-top: 4.3rem;
  height: 10rem;
}
html.mobile .tabs-scale {
  padding-top: 0;
  padding-bottom: 6.1rem;
}
html.mobile .header nav {
  padding: 0 2rem;
}
html.mobile .fancybox-wrap.popup-medium h3,
html.mobile .q-item.popup-medium h3,
html.mobile .prob-benefits.popup-medium h3 {
  font-size: 2.2rem;
  margin-bottom: 3rem;
}
html.mobile .fancybox-wrap .fancybox-skin ul li,
html.mobile .q-item .fancybox-skin ul li,
html.mobile .prob-benefits .fancybox-skin ul li,
html.mobile .fancybox-wrap .popup ul li,
html.mobile .q-item .popup ul li,
html.mobile .prob-benefits .popup ul li {
  padding-bottom: 3rem;
}
html.mobile .fancybox-wrap .fancybox-skin ul li:before,
html.mobile .q-item .fancybox-skin ul li:before,
html.mobile .prob-benefits .fancybox-skin ul li:before,
html.mobile .fancybox-wrap .popup ul li:before,
html.mobile .q-item .popup ul li:before,
html.mobile .prob-benefits .popup ul li:before {
  top: .1rem;
  left: -2rem;
  font-size: 3.2rem;
}
html.mobile .fancybox-wrap.popup-medium .popup-content,
html.mobile .q-item.popup-medium .popup-content,
html.mobile .prob-benefits.popup-medium .popup-content {
  width: 100%;
  padding: 0;
  font-size: 2.2rem;
  line-height: .1rem;
  letter-spacing: .05rem;
}
html.mobile .fancybox-outer {
  width: 105%;
}
html.mobile .fancybox-inner {
  width: 105%;
  padding-right: 17%;
}
html.mobile .page-new .block-ld-small {
  padding-top: 5rem;
}
html.mobile .page-new .block-ld.prob-2 {
  background-position: center bottom;
}
html.mobile .page-new .block-ld.prob-2 .img {
  width: 100%;
}
html.mobile .page-new .block-ld.prob-2 .img img {
  width: 100%;
  height: auto;
}
html.mobile .page-new,
html.mobile .page-new p {
  font-size: 2.4rem;
  line-height: 1.2;
}
html.mobile .page-new .block-ld .col-xs-5 {
  width: 100%;
  padding: 0 15%;
}
html.mobile .page-new .block-ld .col-xs-7 {
  width: 100%;
}
html.mobile .page-new .block-ld .container {
  padding-left: 2rem;
  padding-right: 2rem;
}
html.mobile .page-new .block-ld.prob-1 .col-xs-5 {
  width: 25%;
}
html.mobile .page-new .block-ld.prob-1 .col-xs-7 {
  width: 100%;
}
html.mobile .page-new .block-ld.prob-1 .col-xs-7 p:first-of-type {
  width: 80%;
}
html.mobile .page-new .block-ld.prob-1 {
  margin-bottom: 0;
}
html.mobile .page-new .block-ld.prob-2 {
  margin-top: 0;
  background: none;
}
html.mobile .page-new .block-ld.prob-3 .img .count b {
  font-size: 6rem;
}
html.mobile .page-new .block-ld.prob-3 .img .count .cell {
  width: 29.6rem;
  height: 29.6rem;
}
html.mobile .page-new .block-ld.prob-3 .img .count {
  width: 29.6rem;
  height: 29.6rem;
  font-size: 1.8rem;
  background-size: cover;
}
html.mobile .page-new .block-ld.prob-3 {
  background-position: 10% bottom;
  background-size: auto 139%;
  padding-top: 0;
  padding-bottom: 2rem;
}
html.mobile .page-new .block-ld.prob-3 .container {
  position: relative;
}
html.mobile .page-new .block-ld.prob-3 .container .col-xs-7 {
  padding-top: 36rem;
}
html.mobile .page-new .block-ld.prob-3 .container .col-xs-5 {
  position: absolute;
  top: 0;
  left: -2rem;
}
html.mobile .page-new .block-ld.prob-4 {
  padding-top: 0;
  padding-bottom: 2rem;
  background-image: url(../images/mobile/bact.jpg);
  background-position: center top;
  background-size: cover;
}
html.mobile .page-new .block-ld.prob-4 .container {
  position: relative;
}
html.mobile .page-new .block-ld.prob-4 .container .col-xs-7 {
  padding-top: 24rem;
}
html.mobile .page-new .block-ld.prob-3 .img {
  margin-top: 2rem;
}
html.mobile .page-new .block-ld {
  background-position: 16%;
}
html.mobile .page-new h4 {
  font-size: 2.6rem;
}
html.mobile .page-new.lead,
html.mobile .page-new p.lead {
  font-size: 2.4rem;
}
html.mobile .prob-benefits .item .icon.icon-2:before {
  background-size: 60%;
}
html.mobile .page-new .block-ld.prob-4 {
  padding-top: 9rem;
  padding-bottom: 2rem;
}
html.mobile .page-new .block-ld {
  padding: 5rem 2rem 0;
}
html.mobile .prob-benefits .item .icon:before {
  background-size: auto 50%;
}
html.mobile .prob-benefits .item .icon {
  width: 14rem;
  height: 14rem;
  background-size: contain;
}
html.mobile .prob-benefits .item {
  padding-bottom: 7rem;
}
html.mobile .prob-benefits {
  padding: 7rem 5.5rem 0 5.5rem;
}
html.mobile .prob-benefits .row:nth-child(odd) {
  position: relative;
}
html.mobile .prob-benefits .row:nth-child(odd) .col-xs-4:last-child {
  position: absolute;
  bottom: -100%;
}
html.mobile .prob-benefits .row:nth-child(even) .col-xs-4:first-child {
  width: 50%;
  margin-left: 50%;
}
html.mobile .prob-benefits .col-xs-4 {
  width: 50%;
}
html.mobile .prob-benefits .popup.active {
  top: 4.5rem;
}
html.mobile .prob-benefits .popup .popup-content {
  padding: 0 2rem 1rem;
}
html.mobile .prob-benefits .popup .popup-content .popup-text p {
  font-size: 2.4rem;
  margin-bottom: 1rem;
}
html.mobile .prob-benefits .popup .item {
  padding-bottom: 3.5rem;
  margin-top: -6rem;
}
html.mobile .prob-benefits .item .title {
  margin-top: 2rem;
  line-height: normal;
  font-size: 2.6rem;
}
html.mobile .prob-benefits .item .icon .btn-prob {
  width: 3rem;
  height: 3rem;
  margin-left: -1.5rem;
  margin-bottom: -0.9rem;
  background-size: cover;
}
html.mobile .prob-benefits .item .icon .btn-prob.close:after {
  background-size: auto 50%;
}
html.mobile .spec-block-1 h1 {
  width: 35rem;
  margin-top: 6rem;
}
html.mobile .spec-block-1 {
  min-height: 39.5rem;
  padding-left: 2.0rem;
  padding-right: 2.0rem;
}
html.mobile .spec-block-2 {
  margin-bottom: -7rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
html.mobile .article-block-2 {
  padding-top: 15.7rem;
  margin-top: -21.3rem;
  padding-bottom: 12.0rem;
}
html.mobile .spec-block-2 .container {
  padding-bottom: 7rem;
}
html.mobile .spec-list .list-item .title {
  font-size: 2.3rem;
  margin-bottom: 2.5rem;
}
html.mobile .paginator {
  bottom: 2rem;
}
html.mobile .paginator .prev,
html.mobile .paginator .next {
  height: 2.5rem;
  line-height: 2.5rem;
}
html.mobile .paginator .prev svg,
html.mobile .paginator .next svg {
  width: 100%;
  height: 100%;
}
html.mobile .paginator ul {
  margin: 0 1rem;
}
html.mobile .paginator ul li {
  margin: 0 .5rem;
}
html.mobile .paginator ul li a {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin: 0 -0.2rem;
}
html.mobile .spec-block-2 h2 {
  font-size: 3rem;
}
html.mobile .spec-list .list-item .description {
  font-size: 1.8rem;
}
html.mobile .spec-list .icon-date,
html.mobile .spec-list .icon-place {
  margin-right: 1.5rem;
}
html.mobile .spec-list .list-item {
  padding-right: 0;
  padding-bottom: 4.5rem;
}
html.mobile .spec-list .icon-date:before {
  width: 1.3rem;
  height: 1.4rem;
  margin-top: -0.4rem;
  background-size: contain;
  margin-right: .8rem;
}
html.mobile .spec-list .icon-place:before {
  width: 1.5rem;
  height: 1.9rem;
  background-size: contain;
  margin-right: .8rem;
}
html.mobile .article-container {
  padding-left: 2rem;
  padding-right: 2rem;
}
html.mobile .breadcrumbs {
  padding: 3.5rem 0;
}
html.mobile .breadcrumbs li {
  font-size: 1.4rem;
  background-position: 0 .5rem;
  padding-left: 1.0rem;
  padding-right: .5rem;
}
html.mobile .article-container h1 {
  font-size: 3.7rem;
  line-height: 1.2;
  width: 36rem;
  margin-top: 3.5rem;
  max-width: 36rem;
}
html.mobile .faq-block-1 {
  height: 39.5rem;
  background-size: cover;
  background-position: 60% 0px;
}
html.mobile .faq-block-3 {
  padding: 5rem 0;
  text-align: center;
  color: white;
  font-size: 2rem;
}
html.mobile .faq-block-3 p {
  font-size: 2.4rem;
}
html.mobile .faq-block-3 br {
  display: none;
}
html.mobile .faq-block-3 h3 {
  font-size: 3.5rem;
  margin-bottom: 4.5rem;
}
html.mobile .faq-block-3 p {
  margin: 4.5rem 0 0 0;
}
html.mobile .fancybox-wrap .fancybox-skin h3,
html.mobile .q-item .fancybox-skin h3,
html.mobile .prob-benefits .fancybox-skin h3,
html.mobile .fancybox-wrap .popup h3,
html.mobile .q-item .popup h3,
html.mobile .prob-benefits .popup h3 {
  font-size: 3rem;
}
@media (orientation: landscape) {
  html.mobile .fancybox-wrap .fancybox-skin h3,
  html.mobile .q-item .fancybox-skin h3,
  html.mobile .prob-benefits .fancybox-skin h3,
  html.mobile .fancybox-wrap .popup h3,
  html.mobile .q-item .popup h3,
  html.mobile .prob-benefits .popup h3 {
    font-size: 2rem;
  }
}
html.mobile .fancybox-wrap.popup-form .fancybox-inner {
  overflow: auto !important;
}
html.mobile .fancybox-wrap.popup-form .popup-content input,
html.mobile .fancybox-wrap.popup-form .popup-content textarea {
  font-size: 2rem;
}
html.mobile .fancybox-wrap.popup-form .popup-content input::-webkit-input-placeholder,
html.mobile .fancybox-wrap.popup-form .popup-content textarea::-webkit-input-placeholder {
  font-size: 2rem;
}
html.mobile .fancybox-wrap.popup-form .popup-content textarea {
  height: 16rem;
  resize: none;
}
html.mobile .fancybox-wrap .fancybox-skin .fancybox-close {
  top: -2.2rem;
  right: -2.2rem;
  width: 4.4rem;
  height: 4.4rem;
}
html.mobile .fancybox-wrap .fancybox-skin .fancybox-close:after {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: -0.7rem;
  margin-top: -0.7rem;
  background-size: cover;
}
html.mobile .fancybox-skin {
  padding: 4rem !important;
}
html.mobile .fancybox-wrap .col-xs-6 {
  width: 100%;
  margin-top: 2rem;
}
html.mobile .fancybox-wrap .col-xs-6:first-child {
  margin-top: -1rem;
}
html.mobile .form-field {
  border: 0.2rem solid #bcbcbc;
  padding: 2rem 2rem;
  width: 100%;
  border-radius: 3rem;
}
@media (orientation: landscape) {
  html.mobile .form-field {
    padding: 1rem 1rem;
    width: 100%;
    border-radius: 1.5rem;
  }
}
html.mobile .faq-block-2 .q-item .title {
  font-size: 2.4rem;
  min-height: inherit;
}
html.mobile .faq-block-2 .q-item .popup {
  top: -3rem;
}
html.mobile .faq-block-2 .btn-big.middle {
  font-size: 1.8rem;
  padding: 0 2.0rem;
}
html.mobile .fancybox-wrap.popup-form .popup-content,
html.mobile .q-item.popup-form .popup-content,
html.mobile .prob-benefits.popup-form .popup-content {
  width: 100%;
  margin: 0 auto;
}
html.mobile .faq-block-2 .row {
  position: relative;
}
html.mobile .faq-block-2 .q-item,
html.mobile .faq-block-2 .col-xs-6 {
  position: static;
}
html.mobile .faq-block-2 .q-item {
  width: 30rem;
  padding: 4.5rem 1rem 7.5rem 1rem;
}
html.mobile .faq-slider .item-item .title {
  font-size: 2.3rem;
  margin-top: 2.0rem;
}
html.mobile .faq-block-2 .q-item .icon,
html.mobile .faq-block-2 .q-item .title {
  padding-bottom: 2rem;
}
html.mobile .faq-block-2 .q-item .title br {
  display: none;
}
html.mobile .faq-block-2 .text-center a {
  position: relative !important;
  margin-left: 0 !important;
}
html.mobile .faq-block-2 .btn-big.middle {
  position: absolute;
  width: 25rem;
  bottom: 0;
  margin-left: -12.5rem;
}
html.mobile .faq-block-2 .q-item .icon img {
  width: 10rem;
}
html.mobile .faq-block-4 h3 {
  font-size: 4.6rem;
  margin-bottom: 4.5rem;
}
html.mobile .faq-slider .item {
  margin-top: 0;
}
html.mobile .faq-slider .item-item p {
  margin-top: 2.0rem;
  font-size: 1.8rem;
}
html.mobile .faq-block-2 {
  margin-bottom: 4rem;
  padding-bottom: 0;
}
html.mobile .faq-slider .item-item .item-content {
  width: 25rem;
}
html.mobile .faq-slider .item-item .item-content img {
  width: 100%;
  height: auto;
}
html.mobile .faq-block-2 .q-item .popup .popup-content {
  padding: 2rem 2rem 6rem;
  font-size: 2.2rem;
}
html.mobile .faq-slider {
  margin-bottom: 4rem;
}
html.mobile #carousel-main-3 .carousel-control:hover {
  background: white;
}
html.mobile #carousel-main-3 .carousel-control:active {
  background-color: #fe86a4;
}
html.mobile .map-container .list-container {
  padding-top: 13rem;
  padding-bottom: 4.5rem;
  font-size: 2rem;
  width: 100%;
}
html.mobile .map-container .list-container .col-xs-6 {
  width: 43%;
}
html.mobile .list-container .container > .row [class^='col-'] {
  padding: 2rem 1rem 2rem 2rem !important;
}
html.mobile .ui-menu {
  width: 45.8rem;
  max-height: 50rem;
  overflow: auto;
  font-size: 2.2rem;
  padding: 2.2rem 0 2rem 0;
  margin: 0;
  border-radius: 0 0 2.5rem 2.5rem;
}
html.mobile .gmnoprint .gm-style-mtc {
  top: 10rem !important;
  position: relative;
}
html.mobile .carousel-control.left span,
html.mobile .carousel-control.right span {
  width: 1.8rem;
  height: 3.0rem;
  background-size: cover !important;
}
html.mobile .faq-block-4 {
  margin-bottom: -7rem;
  padding: 8.5rem 0 5.5rem 0;
}
html.mobile .faq-slider .carousel-control.right {
  right: -2rem;
}
html.mobile .faq-slider .carousel-control.left {
  left: -2rem;
}
html.mobile .faq-list .list-item {
  padding: 4rem 4rem 1rem 4rem;
}
html.mobile .faq-list .list-item p {
  font-size: 2.4rem;
}
html.mobile .faq-list {
  margin-top: -2rem;
  margin-bottom: 20.0rem;
  font-size: 1.6rem;
  border-radius: 1.0rem;
}
html.mobile .faq-list .paginator {
  bottom: -70px;
  width: 100%;
}
html.mobile .faq-list .list-item .title {
  font-size: 2.4rem;
  margin-bottom: 3.0rem;
}
html.mobile .menu-link {
  display: inline-block;
  float: right;
  padding: 2rem;
  padding-bottom: 1.6rem;
  font-size: 2.2rem;
  background: #f27cb1;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  border-radius: 0.5rem;
  line-height: 1;
  margin-top: 4rem;
  margin-right: 4rem;
}
@media (orientation: landscape) {
  html.mobile .menu-link {
    padding: 1rem;
    padding-bottom: 0.8rem;
    font-size: 1.1rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
    margin-right: 2rem;
  }
}
html.mobile .header {
  height: 14rem;
}
@media (orientation: landscape) {
  html.mobile .header {
    height: 7rem;
  }
}
html.mobile .header .logo-link {
  display: inline-block;
  width: 18rem;
  height: 3.1rem;
  margin-left: 3.6rem;
  margin-top: 5.3rem;
  background-image: url(../images/logo-tabs-scale.png);
  background-size: contain;
  background-repeat: no-repeat;
}
@media (orientation: landscape) {
  html.mobile .header .logo-link {
    display: inline-block;
    width: 9rem;
    height: 1.55rem;
    margin-left: 1.8rem;
    margin-top: 2.65rem;
  }
}
html.mobile .header .container {
  display: none;
  position: absolute;
  top: 14rem;
  height: auto;
  box-shadow: inset 2px 3.464px 8px 0 rgba(0, 0, 0, 0.13);
}
@media (orientation: landscape) {
  html.mobile .header .container {
    top: 7rem;
    height: calc(100vh - 7rem);
    overflow: auto;
  }
}
html.mobile .header nav li {
  display: block;
  width: 100%;
  text-align: center;
}
html.mobile .baby .btn-forte {
  display: inline-block;
}
html.mobile .tabs-scale.forte .subtitle .text-default,
html.mobile .tabs-scale.forte .subtitle .text-baby,
html.mobile .tabs-scale.baby .subtitle .text-default {
  opacity: 1;
  visibility: visible;
}
html.mobile .bg-block-23 {
  background: none;
}
html.mobile .tabs-cols {
  margin-bottom: 8rem;
}
html.mobile .tabs-cols.forte {
  margin-bottom: 0;
}
html.mobile .tabs-cols .image-forte,
html.mobile .tabs-cols .image-baby {
  width: 35rem;
}
html.mobile .tabs-cols.baby h4 {
  color: #f27cb1;
  font-size: 2.4rem;
  font-weight: 600;
}
html.mobile .tabs-cols.baby .tab-icon {
  background-image: url(../images/tabs-triangle-right.png);
}
html.mobile .tabs-cols.baby .icon-item .tab-text {
  color: #f27cb1;
}
html.mobile .tabs-cols.baby .btn-more {
  background-color: #f27cb1;
  border-color: #f27cb1;
}
html.mobile .tabs-cols.baby .btn-more:hover {
  background-color: transparent;
  color: #f27cb1;
}
html.mobile .tabs-cols.forte h4 {
  color: #f29f05;
  font-size: 2.4rem;
  font-weight: 600;
}
html.mobile .tabs-cols.forte .tab-icon {
  background-image: url(../images/tabs-triangle-left.png);
}
html.mobile .tabs-cols.forte .icon-item .tab-text {
  color: #f29f05;
}
html.mobile .tabs-cols.forte .btn-more {
  background-color: #f29f05;
  border-color: #f29f05;
}
html.mobile .tabs-cols.forte .btn-more:hover {
  background-color: transparent;
  color: #f29f05;
}
html.mobile .tabs-cols .icon-item {
  display: inline-block;
  vertical-align: top;
  width: 49%;
  padding: 0 1rem;
  cursor: pointer;
  text-align: center;
  margin-bottom: 4rem;
}
html.mobile .tabs-cols .icon-item:hover .tab-text {
  text-decoration: underline;
}
html.mobile .tabs-cols .icon-item .tab-icon {
  display: inline-block;
}
html.mobile .tabs-cols .icon-item .tab-text {
  width: 100%;
  font-size: 2.4rem;
  box-sizing: border-box;
  font-family: 'conqueror_sans_medium', Arial, sans-serif;
  font-weight: normal;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #fe86a4;
  display: inline-block;
  cursor: pointer;
  margin-top: 0.5rem;
}
html.mobile .tabs-cols .tabs-desc {
  width: 100%;
  background-position: center top;
  padding-top: 3rem !important;
  padding-bottom: 2rem;
  text-decoration: underline;
}
.menu-blur {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 5;
  top: 0;
  left: 0;
}
