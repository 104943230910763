/* Generated by Font Squirrel (https://www.fontsquirrel.com) on April 25, 2016 */


@font-face {
	font-family: 'univers_bold';
	src: url('../fonts/Univers-Bold_0.eot');
	src: local('☺'), url('../fonts/Univers-Bold_0.woff') format('woff'), 
		 url('../fonts/Univers-Bold_0.ttf') format('truetype'), 
		 url('../fonts/Univers-Bold_0.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'conqueror_sans_regular';
    src: url('../fonts/conquerorsans-webfont.eot');
    src: url('../fonts/conquerorsans-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/conquerorsans-webfont.woff2') format('woff2'),
         url('../fonts/conquerorsans-webfont.woff') format('woff'),
         url('../fonts/conquerorsans-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'conqueror_sans_bold';
    src: url('../fonts/conquerorsans-bold-webfont.eot');
    src: url('../fonts/conquerorsans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/conquerorsans-bold-webfont.woff2') format('woff2'),
         url('../fonts/conquerorsans-bold-webfont.woff') format('woff'),
         url('../fonts/conquerorsans-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'conqueror_sans_black';
    src: url('../fonts/conquerorsans-black-webfont.eot');
    src: url('../fonts/conquerorsans-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/conquerorsans-black-webfont.woff2') format('woff2'),
         url('../fonts/conquerorsans-black-webfont.woff') format('woff'),
         url('../fonts/conquerorsans-black-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'conqueror_sans_extrabold';
    src: url('../fonts/conquerorsans-extrabold-webfont.eot');
    src: url('../fonts/conquerorsans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/conquerorsans-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/conquerorsans-extrabold-webfont.woff') format('woff'),
         url('../fonts/conquerorsans-extrabold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'conqueror_sans_light';
    src: url('../fonts/conquerorsans-light-webfont.eot');
    src: url('../fonts/conquerorsans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/conquerorsans-light-webfont.woff2') format('woff2'),
         url('../fonts/conquerorsans-light-webfont.woff') format('woff'),
         url('../fonts/conquerorsans-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'conqueror_sans_medium';
    src: url('../fonts/conquerorsans-medium-webfont.eot');
    src: url('../fonts/conquerorsans-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/conquerorsans-medium-webfont.woff2') format('woff2'),
         url('../fonts/conquerorsans-medium-webfont.woff') format('woff'),
         url('../fonts/conquerorsans-medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'conqueror_sans_semibold';
    src: url('../fonts/conquerorsans-semibold-webfont.eot');
    src: url('../fonts/conquerorsans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/conquerorsans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/conquerorsans-semibold-webfont.woff') format('woff'),
         url('../fonts/conquerorsans-semibold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;

}