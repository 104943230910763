.block-map {
    @color-rose:@color-baby;
    text-align:center;
    padding:55px 0;
    
    h3 {
        font-size:46px;
        /*color:#494949;*/
		color:@color-text;
        .font(@font-extrabold);
    }
    
    .map {
        height:675px;
    }
    
    .map-container {
        position:relative;
        margin-bottom:30px;
        
        .map-filter {
            width:540px;
            text-align:center;
            position:absolute;
            top:30px;
            left:50%;
            margin-left:-250px;
            z-index:5;
            
            input[name=city],
            button[type=submit],
            .map-switch {
                margin:0 17px;
            }
            
            input[name=city] {
                border:2px solid @color-rose;
                background:white;
                padding:15px 20px;
                .border-radius(25px);
                outline:none;
                .border-box;
                width:190px;
            }
            button[type=submit] {
                border:2px solid @color-rose;
                background:@color-rose;
                color:white;
                padding:15px 20px;
                .border-radius(25px);
                outline:none;
                .border-box;
                width:190px;
                text-transform:uppercase;
                .transition;
                &:hover {
                    color:@color-baby;
                    background-color:@color-bg;
                }
            }
            
            .map-switch {
                position:relative;
                display:inline-block;
                width:50px;
                height:50px;
                /*border:2px solid @color-rose;*/
                background:@color-rose;
                cursor:pointer;
                outline:none;
                vertical-align: middle;
                .border-radius(50%);
                .border-box;
                .transition;

                & > .icon-list,
                & > .icon-map {
                    position:absolute;
                    top:50%;
                    height:50%;
                    left: 50%;
                    .transition;
                }
                & > .icon-list {
                    width:19px;
                    height:12px;
                    background-image: url(../images/icon-map-list.png);
                    background-repeat: no-repeat;
                    margin-left:-10px;
                    margin-top:-6px;
                }
                & > .icon-map {

                    width:17px;
                    height:24px;
                    background-image: url(../images/icon-map.png);
                    background-repeat: no-repeat;
                    margin-left:-9px;
                    margin-top:-12px;

                    opacity:0;
                    visibility:hidden;
                }

                &:hover {
                    background:lighten(@color-rose, 5%);
                }
            }
        }
        
        .list-container {
            position:absolute;
            top:0;
            bottom:0;
            left:0;
            right:0;
            background-color:@color-bg;
            padding-top:130px;
            padding-bottom:45px;
            text-align: left;
            font-size:16px;
            color:@color-text;
            line-height:1.4;
            .transition;
            
            opacity:0;
            visibility:hidden;
            
            a {
                color:@color-rose;
                text-decoration:none;
                &:hover {
                    text-decoration:underline;
                }
            }
            
            .no-site {
                color:@color-text;
            }
            
            .container {
                max-height:100%;
                overflow:auto;
                
                & > .row {
                        &:nth-child(odd){
                        background-color:#f8f8f8;
                    }
                    & [class^='col-']{
                        padding:20px 10px 20px 40px;
                    }
                } 
            }
        }
        
        &.list-view {
            .map-switch {
                & > .icon-map {
                    opacity:1;
                    visibility:visible;
                }
                & > .icon-list {
                    opacity:0;
                    visibility:hidden;
                }
            }
            
            .list-container {
                opacity:1;
                visibility:visible;
            }
        }
    }
    
    .gm-style-bg {
        background-color:@color-bg;
        .box-shadow(0 3px 15px rgba(0,0,0,.4));
        .border-radius(e('25px !important'));
    }
    .gm-style-arrow {
        z-index:1 !important;
        border-top-color:@color-bg !important;
        margin-top:-1px;
    }
    
    .gm-style-close {
        width:28px !important;
        height:28px !important;
        opacity:1 !important;
        top:-8px !important; 
        right:-8px !important; 
        overflow:visible !important;
        
        & img {
            display:none;
        }
        
        &:before {
            content:"";
            position:absolute;
            top:0;
            bottom:0;
            left:0;
            right:0;
            background-color:@color-baby;
            .border-radius(50%);
            .box-shadow(0 4px 10px fade(@color-invert, 20%));
            .transition(all .3s ease-in);
        }

        &:after {
            content:"";
            position:absolute;
            width:10px;
            height:10px;
            top:50%;
            left:50%;
            margin-left:-5px;
            margin-top:-5px;
            background-image:url(../images/icon-x.png);
            background-repeat: no-repeat;
            background-size:contain;
        }
        
        &:hover {
            &:before {
                .transform(scale(1.1));
            }
        }
    }
    
    
    .gm-style-iw {
        .window-content {
            width:280px;
            padding:26px 20px;
            color:@color-text;
            .border-box;
            
            .w-title {
                font-size:20px;
                color:@color-rose;
                text-transform:uppercase;
                margin-bottom:25px;
                .font('conqueror_sans_medium');
            }
            
            p {
                font-size:18px;
                line-height:1.4;
                margin:0;
                .font('conqueror_sans_light');
            }
        }
    }
}

#city-field {
    position:relative;
    z-index:3;
}
.ui-widget-content {
    border:none;
}
.ui-menu {
    z-index:2;
    font-size:14px;
    padding:23px 0 15px 0;
    margin:0;
    .font('conqueror_sans_regular');
    .border-radius(0 0 15px 15px);
    
    &.add-margin {
        margin-top:-23px;
    }
    
    .ui-menu-item {
        padding:15px 20px;
        .transition(background .4s ease);
        
        &.ui-state-focus {
            border:none;
            background:#f5f5f5;
            margin:0;
        }
    }
}