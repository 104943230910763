.spec-block-1 {
	min-height:545px;
	background-image:url(../images/bg-spec-1.jpg);
	background-repeat:no-repeat;
	background-position:center;
	background-size: cover;
	
	padding-left:20px;
	padding-right:20px;
	
	h1 {
		width: 590px;
		font-size:70px;
		line-height:70px;
		margin-top:140px;
		color:@color-baby;
		.font('conqueror_sans_extrabold');
	}
}
.spec-block-2 {
	color:@color-text;
	margin-bottom:-70px;
	
	padding-left:20px;
	padding-right:20px;
	
	h2 {
		.font('conqueror_sans_extrabold');
		font-size:40px;
	}

	.container {
		position: relative;
		padding-bottom: 70px;

		.col-xs-6 {
			position: static;
		}
	}
}

.spec-list {
	.list-item {
		padding-right:70px;
		padding-bottom:45px;
		
		.title {
			.font('conqueror_sans_bold');
			font-size:23px;
			margin-bottom:25px;
			
			a {
				color:@color-baby;
				text-decoration:none;
				
				&:hover {
					text-decoration:underline;
				}
			}
		}
		.description {
			vertical-align:middle;
		}
	}
	
	.icon-date, 
	.icon-place {
		margin-right:15px;
		
		&:before {
			content:"";
			display:inline-block;
			background-repeat:no-repeat;
			vertical-align:middle;
			margin-right:8px;
		}
	}
	
	.icon-date {
		&:before {
			width:13px;
			height:14px;
			background-image:url(../images/icon-date.png);
			margin-top:-4px;
		}
	}
	.icon-place {
		&:before {
			width:15px;
			height:19px;
			background-image:url(../images/icon-place.png);
		}
	}
}