/* ==========================================================================
	* wrapper / побертка
	========================================================================== */
.wrapper {
	position: relative;
	padding-top: 90px;
}
.clear {
	clear: both;
}
/* ==========================================================================
	* header / шапка
	========================================================================== */
.header {
	
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 900;
	background: #fff;
	.container {
		height: 90px;
		padding: 19px 0 0;
		background: #fff;
		/*min-width: 100%;*/
	}
	
	nav {

		padding:0 20px;
		


		ul {
			text-align: justify;
			padding: 0;
			margin: 0;
			li {
				padding: 0;
				margin: 0;
				list-style: none;
				display: inline-block;
			}
			&:after {
				content:"";
				display:inline-block;
				width:100%;
			}
		}
		
		a {
			display: inline-block;
			padding: 16px 28px 15px;
			color: @color-invert;
			font-size: 1em;
			line-height: 1em;
			text-transform: uppercase;
			text-decoration: none;
            border: 2px solid transparent;
			
			&.bak-set-baby {
				.border-radius(25px);
				-webkit-transition: border-color .2s ease-in-out, color .2s ease-in-out;
				-moz-transition: border-color .2s ease-in-out, color .2s ease-in-out;
				-o-transition: border-color .2s ease-in-out, color .2s ease-in-out;
				transition: border-color .2s ease-in-out, color .2s ease-in-out;
				
				&:hover, &.active {
					color: @color-baby;
					border: 2px solid @color-baby;
				}
			}
			&.bak-set-forte {
				.border-radius(25px);
				-webkit-transition: border-color .2s ease-in-out, color .2s ease-in-out;
				-moz-transition: border-color .2s ease-in-out, color .2s ease-in-out;
				-o-transition: border-color .2s ease-in-out, color .2s ease-in-out;
				transition: border-color .2s ease-in-out, color .2s ease-in-out;
				
				&:hover, &.active {
					color: @color-forte;
					border: 2px solid @color-forte;
				}
			}
            
//            .transition(all .2s ease-in-out);
			
			&.not-btn {
				width: 185px;
				padding-left: 0;
				padding-right: 0;
				text-align: center;
				.transition(all .2s ease-in-out);
				
				&:hover, &.active {
					text-decoration: underline;
					font-family: @font-medium;
				}
			}
			
			b {
				font-family: @font-extrabold;
				font-weight: normal;
			}
			
			&.active {
				b {
					
				}
				&:hover {
					text-decoration: none;
				}
			}
			
			&.nab-btn {
				padding: 14px 28px;
				color: @color-forte;
				font-size: 18px;
				font-family: @font-medium;
				font-weight: normal;
				border: 2px solid @color-forte;
				.border-radius(25px);
				.transition(all .2s ease-in-out);
				
				&:hover, &.active {
					color: @color-bg;
					background: @color-forte;
					text-decoration: none;
					.box-shadow(0 10px 25px rgba(242,143,44,.59));
				}
			}
		}
	}
}

/* ==========================================================================
	* block 3
	========================================================================== */
.flora-title {
	color: @color-baby;
	font-size: 46px;
	font-family: @font-extrabold;
	text-align: center;
	margin-bottom: 55px;
}
.flora-text {
	overflow: hidden;
	margin: 0 157px 0 167px;
	padding-bottom: 75px;
	
	div {
		float: right;
		width: 383px;
		color: @color-text;
		font-size: 18px;
		line-height:1.4;
		font-family: @font-light;
		
		&:first-child {
			float: left;
		}
	}
}
/* ==========================================================================
	* block 4
	========================================================================== */
.bg-block-4 {
	height: 570px;
	background-image: url('../images/bg-block-4.jpg');
	background-size: initial;
    background-position: 50% 0;
    background-repeat: no-repeat;
	
	.col-md-12 {
		position: relative;
		overflow: hidden;
		height: 570px;
		
		.carousel-main-baby {
			position: absolute;
			left: 136px;
			bottom: -67px;
		}
	}
}
.bakset-baby-title {
	margin-top: 54px;
	color: @color-baby;
	font-family: @font-extrabold;
	font-size: 46px;
	line-height: 60px;
	text-align: center;
	
	b {
		font-size: 60px;
		color: #231f20;
		font-size: 63px;
		letter-spacing: -0.01em;
		
		span {
			text-transform: uppercase;
			zoom: 0.69;
		}
	}
}
/* ==========================================================================
	* block 5 / 7
	========================================================================== */
.bg-block-5 {
	height: 658px;
	background-image: url('../images/bg-block-5.jpg');
	background-size: initial;
    background-position: 50% 0;
    background-repeat: no-repeat;
	
	.col-md-12 {
		position: relative;
		height: 658px;
	}
}
.how-work {
	margin: 0 12%;
	text-align: center;


	@media (max-width: 1200px) {
		margin: 0 auto 0 auto;
		width: 850px;
	}

	h4 {
		margin-top: 52px;
		margin-bottom: 0;
		color: @color-baby;
		font-size: 46px;
		font-family: @font-extrabold;
		font-weight: normal;
		
		span {
			color: #231f20;
			font-size: 60px;
			font-size: 63px;
			letter-spacing: -0.01em;
			
			b {
				text-transform: uppercase;
				zoom: 0.69;
			}
		}
	}

	.description {
		margin-top: 70px;
		float: left;
		width: 430px;
		text-align: left;
		
		.desc-title {
			font: @font-semibold;
			font-weight: normal;
			font-size: 23px;
		}
		p {
			margin-top: 25px;
			margin-bottom: 0;
			color: @color-text;
			font-size: 18px;
			line-height: 25px;
			font-family: @font-light;
			font-weight: normal;
			
			span {
				color: @color-baby;
			}
		}
	}
	.how-work-image {
		margin-top: 57px;
		float: right;
	}
	.more-knew {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 39px;
		z-index: 3;
		display: inline-block;
		color: @color-baby;
		font-size: 18px;
		font-family: @font-medium;
		font-weight: normal;
		text-transform: uppercase;
		text-decoration: none;
		.transition();
		
		&:before {
			content: '';
			position: absolute;
			bottom: -4px;
			left: 0;
			right: 0;
			width: 122px;
			height: 1px;
			margin: 0 auto;
			background: @color-baby;
		}
		&:hover {
			color: @color-text;
			
			&:before {
				background: @color-text;
			}
		}
	}
	
	&.block-7 {
		
		h4 {
			color: @color-forte;
		}
		.description {
			float: right;
			
			p {
				span {
					/*color: #f04c2d;*/
					color:@color-forte;
				}
			}
		}
		.how-work-image {
			float: left;
		}
		.more-knew {
			color: @color-forte;
			
			&:before {
				background: @color-forte;
			}
			&:hover {
				color: @color-text;

				&:before {
					background: @color-text;
				}
			}
		}
	}
}
/* ==========================================================================
	* block 6
	========================================================================== */
.bg-block-6 {
	height: 635px;
	background-image: url('../images/bg-block-6-2.jpg');
	background-size: initial;
    background-position: 50% 0;
    background-repeat: no-repeat;

	.col-md-12 {
		position: relative;
		overflow: hidden;
		height: 635px;
		
		.carousel-main-baby {
			position: absolute;
			right: 142px;
			bottom: -67px;
		}
	}
}
.bakset-forte-title {
	margin-top: 68px;
	color: @color-forte;
	font-family: @font-extrabold;
	font-size: 46px;
	line-height: 60px;
	text-align: center;
	
	b {
		font-size: 60px;
		color: #231f20;
		font-size: 63px;
		letter-spacing: -0.01em;
		
		span {
			text-transform: uppercase;
			zoom: 0.69;
		}
	}
}
/* ==========================================================================
	* block 7
	========================================================================== */
.bg-block-7 {
	height: 720px;
	background-image: url('../images/bg-block-7.jpg');
	background-size: initial;
    background-position: 50% 0;
    background-repeat: no-repeat;
	
	.col-md-12 {
		position: relative;
		height: 720px;
	}
}
@media ( min-width :1524px ) {
	.bg-block-4 {
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		background-position: 50%;
	}
	.bg-block-5 {
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}
	.bg-block-6 {
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		background-position: 50%;
	}
	.bg-block-7 {
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}
}






