.faq-list {
	@color-shadow:#9c426a;
	position: relative;
	margin-top:-20px;
	margin-bottom:160px;
	font-size:16px;
	background-color:@color-bg;
	.border-radius(10px);
	.box-shadow(0 10px 30px fade(@color-shadow, 30%));
	
	.list-item {
		padding:55px 115px 35px 115px;
		.box-shadow(0 15px 30px fade(@color-shadow, 5%));
		
		.title {
			.font('conqueror_sans_semibold');
			font-size:24px;
			margin-bottom:30px;
		}
		.description {
			&, p {
				line-height:1.5;
			}
		}
	}
	.paginator {
		bottom: -70px;
		width: 100%;
	}
}

.faq-new {
	margin-bottom:-70px;
	.article-wrap.container {
		position: relative;
		.paginator {
			width:96%;
		}
	}
}

.faq-block-1 {
	height:500px;
	background-image:url(../images/bg-faq-b1.jpg);
	background-repeat:no-repeat;
	background-position:100% 100%;
	
	h1 {
		font-size:70px;
		line-height:70px;
		margin-top:140px;
		color:@color-baby;
		.font('conqueror_sans_extrabold');
	}
	.article-container {
		max-width: 1500px;
		margin: 0 auto;
	}
	@media (max-width: 1600px) {
		height: 500px;
		background-size: cover;
	}
	@media (max-width: 1400px) {
		height: 450px;
	}
	@media (max-width: 1150px) {
		height: 400px;
	}
	@media (max-width: 1100px) {
		height: 350px;
	}
}

.faq-block-2 {
	background-image:url(../images/bg-faq-b2.jpg);
	background-repeat:no-repeat;
	background-position:50% 0;
	background-size:cover;
	
	padding-bottom:40px;
	
	.btn-big.middle {
		font-size:14px;
		padding:0 20px;
	}
	
	.q-item {
		position:relative;
		width:430px;
		margin:0 auto;
		padding:45px 20px 25px 20px;
		text-align:center;
		
		.icon,
		.title {
			padding-bottom:20px;
		}
		.title {
			font-size:24px;
			color:@color-text;
			line-height:1.3;
			min-height:175px;
			.font('conqueror_sans_semibold');
		}
		
		
		.popup {
			position:absolute;
			width:100%;
			top:-30px;
			left:0;
			visibility:hidden;
			opacity:0;
			.transition;
			
			&.active {
				top:45px;
				visibility:visible;
				opacity:1;
			}
			
			.popup-content {
				width:auto;
				padding:70px 30px;
				font-size:18px;
			}
			.popup-close {
				position:absolute;
				cursor:pointer;
			}
		}
	}
}

.faq-block-3 {
	background-color:@color-baby;
	background-image:url(../images/bg-faq-b3.png);
	background-repeat:no-repeat;
	background-position:50% 0;
	background-size:cover;
	
	padding:55px 0;
	
	text-align:center;
	color:@color-bg;
	font-size:20px;
	
	h3 {
		font-size:46px;
		margin-bottom:45px;
		.font('conqueror_sans_extrabold');

		@media (max-width: 1600px) {
			font-size:44px;
			margin-bottom:40px;
		}
		@media (max-width: 1400px) {
			font-size:40px;
			margin-bottom:30px;
		}
		@media (max-width: 1150px) {
			font-size:35px;
			margin-bottom:25px;
		}
		@media (max-width: 1100px) {
			font-size:32px;
			margin-bottom:20px;
		}
	}
	
	p {
		margin:45px 0 0 0;
		@media (max-width: 1600px) {
			margin-top:44px;
		}
		@media (max-width: 1400px) {
			margin-top:40px;
		}
		@media (max-width: 1150px) {
			margin-top:35px;
		}
		@media (max-width: 1100px) {
			margin-top:32px;
		}
	}
	
	.btn-big {
		border-width:0;
		line-height:62px;
		.transition;
		
		&:hover {
			color:@color-baby;
			background-color:@color-bg;
			opacity:.7;
		}
	}
	
}

.faq-block-4 {
	background-color:@color-bg;
	background-image:url(../images/bg-faq-b4.jpg);
	background-repeat:no-repeat;
	background-position:50% 0;
	background-size:cover;
	
	margin-bottom:-70px;
	padding:85px 0 55px 0;
	
	h3 {
		font-size:46px;
		margin-bottom:45px;
		color:@color-baby;
		text-align:center;
		.font('conqueror_sans_extrabold');
	}
}
@media (max-width: 1200px) {
	html:not(.mobile):not(.tablet) {
		.faq-slider .carousel-control.left {
			left: 0;
		}
		.faq-slider .carousel-control.right {
			right: 0;
		}
	}
}
.faq-slider {
	
	margin-bottom:40px;
	
	.item {
		margin:0;
		
		&:after {
			.cf;
		}
	}
	.carousel-control {
		margin-top:-25px;
		.sr-only {
			text-indent:-9999px;
		}
		.left {
			left:30px;
		}
		.right {
			right:30px;
		}
	}

	
	.item-item {
		float:left;
		width:50%;
		text-align:left;
		
		.font('conqueror_sans_light');
		
		.title {
			font-size:23px;
			margin-top:20px;
			.font('conqueror_sans_bold');
			a {
				color:@color-text;
				text-decoration:none;
			}
		}
		
		p {
			margin-top:20px;
			font-size:18px;
			line-height:1.4;
		}
		
		.item-content {
			width:423px;
			margin:0 auto;
		}
	}
}