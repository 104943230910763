.tabs-scale .tabs-content .image-baby,
.tabs-scale .tabs-content .image-forte {
  width: 27%;
  max-width: 350px;
  margin: 0 2%;
}
.tabs-scale.forte .tabs-content .right {
  transform: scale(0.7);
}
.tabs-scale .tabs-content .right,
.tabs-scale .tabs-content .left {
  transform: scale(0.9);
}
.tabs-scale.baby .tabs-content .left {
  transform: scale(0.7);
}
.tabs-scale {
    @color-tab-left: @color-forte;
    @color-tab-right: @color-baby;
    
    text-align:center;
    padding-top:100px;
    padding-bottom:61px;
    
    .tabs {
        text-align:center;
        
        .logo-title {
            position:relative;
            display:inline-block;
            width:280px;
            height:46px;
            background-image:url('../images/logo-tabs-scale.png');
            background-repeat: no-repeat;
            text-indent: -9999px;
            vertical-align: middle;
            
            .subtext-forte,
            .subtext-baby {
                position:absolute;
                left:0;
                bottom:-1.2em;
                .opacity(0);
                visibility:hidden;
                .transition(all .6s ease-in-out);
                text-indent: 0;
                .font(@font-semibold);
                font-size:34px;
            }
            .subtext-forte {
                color: @color-tab-left;
                
            }
            .subtext-baby {
                color: @color-tab-right;
            }
        }
        
        .tab {
            border-width:2px;
            border-style: solid;
            .border-radius(7px);
            margin:0 55px;
            font-size:26px;
            font-weight:bold;
            text-decoration:none;
            min-width:130px;
            height: 50px;
            line-height: 44px;
            display:inline-block;
            vertical-align: middle;
            
            &.left, &.right {
                .transition(all .2s ease-in-out);
            }
            
            &.left {
                color:@color-forte;
                border-color:@color-forte;

			&:hover {
				background-color:@color-forte;
                //.gradient(#fcb224, #f7902c);
				color:@color-bg;
			}
            }
            &.right {
                color:@color-baby;
                border-color:@color-baby;

			  &:hover {
				  background-color:@color-baby;
                  //.gradient(#fc82a0, #f36a8d);
				  color:@color-bg;
			  }
            }
        }
    }
    
    .subtitle {
        position:relative;
        /*color:#625e60;*/
		color:@color-text;
        font-size:26px;
        .font(@font-semibold);
        text-align:center;
        margin-top:43px;
        height:100px;
        
        .text-default,
        .text-forte,
        .text-baby {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            .transition(all .6s ease-in-out);
        }
        
        .text-default {
            .opacity(1);
            visibility:visible;
        }
        .text-forte,
        .text-baby {
            .opacity(0);
            visibility:hidden;
        }
        .text-forte {
            color:@color-forte;
        }
        .text-baby {
            color:@color-baby;
        }
    }
    
    .tabs-content {
        position:relative;
        text-align:center;
        /*height:530px;*/
		height:480px;
        

        &--bottom {
          width: 980px;
          margin: 0 auto 50px;
          &:after {
            content: '';
            display: table;
            clear: both;
          }
          .tab-icon {
            display: inline-block;
			vertical-align:middle;
			text-align:center;
            //background: none;
            /*img {
              margin-top: -3px;
              margin-left: -1px;
            }*/
          }
          img {
            margin-top: 0;
            margin-left: -2px;
          }
          .icon-item {
            float: left;
            width: 50%;
            padding: 0 50px;
            cursor: pointer;
            &:hover .tab-text {
              text-decoration: underline;
            }
            &:nth-child(odd) {
              text-align: right;
            }
              text-align: left;
            .tab-text {
              padding-top: 7px;
              width: 242px;
              font-size: 18px;
              box-sizing: border-box;
              font-family: 'conqueror_sans_medium', Arial, sans-serif;
              font-weight: normal;
              padding-left: 15px;
              color:@color-baby;
              display: inline-block;
              cursor: pointer;
            }

          }
        }
        .image-forte,
        .image-baby {
            cursor:pointer;
            position:absolute;
            
            .transition(all .6s ease);
            -webkit-backface-visibility: hidden;
        }
        
        .image-forte {
            left:23%;
            z-index:1;
            top:40px;
			
            .transform(scale(.9));
        }
        .image-baby {
            right:23%;
            z-index:2;
			top:48px;
			
        }
        
        .left, .right {
            position:absolute;
            width:325px;
            top:25px;
            z-index:3;
            
            .transition(all .6s ease-out);
            
            .icon-item {
                padding-bottom:20px;
				cursor:pointer;
				
                .tab-text {
                    padding-top:7px;
                    width:242px;
                    font-size:18px;
                    .border-box;
                    .font(@font-medium);
                }
                &:after {
                    content:"";
                    display:block;
                    clear:both;
                }
				
				&:hover {
					.tab-text {
						text-decoration:underline;
					}
				}
            }
            
            &:after {
				content:"";
				position:absolute;
				top:0;
				bottom:0;
				left:0;
				right:0;
			}
        }
        .left {
            left:0;
			
            .tab-icon {
                float:right;
                background-image:url('../images/tabs-triangle-left.png');
            }
            .tab-text {
                float:right;
                text-align:right;
                padding-right:15px;
                color:@color-tab-left;
            }
        }
        .right {
            right:0;
			
            .tab-icon {
                float:left;
                background-image:url('../images/tabs-triangle-right.png');
            }
            .tab-text {
                float:left;
                text-align:left;
                padding-left:15px;
                color:@color-tab-right;
            }
        }
    }
    
    &.forte {
        .subtitle {
            .text-forte {
                .opacity(1);
                visibility:visible;
            }
            .text-default,
            .text-baby {
                .opacity(0);
                visibility:hidden;
            }
        }
        
        .tabs .left {
            background-color:@color-forte;
            color:#fff;
        }
        
        .tabs-content .right {
            .transform(scale(.8));
            .opacity(.5);
        }
		.tabs-content .left {
			&:after {
				display:none;
			}
		}
        
        .tabs .logo-title .subtext-forte {
            .opacity(1);
            visibility:visible;
        }
        
        .tabs-content .image-forte {
            z-index:3;
            .transform(scale(1));
            left:23%;
            -webkit-animation: forte-animation-show .6s ease-out forwards;
            animation: forte-animation-show .6s ease-out forwards;
        }
        
        @-webkit-keyframes forte-animation-show {
            0% {left:26%;}
            35% {left:23%;}
            100% {left:29%;}
        }
        @keyframes forte-animation-show {
            0% {left:26%;}
            35% {left:23%;}
            100% {left:29%;}
        }
        
        .tabs-content .image-baby {
            z-index:1;
            right:23%;
            
            -webkit-animation: baby-animation-hide .6s ease forwards;
            animation: baby-animation-hide .6s ease forwards;
            
            .transform(scale(.8));
            .blur(2px);
        }
        
        @-webkit-keyframes baby-animation-hide {
            0% {right:26%;}
            35% {right:23%;}
            100% {right:29%;}
        }
        @keyframes baby-animation-hide {
            0% {right:26%;}
            35% {right:23%;}
            100% {right:29%;}
        }
    }

    &.baby {
        .subtitle {
            .text-baby {
                .opacity(1);
                visibility:visible;
            }
            .text-default,
            .text-forte {
                .opacity(0);
                visibility:hidden;
            }
        }
        
        .tabs .right {
            background-color:@color-baby;
            color:#fff;
        }
        
        .tabs-content .left {
            .transform(scale(.8));
            .opacity(.5);
        }
		
		.tabs-content .right {
			&:after {
				display:none;
			}
		}
        
        .tabs .logo-title .subtext-baby {
            .opacity(1);
            visibility:visible;
        }
        
        .tabs-content .image-forte {
            z-index:1;
            .transform(scale(.8));
            .blur(2px);
        }
        .tabs-content .image-baby {
            z-index:2;
            right:380px;
        }
        
        &.transition-back {
            .tabs-content .image-forte {
                z-index:1;
                .transform(scale(.8));
                .blur(2px);
                
                -webkit-animation: forte-animation-hide .6s ease-out forwards;
                animation: forte-animation-hide .6s ease-out forwards;
            }

            @-webkit-keyframes forte-animation-hide {
				0% {right:26%;}
				35% {right:23%;}
				100% {right:29%;}
            }
            @keyframes forte-animation-hide {
                0% {right:26%;}
				35% {right:23%;}
				100% {right:29%;}
            }

            .tabs-content .image-baby {
                z-index:2;
                right:26%;

                -webkit-animation: baby-animation-show .6s ease forwards;
                animation: baby-animation-show .6s ease forwards;
            }

            @-webkit-keyframes baby-animation-show {
				0% {right:26%;}
				35% {right:23%;}
				100% {right:29%;}
            }
            @keyframes baby-animation-show {
                0% {right:26%;}
				35% {right:23%;}
				100% {right:29%;}
            }
        }
    }
    
    .tabs-desc {
        font-size:22px;
        /*color:#625e60;*/
		/*color:@color-text;*/
        padding-left:40px;
        min-height:29px;
        line-height:29px;
        .font(@font-light);
        background-image: url('../images/icon-alert.png');
        background-repeat:no-repeat;
        background-position: 0;
        display:inline-block;
        margin-bottom:30px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
	
	&.forte {
		.btn-more {
			background-color:@color-forte;
			border-color:@color-forte;
			color:@color-bg;
			.transition;
			
			&:hover {
				background-color:@color-bg;
				border-color:@color-forte;
				color:@color-forte;
			}
		}
	}
	&.baby {
		.btn-more {
			background-color:@color-baby;
			border-color:@color-baby;
			color:@color-bg;
			.transition;
			
			&:hover {
				background-color:@color-bg;
				border-color:@color-baby;
				color:@color-baby;
			}
		}
	}
    
}
.btn-forte,
.btn-baby {
  display: none;
}
.tabs-scale.forte .btn-forte {
  display: inline-block;
}
.tabs-scale.baby .btn-baby {
  display: inline-block;
}

.tab-icon {
	display:block;
	width:83px;
	height:77px;
	background-repeat:no-repeat;
	
	img {
		margin-top: 8px;
	}
}
.forte {
	.tab-icon {
		background-image:url('../images/tabs-triangle-left.png');
	}
}
.baby {
	.tab-icon {
		background-image:url('../images/tabs-triangle-right.png');
	}
}
.forte .btn-baby {
  display: none;
}
.baby .btn-forte {
  display: none;
}
.forte .tabs-content--bottom {
  .tab-icon {
    background-image: url('../images/tabs-triangle-left.png');
  }
  .tab-text {
    color: @color-forte !important;
  }
}

@media (min-width:1366px){
	.tabs-scale .tabs-content .left,
	.tabs-scale .tabs-content .right {
		width:345px;
	}
	.tabs-scale .tabs-content .left {
		left:-20px;
	}
	.tabs-scale .tabs-content .right {
		right:-20px;
	}
	.tabs-scale .tabs-content .left .icon-item .tab-text,
	.tabs-scale .tabs-content .right .icon-item .tab-text {
		width:262px;
	}
}
@media (max-width:1250px){
  .tabs-scale .tabs-content .image-baby,
  .tabs-scale .tabs-content .image-forte {
    width: 20%;
    margin: 50px 6% 0;
  }
}
@media (min-width:1250px){
	.tabs-scale .tabs-content .left,
	.tabs-scale .tabs-content .right {
		width:300px;
	}
	.tabs-scale .tabs-content .left {
		left:-20px;
	}
	.tabs-scale .tabs-content .right {
		right:-20px;
	}
	.tabs-scale .tabs-content .left .icon-item .tab-text,
	.tabs-scale .tabs-content .right .icon-item .tab-text {
		width:212px;
	}
}