.how-work .more-knew {
	bottom: 80px;
}
@media (max-width: 1400px) {
	#carousel-main-3 .carousel-inner .item {
		height: 65vw;
	}
	#carousel-main-3 {
		height: 60vw;
	}
	.bg-block-4 {
		background-position: right;
	}
	.bg-block-6 {
		background-position: 30% 0;
	}

	.block-map .map {
		height: 40vw;
	}

	//fonts and paddings

	.how-work .description {
		margin-top: 50px;
	}
	.how-work .how-work-image {
		margin-top: 37px;
		img {
			width: 90%;
		}
	}
	.how-work .description .desc-title {
		font-size: 20px;
	}
	.how-work .description p {
		margin-top: 20px;
		font-size: 16px;
		line-height: 20px;
	}

	.how-work h4 {
		margin-top: 42px;
		font-size: 36px;
	}
	.block-map h3 {
		font-size: 36px;
	}
	.bakset-baby-title,
	.bakset-forte-title {
		margin-top: 48px;
		font-size: 36px;
		line-height: 45px;
	}
	.bakset-baby-title b,
	.how-work h4 span,
	.bakset-forte-title b {
		font-size: 50px;
	}
	.bg-block-7,
	.bg-block-7 .col-md-12 {
		height: 620px;
	}
	.bg-block-5,
	.bg-block-6,
	.bg-block-5 .col-md-12,
	.bg-block-6 .col-md-12 {
		height: 535px;
	}

}
@media (max-width: 1250px) {
	#carousel-main-3 .carousel-inner .item {
		height: 70vw;
	}
	#carousel-main-3 {
		height: 73vw;
	}
	.bg-block-4 {
		background-position: 80% 0;
	}
	.bg-block-6 {
		background-position: 30% 0;
	}
	.bg-block-6 .col-md-12 .carousel-main-baby {
		right: 0;
	}

	.bg-block-4 .col-md-12 .carousel-main-baby {
		left: 0;
	}
}
@media (max-width: 1100px) {
	#carousel-main-3 .carousel-inner .item {
		height: 770px;
	}
	#carousel-main-3 {
		height: 800px;
	}
}
@media (max-width: 1200px) {
	.benefit-icons .icon-item {
		margin: 0 3%;
	}
}
@media (max-width: 1200px) {
	.header nav a {
		padding: 12px 15px 11px;
		font-size: 0.8em;
		line-height: 0.8em;
	}
	.header {

		.container {
			padding: 27px 0 0;
			min-width: 1000px;
		}
	}
	.header nav a.nab-btn {
		padding: 12px 15px 11px;
		font-size: 0.8em;
	}
	.how-work {
		margin: 0 auto 0 auto;
		width: 900px;
	}
	.footer-text {
		width: 990px;
		background-size: contain;
	}
	.spec-block-1 h1,
	.page-new h2 {
		font-size:42px;
		line-height:1.2;
	}
}
@media (max-width: 1024px) {
	.block-map .map {
		height: 400px;
	}
}