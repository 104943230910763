.paginator {
	//padding:70px 20px;
	text-align:center;
	position: absolute;
	white-space: nowrap;
	width: 44%;
	bottom: 0;
	.cls-1 {
		opacity:1;
	}
	> a {
		width: 14px;
	}
	
	.prev, 
	.next {
		display:inline-block;
		height:25px;
		line-height:25px;
		vertical-align:middle;
		
		svg {
			vertical-align:top;
			.transition(all .3s ease);
		}
		
		&:hover {
			text-decoration:none;
			
			svg {
				.transform(scale(1.2));
			}
		}
	}
	
	ul {
		margin:0 20px;
		display:inline-block;
		
		li {
			display:inline-block;
			padding:0;
			margin:0 5px;
			.font('conqueror_sans_medium');
			
			& a, 
			& span {
				display:inline-block;
				width:25px;
				height:25px;
				line-height:25px;
				text-align:center;
				margin:0 -2px;
				color:@color-text;
				.border-radius(50%);
				.transition;
			}
		}
		
		li.active a,
		li.active span,
		li a:hover,
		li span:hover {
			color:@color-bg;
			background-color:@color-baby;
			text-decoration:none;
		}
	}
}