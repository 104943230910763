/**
 *  Стили Антона ;)
 */


 
/* Фон под 2-й и 3-й блоки */
.bg-block-23 {
    background-image:url('../images/bg-block-23.jpg');
    background-size:initial;
    background-position:50% 0;
    background-repeat: no-repeat;
}

.btn-big {
    @color-rose:@color-baby;
    border:2px solid @color-rose;
    color:@color-rose;
    text-transform:uppercase;
    padding:0 60px;
    height:60px;
    line-height:58px;
    font-size:20px;
    .font(@font-medium);
    text-decoration:none;
    display:inline-block;
    outline:none;
    .border-radius(30px);
    .transition(e('color .2s ease, background .2s ease'));
	background-color:@color-bg;
    
    &.middle {
        height:50px;
        line-height:48px;
        font-size:18px;
        padding:0 52px;
		 .border-radius(25px);
    }
	
    &:hover {
        color:@color-bg;
        background-color:@color-rose;
    }
    
    &.invert {
        border-color:@color-baby;
        color:@color-bg;
        background-color:@color-baby;
        
        &:hover {
            color:@color-baby;
            background-color:@color-bg;
        }
    }
}

.footer-text {
    width:1004px;
    height:65px;
    margin:30px auto;
    background-image:url('../images/footer-text.png');
    background-repeat:no-repeat;
    text-indent:-9999px;
}

footer {
    background-color:#f8f8f8;
    margin-top:70px;
    padding:25px 0 40px 0;
	padding-bottom: 25px;
}

.text-center {
    text-align:center;
}
.text-right {
    text-align:right;
}

.footer-socials {
    @color-rose:@color-baby;
    
    .social-item {
        display:inline-block;
        width:50px;
        height:50px;
        line-height:45px;
        .border-radius(25px);
        border:2px solid @color-rose;
        margin:0 8px;
        background-color:@color-bg;
        vertical-align: middle;
        .transition(background-color .2s ease);
        
        &:hover {
            background-color:@color-rose;
        }
        
        &.fb:after {
            content:"";
            display:inline-block;
            width:11px;
            height:21px;
            background-image:url('../images/icon-fb.png');
            background-repeat:no-repeat;
            background-position:0 0;
            vertical-align: middle;
        }
        
        &.fb:hover:after {
             background-position:0 -21px;
        }
        
        &.ok:after {
            content:"";
            display:inline-block;
            width:13px;
            height:23px;
            background-image:url('../images/icon-ok.png');
            background-repeat:no-repeat;
            background-position:0 0;
            vertical-align: middle;
        }
        
        &.ok:hover:after {
            background-position:0 -23px;
        }
        
        &.vk:after {
            content:"";
            display:inline-block;
            width:25px;
            height:15px;
            background-image:url('../images/icon-vk.png');
            background-repeat:no-repeat;
            background-position:0 0;
            vertical-align: middle;
        }
        
        &.vk:hover:after {
            background-position:0 -15px;
        }
    }
}

.pharmamed {
	margin-top: 7.5px;
	display: inline-block;
}
.developer {
    display:inline-block;
    width:141px;
    height:23px;
    background-image:url('../images/developer.png');
    background-repeat: no-repeat;
    margin-top:15px;
}
.anchor-container {
    position:relative;
    & > div {
        position:absolute;
        top:-100px;
    }
}

.over-slider {
    position:relative;
    & > .wrap {
        position:absolute;
        width:100%;
        bottom:0;
    }
}

.benefit-icons {
    text-align:center;
    .icon-item {
        position:relative;
        width:120px;
        height:120px;
        line-height: 70px;
        border:2px solid @color-baby;
        display:inline-block;
        margin:0 43px;
        background-color:white;
        /*color:#6b6868;*/
		color:@color-text;
        font-size:12px;
        text-align:center;
		vertical-align:top;
        .border-radius(10px);
        .border-box;
        
        img {
            &.icon-1 { margin-top:9px; }
			&.icon-2 { margin-top:8px; }
			&.icon-3 { margin-top:8px; }
			&.icon-4 { margin-top:15px; }
			&.icon-5 { margin-top:5px; }
			
			vertical-align:top;
        }
        & > span {
            position:absolute;
            bottom:0;
            left:0;
            right:0;
            padding:5px 0;
            line-height:1.2;
			
			font-size:12px;
			font-weight:bold;
			.font('univers_bold');
			/*font-family:arial, sans-serif;*/
        }    
    }
}


.icon-down {
    position:relative;
    @color-rose:@color-baby;
    display:inline-block;
    width:44px;
    height:44px;
    background:white;
    border:2px solid @color-rose;
    cursor:pointer;
    .border-radius(22px);
    .transition(all .4s ease);

    &:after {
        content:"";
        display:inline-block;
        width:12px;
        height:7px;
        background-image:url('../images/down-arrow.png');
        background-repeat: no-repeat;
        background-position:-1px 0;
        position:absolute;
        top:50%;
        left:50%;
        margin-left:-6px;
        margin-top:-3px;
    }
    &:hover {
        /*opacity:.5;*/
        background-color:@color-rose;
        &:after {
            background-position:-15px 0;
        }
    }
}
.link-down {
    padding-top:88px;
    padding-bottom:20px;
    text-align:center;
}

@media ( min-width :1524px ) {
	.bg-block-23 {
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}
}