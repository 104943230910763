/* ==========================================================================
	* Цвета
	========================================================================== */

@color-bg:white;
@color-invert:black;
@color-baby:#fe86a4;
@color-forte:#f28f2c;
@color-text:#565656;


/* ==========================================================================
	* fonts / Шрифты применяемые на сайте
	========================================================================== */

@font-light : 'conqueror_sans_light';
@font-regular : 'conqueror_sans_regular';
@font-medium : 'conqueror_sans_medium';
@font-semibold : 'conqueror_sans_semibold';
@font-black : 'conqueror_sans_black';
@font-extrabold : 'conqueror_sans_extrabold';

/* ==========================================================================
	* colors / Цвета применяемые на сайте
	========================================================================== */


/* ==========================================================================
	* Сетка / Grid system (pulled from core Bootstrap)
	========================================================================== */
//== Variables :: Media queries 
//
// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
@screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
@screen-xs-min:              @screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
@screen-phone:               @screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
@screen-md:                  992px;
@screen-md-min:              @screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
@screen-desktop:             @screen-md-min;

// Large screen / lg desktop
//** Deprecated `@screen-lg` as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
//@screen-lg-desktop:          @screen-lg-min;
@screen-lg-desktop:          1345px;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
@grid-columns:              12;
//** Padding between columns. Gets divided in half for the left and right.
@grid-gutter-width:         20px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
@grid-float-breakpoint:     @screen-sm-min;
//** Point at which the navbar begins collapsing.
@grid-float-breakpoint-max: (@grid-float-breakpoint - 1);


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
@container-tablet:             ((730px + @grid-gutter-width));
//** For `@screen-lg-min` and up.
@container-sm:                 @container-tablet;

// Medium screen / desktop
@container-desktop:            ((950px + @grid-gutter-width));
//** For `@screen-lg-min` and up.
@container-md:                 @container-desktop;

// Large screen / lg desktop
@container-large-desktop:      ((1150px + @grid-gutter-width));
//** For `@screen-lg-min` and up.
//@container-lg:                 @container-large-desktop;
//@container-lg:                 ((1283px + @grid-gutter-width));
@container-lg:                 auto;


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  .container-fixed();

  @media (min-width: @screen-sm-min) {
    width: @container-sm;
  }
  @media (min-width: @screen-md-min) {
    width: @container-md;
  }
  @media (min-width: @screen-lg-min) {
    width: @container-large-desktop;
  }
  @media (min-width: @screen-lg-desktop) {
    width: @container-lg;
  }
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
  .container-fixed();
}


// Row
//
// Rows contain and clear the floats of your columns.

.row {
  .make-row();
}


// Columns
//
// Common styles for small and large grid columns

.make-grid-columns();


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

.make-grid(xs);


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: @screen-sm-min) {
  .make-grid(sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: @screen-md-min) {
  .make-grid(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: @screen-lg-min) {
  .make-grid(lg);
}

// Manually adding .clearfix() classes since LESSPHP has trouble with them.
.clearfix,
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}

// The magic ingredient
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.clearfix {
	width: 100%;
}