.page-new {
	b.baby {
		.font('conqueror_sans_medium');
		color:@color-baby;
	}
	
	.block-ld {
		padding:140px 20px;
		background-size:cover;
		background-position:50%;
		background-repeat:no-repeat;
		
		.img {
			text-align:center;
		}
		
		&-small {
			padding-top:50px;
		}
		
		&.prob-1 {
			background-position:50% 100%;
			background-image:url(../images/bg-prob-1.jpg);
			padding-bottom:220px;
		}
		&.prob-2 {
			background-position:0 100%;
			background-size:initial;
			background-image:url(../images/bg-prob-2.jpg);
			margin-top:-150px;
			
			.img {
				margin-top:-70px;
			}
		}
		&.prob-3 {
			background-image:url(../images/bg-prob-3.jpg);
			padding-top:180px;
			padding-bottom:160px;
			.img {
				margin-top:-70px;
				
				.count {
					width:296px;
					height:296px;
					background-image:url(../images/prob-3-img.png);
					background-position:50%;
					background-repeat:no-repeat;
					text-transform:uppercase;
					color:@color-bg;
					font-size:18px;
					line-height:1.2;
					display:inline-block;
					
					.cell {
						display:table-cell;
						width:296px;
						height:296px;
						vertical-align:middle;
					}
					
					b {
						font-size:60px;
						.font('conqueror_sans_bold');
					}
				}
			}
		}
		&.prob-4 {
			background-image:url(../images/bg-prob-4.jpg);
			padding-top:180px;
			padding-bottom:90px;
		}
	}
	
	&, p {
		.font('conqueror_sans_light');
		font-size:16px;
		color:@color-text;
		line-height:1.5;
		
		&.lead {
			font-size:18px;
		}
	}
	
	h2 {
		.font('conqueror_sans_extrabold');
		
		font-size:70px;
		color:@color-baby;
		line-height:1;
		margin-bottom:.8em;
	}
	
	h3 {
		.font('conqueror_sans_bold');
		
		font-size:40px;
		color:@color-baby;
		line-height:1;
		margin-bottom:1em;
	}
	
	h4 {
		.font('conqueror_sans_bold');
		
		font-size:23px;
		line-height:1;
		margin-bottom:1em;
	}
}

.prob-benefits {
	padding:70px 85px 0 85px;
	position:relative;
	
	.item {
		padding-bottom:70px;
		display:block;
		text-align:center;
		
		.icon {
			position:relative;
			width:141px;
			height:140px;
			margin:0 auto;
			
			background-image:url(../images/prob-benefit-bg.png);
			background-repeat:no-repeat;
			
			&:before {
				content:"";
				position:absolute;
				top:0;
				bottom:0;
				left:0;
				right:0;
				background-position:50%;
				background-repeat:no-repeat;
			}
			
			&.icon-1:before {
				background-image:url(../images/prob-benefit-1.png);
			}
			&.icon-2:before {
				background-image:url(../images/prob-benefit-2.png);
			}
			&.icon-3:before {
				background-image:url(../images/prob-benefit-3.png);
			}
			&.icon-4:before {
				background-image:url(../images/prob-benefit-4.png);
			}
			&.icon-5:before {
				background-image:url(../images/prob-benefit-5.png);
			}
			&.icon-6:before {
				background-image:url(../images/prob-benefit-6.png);
			}
			
			.btn-prob {
				display:block;
				position:absolute;
				bottom:0;
				left:50%;
				width:30px;
				height:30px;
				margin-left:-15px;
				margin-bottom:-9px;
				cursor:pointer;
				
				&:before,
				&:after {
					content:"";
					position:absolute;
					top:0;
					bottom:0;
					left:0;
					right:0;
					background-position:50%;
					background-repeat:no-repeat;
					.transition;
				}
				
				&:before {
					background-color:@color-bg;
					.border-radius(50%);
					.box-shadow(0 2px 5px fade(@color-baby, 20%));
				}
				
				&.open {
					&:after {
						background-image:url(../images/prob-plus.png);
					}
				}
				&.close {
					&:after {
						background-image:url(../images/prob-close.png);
					}
				}
				
				&:hover {
					&:before {
						.transform(scale(1.3));
					}
					&:after {
						.transform(rotate(180deg));
					}
				}
			}
		}
		
		.title {
			margin-top:20px;
			.font('conqueror_sans_semibold');
			font-size:24px;
			color:@color-baby;
		}
	}
	
	.popup {
		position:absolute;
		width:100%;
		top:-30px;
		left:0;
		visibility:hidden;
		opacity:0;
		.transition;
		
		&.active {
			top:45px;
			visibility:visible;
			opacity:1;
		}
		
		.popup-content {
			width:auto;
			padding:0 120px 40px 120px;
			
			&, p {
				font-size:18px;
			}
		}
		
		.item {
			padding-bottom:35px;
			margin-top:-60px;
		}
	}
}